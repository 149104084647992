import React, { useState, useEffect } from "react";
import ApiConstant from "../../constants/ApiConstant";
import { useTranslation } from "react-i18next";
import {api} from "../../services/api";
import Input from "../../shared/form/Input";
import Loading from "../../shared/form/Loading";
import ConfirmModal from "../../shared/form/ConfirmModal";
import { toast } from "react-toastify";
import RenderDetailUser from "../company/RenderDetailUser";
import MenuManager from "./MenuManager";

export default function Company() {
    const [dataCompany, setDataCompany] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [dataUser, setDataUser] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [userCompany, setUserCompany] = useState([]);
    const [formCompany, setFormCompany] = useState({
        // group_name: "",
        // status: "",
        // data: "",
    });
    const [showAddAttr, setShowAddAttr] = useState(false);
    const [attributes, setAttributes] = useState([]);
    const [newAttr, setNewAttr] = useState("");
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [errors, setError] = useState([]);

    const handleCloseAddAttr = () => setShowAddAttr(false);
    const handleShowAddAttr = () => {
        setShowAddAttr(true);
    };

    const handleAddAttr = async(e) => {
        const form = {
            user_in_company: userCompany,
        };
        if (userCompany && userCompany.length > 0) {
            setLoading(true);
            const path = ApiConstant.Company.CreateUserInCompany;
            const result = await api.post(path, form);
            if (result.api_result.result === ApiConstant.Result.Success) {
                setLoading(false);
                toast.success(t("company.create_sucess"))
                getDataCompany();
            } else {
                setLoading(false);
                toast.error(t('company.create_fail'))
                setError({
                    ...errors,
                    msg: result.api_result.message,
                });
            }
            attributes.push(newAttr);
            setAttributes([...attributes]);
            setShowAddAttr(false);
            setNewAttr("");
        }
    };

    const getDataCompany = async (e) => {
        setLoading(true);
        const path = ApiConstant.Company.List;
        const result = await api.get(path, { keyword: companyName });
        if (result.api_result.result === ApiConstant.Result.Success) {
            setLoading(false);
            let datas = result.datas;
            setDataCompany(datas);
        } else {    
            setLoading(false);
            setError({
                ...errors,
                msg: result.api_result.message,
            });
        }
    };

    useEffect(() => {
        document.title = t("company.title");
        getDataCompany();
    }, [t]);

    const handleChangeValue = (e) => {
        setCompanyName(e.target.value);
    };

    const handleChangeValueCompany = (e) => {
        setFormCompany({
            ...formCompany,
            [e.target.name]: e.target.value,
            status: 1,
        });
    };

    const searchItemEmail = async (e) => {
        setDataUser ([])
        setLoading(true);
        const path = ApiConstant.Company.SearchUser
        const result = await api.get(path, keyword);
        if (result.api_result.result === 0){
            setLoading(false);
            let data = result.datas
            setDataUser(
                data
            )
        } else{
            setLoading(false)
            setError({
                ...errors,
                keyword: result.api_result.message
            })
        }
    }
    const handleChangeValueKeyword = async (e) => {
        setKeyword({
            ...keyword,
            [e.target.name]: e.target.value,
        });
        setError({
            keyword: "",
        });
    };
    function renderListUser(user, index) {
        return (
            <li key={index}>
                <div className="avatar-group"><span>{'00'+user.user_id}</span></div>
                <p className="title">{user.name_kana}</p>
                <p>{user.email}</p>
                <input type="checkbox" onChange={(e) => selectValueUser(e,user)} name="getUser" className="check-box"/>
            </li>
        )
    }

    const selectValueUser = (e, user) => {
        if(e.target.checked) {
            setUserCompany([
                ...userCompany,
                user
            ])
        }
    }

    function renderModalAddUserInCompany() {
        return (
            <div className="list-group">
                <div className="md-full mt-10">
                    <form method="POST" target="hidden_iframe">
                        <div className="form-group form-search">
                            <Input
                                onChangeValue={(e) => handleChangeValueKeyword(e, true)}
                                name="keyword"
                                type="text"
                                value={keyword.keyword}
                                placeholder={t("company.place_holder_email_user")}
                                error={errors.keyword}
                                className="form-control"
                            />
                            <button
                                type="button"
                                onClick={searchItemEmail}
                                className="btn green button-submit"
                            >
                                {t("company.search")}
                            </button>
                        </div>
                    </form>
                </div>
                <div className="list-group-content">
                    <ul>
                        {dataUser && dataUser.length > 0 ? (
                            dataUser.map((user, index) => {
                                return renderListUser(user, index);
                            })
                        ) : (
                            <li className="sub-item text-center">
                                <span>{t("nodata")}</span>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="clearfix" />
            <div className="page-container">
                <div className="page-content-wrapper">
                    <div className="page-content contract_group">
                        <div className="container">
                            <div className="main-body-content">
                                <div className="contract_suport_title border-bottom mrb-10">
                                    <h3 className="mt-30">{ dataCompany.company_name ? t("company.company_name") + dataCompany.company_name : ''}</h3>
                                    <h3 className="mt-30">{t("company.manager_member")}</h3>
                                </div>
                                <div className="contract_group_ct">
                                    <div className="row">
                                        <MenuManager />
                                        <div className="col-sm-9 com-xs-9">
                                            <div className="md-full mt-20 contract_form_search_group">
                                                <form method="GET" target="hidden_iframe">
                                                    <div className="form-group form-search">
                                                        <Input
                                                            onChangeValue={(e) =>
                                                                handleChangeValue(e, true)
                                                            }
                                                            name="user_in_company"
                                                            type="text"
                                                            value={companyName.user_in_company}
                                                            placeholder={t(
                                                                "company.place_holder_company_name",
                                                            )}
                                                            className="form-control"
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={getDataCompany}
                                                            className="btn green button-submit"
                                                        >
                                                            {t("company.search_company")}
                                                        </button>
                                                        
                                                    </div>
                                                </form>
                                                <button
                                                    type="button"
                                                    onClick={handleShowAddAttr}
                                                    className="btn button-add-group search-add-group"
                                                >
                                                    <img
                                                        alt=""
                                                        src="../../assets/images/plus_color.png"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3 com-xs-3" />
                                        <div className="col-sm-9 com-xs-9">
                                            <div className="list-group md-full mt-10">
                                                <div className="list-group-content">
                                                    <ul>
                                                        {dataCompany && dataCompany.data.length > 0 ? (
                                                        dataCompany.data.map((company, index) => {
                                                            return (
                                                                <RenderDetailUser
                                                                    company={company}
                                                                    key={index}
                                                                    getDataCompany={getDataCompany}
                                                                />
                                                            )
                                                        })
                                                        ) : (
                                                            <li className="sub-item text-center">
                                                                <span>{t("nodata")}</span>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                                <div className="add-group">
                                                    <button
                                                        type="button"
                                                        onClick={handleShowAddAttr}
                                                        className="btn button-add-group float-right"
                                                    >
                                                        <img
                                                            alt=""
                                                            src="../../assets/images/plus_color.png"
                                                        />
                                                    </button>
                                                    <ConfirmModal
                                                        title={t("company.add_member")}
                                                        showModal={showAddAttr}
                                                        onClose={handleCloseAddAttr}
                                                        onConfirm={handleAddAttr}
                                                        body={renderModalAddUserInCompany()}
                                                        cancel={t("company.cancel")}
                                                        confirm={t("company.save")}
                                                        className="contract_group modal-add-group"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <Loading /> : ""}
        </div>
    );
}
