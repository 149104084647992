import React, { useState } from "react";
import ConfirmModal from "../../shared/form/ConfirmModal";
import { useTranslation } from "react-i18next";
import Input from "../../shared/form/Input";
import _ from "lodash";
import ApiConstant from "../../constants/ApiConstant";
import { api } from "../../services/api";

export default function ProfileSignature(props) {
    const { t } = useTranslation();
    const numberOfDigits = 4;
    const defaultDigits = {};
    const defaultDigitErrors = {};
    const prefixDigit = "number";

    for (let d = 1; d <= numberOfDigits; d++) {
        defaultDigits[prefixDigit + d] = "";
        defaultDigitErrors[prefixDigit + d] = false;
    }

    const [isShow, setShow] = useState(false);
    const [digits, setDigits] = useState(defaultDigits);
    const [digitErrors, setDigitErrors] = useState(defaultDigitErrors);
    const [modalError, setModalError] = useState("");

    const handleChangeInput = (e) => {
        const reg = new RegExp("^[/\\d]{1}?$");
        const value = e.target.value;
        const name = e.target.name;
        const currentNo = parseInt(name.replace(prefixDigit, ""));

        if (!value || reg.test(value)) {
            setDigits({
                ...digits,
                [name]: value,
            });

            if (value) {
                if (currentNo < numberOfDigits) {
                    document.getElementById(prefixDigit + (currentNo + 1)).focus();
                }

                setDigitErrors({
                    ...digitErrors,
                    [prefixDigit + currentNo]: false,
                });
            }
        } else {
            setDigits(digits);
        }
    };

    const handleModalShow = async () => {
        const phoneNumber = props.form.mobile;

        if (!phoneNumber) {
            props.setFormErrors({
                ...props.formErrors,
                mobile: t("validate.required", { attr: t("profile.mobile") }),
            });

            return false;
        }

        props.setLoading(true);
        const result = await api.post(ApiConstant.Profile.SendVerifyMobileNumber, {
            phone_number: phoneNumber,
        });

        if (result.api_result.result !== ApiConstant.Result.Success) {
            props.setFormErrors({
                ...props.formErrors,
                mobile: result.api_result.message,
            });
        } else {
            setShow(true);
        }

        props.setLoading(false);
    };

    const handleModalClose = async () => {
        setShow(false);
    };

    const handleModalSubmit = async () => {
        let hasErrors = false;
        let digitsText = "";
        const dErrors = {};

        for (let d = 1; d <= numberOfDigits; d++) {
            if (!digits[prefixDigit + d]) {
                hasErrors = true;
                dErrors[prefixDigit + d] = true;
            } else {
                digitsText += digits[prefixDigit + d];
            }
        }

        setDigitErrors(dErrors);

        if (hasErrors) {
            return false;
        }

        props.setLoading(true);
        const result = await api.post(ApiConstant.Profile.VerifyMobileNumber, {
            digits: digitsText,
        });

        if (result.api_result.result === ApiConstant.Result.Success) {
            props.setForm({
                ...props.form,
                is_verified_mobile_number: 1,
            });
            handleModalClose();
        } else {
            setModalError(t("profile.verified_mobile_number_wrong"));
        }

        props.setLoading(false);
    };

    const handleModalBody = () => {
        return (
            <div className="verify-mobile-phone-wrapper">
                <h3>{t("profile.modal_verify_mobile_desc")}</h3>
                {modalError ? <p className="text-danger">{modalError}</p> : null}
                <div className="verify-mobile-phone-input-wrapper">
                    {_.map(digits, function (number, key) {
                        return (
                            <Input
                                key={key}
                                onChangeValue={(e) => handleChangeInput(e)}
                                id={key}
                                name={key}
                                value={number}
                                type="text"
                                errorNoText={digitErrors[key]}
                            />
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="form-group row item-company">
                <label className="col-sm-3 col-form-label text-right">
                    {t("profile.mobile")}
                    <span className="note-vali">＊</span>
                </label>
                <div className="col-sm-4">
                    <div className="item_form">
                        <Input
                            onChangeValue={(e) => props.handleChangeValue(e, true)}
                            name="mobile"
                            type="text"
                            value={props.form.mobile}
                            placeholder={t("profile.place_holder_mobile")}
                            error={!_.isEmpty(props.formErrors) ? props.formErrors.mobile : null}
                            className="form-control"
                        />

                        <ConfirmModal
                            title={t("Contract.draw_signature")}
                            showModal={isShow}
                            onClose={handleModalClose}
                            onConfirm={handleModalSubmit}
                            cancel={t("button.btn_cancel")}
                            confirm={t("button.btn_verify")}
                            body={handleModalBody()}
                            className="user-profile modal-verify-mobile"
                        />
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="submit-form">
                        {props.form.is_verified_mobile_number ? (
                            <button
                                type="button"
                                disabled
                                className="btn green button-submit btn-back"
                            >
                                {t("profile.verified_mobile_number")}
                            </button>
                        ) : (
                            <button
                                type="button"
                                onClick={handleModalShow}
                                className="btn blue button-submit"
                            >
                                {t("profile.not_verified_mobile_number")}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
