import _ from "lodash";

export const isLoggedIn = () => {
    return localStorage.getItem("user_verify_token") !== null;
};

export const isLoggedOut = () => {
    return !isLoggedIn();
};

export const loginUserEmail = () => {
    return localStorage.getItem("user_email") || "";
};

export const loginUserVerified = () => {
    return localStorage.getItem("user_is_verified") === 1;
};
