import React from "react";
import _ from "lodash"
import SupportConstants from "../../constants/SupportConstants";
import ClassNames from "classnames";
import Input from "../../shared/form/Input";
import { useTranslation } from "react-i18next";

function LayoutFormSupport (props){
  const { t } = useTranslation();
    return (
        <div className="page-content contract_suport">
        <div className="container">
          <div className="main-body-content">
            <div className="contract_suport_title border-bottom mrb-30">
              <h3 className="mt-30">{t("Support.title")}</h3>
            </div>
            <div className="contract_suport_ct">
              <p className="note-form text-center font-16">{t("Support.form_desc")}</p>
              <div className="row">
                <div className="col-sm-2 com-xs-2" />
                <div className="col-sm-8 com-xs-8">
                  <div className="md-full mt-30">
                    <form method="POST" target="hidden_iframe" onSubmit={props.handleSubmitForm}>
                      <div className="form-group row">
                        <div className="col-sm-8">
                          <Input
                            onChangeValue={(e) => props.handleChange(e, true)}
                            name="title"
                            type="text"
                            value={props.data.title}
                            error={props.dataErrors.title}
                            placeholder={t("Support.title_placehodel")}
                            disableClassFormGroup={1}
                          />
                        </div>
                        <div className="col-sm-4">
                          <select name="type_question" value={props.data.type_question} className="form-control select2me" onChange={(e) => props.handleChange(e, true)}>
                            <option value="">{t("Support.type_question_blank")}</option>
                            {
                              _.map(SupportConstants.Type, function(value, key){
                                return <option value={value} key={key}>{t("Support.type_question_"+value)}</option>
                              })
                            }
                          </select>
                          <span className={ClassNames("error", { hidden: !props.dataErrors.type_question })}>{props.dataErrors.type_question}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-8">
                          <Input
                            onChangeValue={(e) => props.handleChange(e, false)}
                            name="id_contract"
                            type="text"
                            value={props.data.id_contract}
                            error={props.dataErrors.id_contract}
                            placeholder={t("Support.id_contract_placehodel")}
                            disabled={parseInt(props.data.type_question) !== parseInt(SupportConstants.Type.Contract) ? "disabled" : ""}
                            disableClassFormGroup={1}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <textarea name="content" rows="6" className="rw-ckeditor form-control" onChange={(e) => props.handleChange(e, true)} defaultValue={props.data.content}/>
                          <span className={ClassNames("error", { hidden: !props.dataErrors.content })}>{props.dataErrors.content}</span>
                        </div>
                      </div>
                      <div className="call_button">
                        <button className="btn green button-submit" type="submit">{t("button.btn_next")}</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default LayoutFormSupport;