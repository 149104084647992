import React, { useState, useEffect } from "react";
import LayoutResetPassword from "./layoutResetPassword";
import LayoutResetSuccess from "./layoutResetSuccess";
import ApiConstant from "../../../constants/ApiConstant";
import { useHistory } from "react-router-dom";
import RouteConstant from "../../../constants/RouteConstant";
import { useTranslation } from "react-i18next";
import { api } from "../../../services/api";
import Loading from "../../../shared/form/Loading";

function ResetPassword(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [dataReset, setDataReset] = useState({
        password: "",
        confirm_password: "",
        status: false,
    });
    const [isLoading, setIsLoading] = useState(false);

    const [dataErrors, setDataErrors] = useState({
        password: "",
        confirm_password: "",
    });

    useEffect(() => {
        document.title = t("forgot_password.title_reset_password");
    });

    const params = new URLSearchParams(props.location.search);
    const email = params.get("email");
    const token = params.get("token");

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const result = await api.post(ApiConstant.Auth.ForgotPassword.CheckSession, {
                email: email,
                token_reset: token,
            });
            if (result) setIsLoading(false);
            if (result.api_result.result !== 0) {
                history.push(RouteConstant.Login.path);
            }
        }
        fetchData();
    }, [email, token, history]);

    const handleChange = (e, required) => {
        setDataReset({
            ...dataReset,
            [e.target.name]: e.target.value,
        });

        if (required) {
            setDataErrors({
                ...dataErrors,
                [e.target.name]: !e.target.value
                    ? t("validate.required", {
                          attr:
                              e.target.name === "password"
                                  ? t("forgot_password.txt_password")
                                  : t("forgot_password.txt_confirm_password"),
                      })
                    : "",
            });
        }
    };

    const checkconfirmPassword = () => {
        if (
            dataReset.password &&
            dataReset.confirm_password &&
            dataReset.password !== dataReset.confirm_password
        ) {
            setDataErrors({
                ...dataErrors,
                confirm_password: t("validate.msg_confirm_password"),
            });

            return false;
        }

        return true;
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        const errors = {
            password : !dataReset.password
            ? t("validate.required", { attr: t("forgot_password.txt_password") })
            : dataReset.password.length > 255
            ? t("Register.max_password")
            : dataReset.password.length < 6
            ? t("Register.min_password")
            : "",
            confirm_password : !dataReset.confirm_password
            ? t("validate.required", { attr: t("forgot_password.txt_confirm_password") })
            : dataReset.confirm_password.length > 255
            ? t("Register.max_confirm_password")
            : dataReset.confirm_password.length < 6
            ? t("Register.min_confirm_password")
            : ""
        };

        setDataErrors({
            ...dataErrors,
            confirm_password: errors.confirm_password,
            password: errors.password
        });

        if (!errors.password && !errors.confirm_password && checkconfirmPassword()) {
            setIsLoading(true);
            const result = await api.post(ApiConstant.Auth.ForgotPassword.ResetPassword, {
                email: email,
                token_reset: token,
                password: dataReset.password,
                password_confirmation: dataReset.confirm_password,
            });
            if (result) setIsLoading(false);
            if (result.api_result.result === ApiConstant.Result.Success) {
                setDataReset({
                    ...dataReset,
                    status: true,
                });
            }
        }
    };

    return (
        <>
            {dataReset.status ? (
                <LayoutResetSuccess />
            ) : (
                <LayoutResetPassword
                    handleSubmitForm={handleSubmitForm}
                    dataReset={dataReset}
                    setDataReset={setDataReset}
                    handleChange={handleChange}
                    dataErrors={dataErrors}
                />
            )}
            {isLoading ? <Loading /> : ""}
        </>
    );
}

export default ResetPassword;
