import React, { useState } from "react";
import { logout } from "../../services/AuthService";
import axiosAuth from "../../services/RequestService";
import ApiConstant from "../../constants/ApiConstant";
import { Link, useHistory, useLocation } from "react-router-dom";
import RouteConstant from "../../constants/RouteConstant";
import { useTranslation } from "react-i18next";
import UserConstant from "../../constants/UserConstant";

export default function Header() {
    const history = useHistory();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const avatarUser =
        typeof localStorage.getItem("profile_avatar") !== "undefined" &&
        localStorage.getItem("profile_avatar") !== ""
            ? localStorage.getItem("profile_avatar")
            : "/images/avatar_men.png";
    const nameUser = localStorage.getItem("name_user");
    const onLogout = () => {
        axiosAuth
            .get(ApiConstant.Auth.Logout)
            .then((response) => {
                if (response.data.api_result.result === 0) {
                    logout();
                    history.push(RouteConstant.Login.path);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const USER_COMPANY = UserConstant.UserType.Company;
    const userType = localStorage.getItem("user_type");
    const { t } = useTranslation();

    const getNavLinkClass = (path) => {
        if (!path.page) return "";
        const pathNameArr = location.pathname.split("/");
        return pathNameArr[1] === path.page ? "active" : "";
    };

    const checkBlur = (e) => {
        if (e && e.relatedTarget) {
            e.relatedTarget.click();
        }
        setShow(false);
    };

    return (
        <div className="page-header">
            <div className="page-header-inner container">
                <div className="top-megamenu">
                    <div className="page-sidebar-wrapper vetical-menu">
                        <div className="page-sidebar navbar-collapse collapse">
                            <ul
                                data-keep-expanded="false"
                                data-auto-scroll="true"
                                data-slide-speed="200"
                                className="page-sidebar-menu"
                            >
                                <li className={getNavLinkClass(RouteConstant.Contract.List)}>
                                    <Link to={RouteConstant.Contract.List.path}>
                                        <span className="title">{t("header.contract")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={RouteConstant.Support.path}>
                                        <span className="title">{t("header.support")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="">
                                        <span className="title">{t("header.notify")}</span>
                                    </Link>
                                </li>
                                {parseInt(userType) === USER_COMPANY ? (
                                    <li
                                        className={getNavLinkClass(RouteConstant.Company.ListUser.path)}
                                    >
                                        <Link to={RouteConstant.Company.ListUser.path}>
                                            <span className="title">{t("header.company")}</span>
                                        </Link>
                                    </li>
                                ) : (
                                    ""
                                )}
                                <li className={getNavLinkClass(RouteConstant.Setting)}>
                                    <Link to={RouteConstant.Setting.path}>
                                        <span className="title">{t("header.setting")}</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="top-menu">
                    <div className="nav navbar-nav float-right">
                        <div className="dropdown dropdown-user dropdown-dark">
                            <Link
                                onClick={(e) => e.preventDefault()}
                                to=""
                                data-toggle="dropdown"
                                data-hover="dropdown"
                                data-close-others="true"
                                aria-expanded="true"
                                className="dropdown-toggle"
                            >
                                <img alt="" src={avatarUser} className="img-circle" />
                                <button
                                    className="username username-hide-mobile"
                                    onClick={(e) => setShow(!show)}
                                    onBlur={checkBlur}
                                >
                                    {nameUser}
                                </button>
                            </Link>
                            {show && (
                                <ul
                                    className="dropdown-menu dropdown-menu-default"
                                    style={{ display: show ? "block" : "none" }}
                                >
                                    <li>
                                        <Link
                                            to={
                                                parseInt(userType) === USER_COMPANY
                                                    ? RouteConstant.Profile.UserCompany.path
                                                    : RouteConstant.Profile.User.path
                                            }
                                        >
                                            <img alt="" src="/images/user-avatar.png" />{" "}
                                            {t("header.my_profile")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="" onClick={onLogout}>
                                            <img alt="" src="/images/exit.png" />{" "}
                                            {t("header.logout")}
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </div>
                        {/* <div className="dropdown dropdown-user dropdown-dark"><a href="javascript:;" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" aria-expanded="true" className="dropdown-toggle"><img alt="" src="../../assets/images/avatar_men.png" className="img-fluid rounded-circle" /><span className="username username-hide-mobile">Longtt</span></a>
                            <ul className="dropdown-menu dropdown-menu-default">
                            <li><a href="#"><img alt="" src="../../assets/images/user-avatar.png" /> My Profile </a></li>
                            <li><a href="#"><img alt="" src="../../assets/images/exit.png" /> ログアウト</a></li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
