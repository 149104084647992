import React, { useState, useEffect } from "react";
import ApiConstant from "../../constants/ApiConstant";
import { useTranslation } from "react-i18next";
import {api} from "../../services/api";
import Input from "../../shared/form/Input";
import Loading from "../../shared/form/Loading";
import { Link } from "react-router-dom";
import { trim } from "lodash";
import ConfirmModal from "../../shared/form/ConfirmModal";
import RouteConstant from "../../constants/RouteConstant";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export default function Group() {
    const history = useHistory();
    const [dataGroup, setDataGroup] = useState("");
    const [groupName, setGroupName] = useState("");
    const [dataUser, setDataUser] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [userGroup, setUserGroup] = useState([]);
    const [formGroup, setFormGroup] = useState({
        group_name: "",
        status: "",
        data: "",
    });
    const [showAddAttr, setShowAddAttr] = useState(false);
    const [attributes, setAttributes] = useState([]);
    const [newAttr, setNewAttr] = useState("");
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [errors, setError] = useState([]);

    const handleCloseAddAttr = () => setShowAddAttr(false);
    const handleShowAddAttr = () => {
        setShowAddAttr(true);
    };

    const USER_NO_VERIFY = 0;
    const USER_VERIFY = 1;

    const handleAddAttr = async(e) => {
        const form = {
            user_in_group: userGroup,
            group_name: formGroup.group_name,
            status: 1,
        };
        if (trim(formGroup.group_name) !== "") {
            setLoading(true);
            const path = ApiConstant.Group.Create;
            const result = await api.post(path, form);
            if (result.api_result.result === ApiConstant.Result.Success) {
                setLoading(false);
                toast.success(t("group.create_sucess", { attr: formGroup.group_name }))
                getDataGroup();
            } else {
                setLoading(false);
                toast.error(t('group.create_fail', { attr: formGroup.group_name }))
                setError({
                    ...errors,
                    msg: result.api_result.message,
                });
            }
            attributes.push(newAttr);
            setAttributes([...attributes]);
            setShowAddAttr(false);
            setNewAttr("");
        }
    };

    const getDataGroup = async (e) => {
        setLoading(true);
        const path = ApiConstant.Group.List;
        const result = await api.get(path, { group_name: groupName });
        if (result.api_result.result === ApiConstant.Result.Success) {
            setLoading(false);
            let datas = result.datas;
            setDataGroup(datas);
        } else {
            setLoading(false);
            setError({
                ...errors,
                msg: result.api_result.message,
            });
        }
    };

    const searchDataGroup = async (e) => {
        history.push(RouteConstant.Group.Search.path+'?group_name='+groupName);
    }

    useEffect(() => {
        document.title = t("group.title");
        getDataGroup();
    }, [t]);

    const handleChangeValue = (e) => {
        setGroupName(e.target.value);
    };

    const handleChangeValueGroup = (e) => {
        setFormGroup({
            ...formGroup,
            [e.target.name]: e.target.value,
            status: 1,
        });
    };

    const searchItemEmail = async (e) => {
        setDataUser ([])
        setLoading(true);
        const path = ApiConstant.Group.User.Search
        const result = await api.get(path, keyword);
        if (result.api_result.result === 0){
            setLoading(false);
            let data = result.datas
            setDataUser(
                data
            )
        } else{
            setLoading(false)
            setError({
                ...errors,
                keyword: result.api_result.message
            })
        }
    }
    const handleChangeValueKeyword = async (e) => {
        setKeyword({
            ...keyword,
            [e.target.name]: e.target.value,
        });
        setError({
            keyword: "",
        });
    };
    function renderListUser(user, index) {
        return (
            <li key={index}>
                <div className="avatar-group"><span>{'00'+user.user_id}</span></div>
                <p className="title">{user.name_kana}</p>
                <p>{user.email}</p>
                <input type="checkbox" onChange={(e) => selectValueUser(e,user)} name="getUser" className="check-box"/>
            </li>
        )
    }

    const selectValueUser = (e, user) => {
        if(e.target.checked) {
            setUserGroup([
                ...userGroup,
                user
            ])
        }
    }

    function renderModalAddGroup() {
        return (
            <div className="list-group">
                <div className="md-full mt-10">
                    <form method="POST" target="hidden_iframe">
                        <Input type="hidden" name="status" value="1" />
                        <div className="form-group form-search">
                            <Input
                                onChangeValue={(e) => handleChangeValueGroup(e, true)}
                                name="group_name"
                                type="text"
                                value={formGroup.group_name}
                                placeholder={t("group.place_holder_group_name")}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group form-search">
                            <Input
                                onChangeValue={(e) => handleChangeValueKeyword(e, true)}
                                name="keyword"
                                type="text"
                                value={keyword.keyword}
                                placeholder={t("group.place_holder_email_user")}
                                error={errors.keyword}
                                className="form-control"
                            />
                            <button
                                type="button"
                                onClick={searchItemEmail}
                                className="btn green button-submit"
                            >
                                {t("group.search")}
                            </button>
                        </div>
                    </form>
                </div>
                <div className="list-group-content">
                    <ul>
                        {dataUser && dataUser.length > 0 ? (
                            dataUser.map((user, index) => {
                                return renderListUser(user, index);
                            })
                        ) : ""
                        }
                    </ul>
                </div>
            </div>
        );
    }
    const cancelJoinGroup = async (e, group) => {
        if (window.confirm(t("group.confirm_cancel_join_group", { attr: group.group_name }))) {
            let pathDeleteUserInGroup = ApiConstant.Group.User.Cancel
            const cancelJoin = await api.post(pathDeleteUserInGroup, {
                user_group_id: group.user_group_id,
            });
            if (cancelJoin.api_result.result === ApiConstant.Result.Success) {
                toast.success(t('group.cancel_join_success', { attr: group.group_name }))
            } else {
                toast.error(t('group.cancel_join_fail', { attr: group.group_name }))
            }
            getDataGroup()
            return cancelJoin
        }
    }
    const confirmJoinGroup = async (e, group) => {
        if (window.confirm(t("group.confirm_join_group", { attr: group.group_name }))) {
            let pathConfirmUserInGroup = ApiConstant.Group.User.Confirm
            const confirmJoin = await api.post(pathConfirmUserInGroup, {
                user_group_id: group.user_group_id,
            });
            if (confirmJoin.api_result.result === ApiConstant.Result.Success) {
                toast.success(t('group.confirm_join_success', { attr: group.group_name }))
            } else {
                toast.error(t('group.confirm_join_fail', { attr: group.group_name }))
            }
            getDataGroup()
            return confirmJoin
        }
    }

    function renderListConfirm(group, index) {
        if (group.verify === USER_NO_VERIFY) {
            return (
                <li key={index}>
                    <img alt="" src="../../assets/images/avatar-talk.jpg" className="avatar-group" />
                    <p className="title">{group.group_name}</p>
                    <button type="button" onClick={(e) => confirmJoinGroup(e, group, true)} className="btn btn-primary">{t("group.allow_join_group")}</button>
                    <button type="button" onClick={(e) => cancelJoinGroup(e, group, true)} className="btn btn-danger cancel">{t("group.cancel_join_group")}</button>
                </li>
            )
        }
    }

    function renderListGroup(group, index) {
        if (group.verify === USER_VERIFY) {
            return (
                <li key={index}>
                    <Link to={ApiConstant.Group.Detail.replace(":groupId",group.user_group_id)}>
                        <img alt="" src="../../assets/images/avatar-talk.jpg" className="avatar-group" />
                        <p className="title">{ group.group_name }</p>
                        <p className="group"><img alt="" src="../../assets/images/user-group.png" className="icon-group" /><span>{group.count_user}</span></p>
                    </Link>
                </li>
            )
        }
    }

    return (
        <div>
            <div className="clearfix" />
            <div className="page-container">
                <div className="page-content-wrapper">
                    <div className="page-content contract_group">
                        <div className="container">
                            <div className="main-body-content">
                                <div className="contract_suport_title border-bottom mrb-10">
                                    <h3 className="mt-30">{t("group.manager_group")}</h3>
                                </div>
                                <div className="contract_group_ct">
                                    <div className="row">
                                        <div className="col-sm-2 com-xs-2" />
                                        <div className="col-sm-8 com-xs-8">
                                            <div className="md-full mt-20 contract_form_search_group">
                                                <form method="GET" target="hidden_iframe">
                                                    <div className="form-group form-search">
                                                        <Input
                                                            onChangeValue={(e) =>
                                                                handleChangeValue(e, true)
                                                            }
                                                            name="group_name"
                                                            type="text"
                                                            value={groupName.group_name}
                                                            placeholder={t(
                                                                "group.place_holder_group_name",
                                                            )}
                                                            className="form-control"
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={searchDataGroup}
                                                            className="btn green button-submit"
                                                        >
                                                            {t("group.search_group")}
                                                        </button>
                                                        
                                                    </div>
                                                </form>
                                                <button
                                                    type="button"
                                                    onClick={handleShowAddAttr}
                                                    className="btn button-add-group search-add-group"
                                                >
                                                    <img
                                                        alt=""
                                                        src="../../assets/images/plus_color.png"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-2 com-xs-2" />
                                        <div className="col-sm-8 com-xs-8">
                                            <div className="list-group md-full mt-10">
                                                <div className="list-group-note">
                                                    <p className="font-16">
                                                        {t("group.group_inprocess_join")}
                                                    </p>
                                                </div>
                                                <div className="list-group-content">
                                                    <ul>
                                                        {dataGroup.length > 0 ? (
                                                        dataGroup.map((group, index) => {
                                                            return (
                                                                renderListConfirm(group, index)
                                                            );
                                                        })
                                                        ): (
                                                            <li className="sub-item text-center">
                                                                <span>{t("group.nodata_invite")}</span>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                                <div className="list-group-note">
                                                    <p className="font-16">
                                                        {t("group.group_join")}
                                                    </p>
                                                </div>
                                                <div className="list-group-content">
                                                    <ul>
                                                    {dataGroup.length > 0 ? (
                                                        dataGroup.map((group, index) => {
                                                            
                                                        return (
                                                            renderListGroup(group,index)
                                                            );
                                                        })
                                                        ) : (
                                                            <li className="sub-item text-center">
                                                                <span>{t("group.nodata_join")}</span>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                                <div className="add-group">
                                                    <button
                                                        type="button"
                                                        onClick={handleShowAddAttr}
                                                        className="btn button-add-group float-right"
                                                    >
                                                        <img
                                                            alt=""
                                                            src="../../assets/images/plus_color.png"
                                                        />
                                                    </button>
                                                    <ConfirmModal
                                                        title={t("group.create_group")}
                                                        showModal={showAddAttr}
                                                        onClose={handleCloseAddAttr}
                                                        onConfirm={handleAddAttr}
                                                        body={renderModalAddGroup()}
                                                        cancel={t("group.cancel")}
                                                        confirm={t("group.save")}
                                                        className="contract_group modal-add-group"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <Loading /> : ""}
        </div>
    );
}
