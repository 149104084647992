import React, { useState, useEffect, useRef } from "react";
import ApiConstant from "../../constants/ApiConstant";
import _ from "lodash";
import Input from "../../shared/form/Input";
import { useTranslation } from "react-i18next";
import { api } from "../../services/api";
import RouteConstant from "../../constants/RouteConstant";
import PdfViewer from "../../components/contract/update/PdfViewer";
import Loading from "../../shared/form/Loading";
import { loginUserEmail, isLoggedOut, loginUserVerified } from "../../helpers/AuthHelper";
import { redirectToAppSchemeOrWebPath } from "../../helpers/MobileAppHelper";
import ContractSuccess from "./ContractSuccess";
import ContractAssignmentConstant from "../../constants/ContractAssignmentConstant";
import ContractConstant from "../../constants/ContractConstant";
import qs from "querystring";
import ConfirmContractMustVerified from "./ConfirmContractMustVerified";
import Cookies from "universal-cookie";
import SignatureCanvas from "react-signature-canvas";
import ConfirmModal from "../../shared/form/ConfirmModal";

export default function ConfirmContract(props) {
    const shareToken = props.match.params.shareToken;
    const queryParams = new URLSearchParams(props.location.search);

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [mustVerified, setMustVerified] = useState(false);
    const [contract, setContract] = useState({
        id: "",
        title: "",
        content: "",
        signEmail: "",
        shareToken: shareToken,
        attributes: [],
        status: ContractAssignmentConstant.Status.NotSignYet,
        assignmentType: ContractAssignmentConstant.Type.View,
        contractType: ContractConstant.Type.SignWhenever,
        contractSignedData: {
            dataURL: "",
            content: "",
        },
        userSignature: {
            id: "",
            signature: "",
        },
    });
    const [errors, setErrors] = useState({
        global: [],
        formFields: [],
    });
    const [isComplete, setIsComplete] = useState(false);

    const signatureRef = useRef({});
    const [signatureId, setSignatureId] = useState("");
    const [signatureMessage, setSignatureMessage] = useState("");

    const [modalSignatureStatus, setModalSignatureStatus] = useState(false);
    const [modalDenySigningStatus, setModalDenySigningStatus] = useState(false);
    const [modalSigningStatus, setModalSigningStatus] = useState(false);
    const [thumbnailData, setThumbnailData] = useState([]);

    useEffect(() => {
        document.title = t("Contract.document_title_confirm_contract");
    });

    useEffect(() => {
        async function handleFetchAssignmentContract() {
            setLoading(true);
            const result = await api.get(ApiConstant.Contract.Confirm, {
                share_token: shareToken,
            });

            if (result.api_result.result === ApiConstant.Result.Success) {
                const contractType = result.datas.contractType;

                if (
                    _.includes(
                        [
                            ContractConstant.Type.SignWhenLogin,
                            ContractConstant.Type.SignWhenLoginAndVerified,
                        ],
                        contractType,
                    )
                ) {
                    if (isLoggedOut()) {
                        const currentUrl = window.location.href;

                        const cookies = new Cookies();
                        cookies.set(
                            ContractAssignmentConstant.SigningContractDataKey,
                            JSON.stringify({ url: currentUrl, signEmail: result.datas.signEmail }),
                            {
                                path: "/",
                                maxAge: ContractAssignmentConstant.SigningContractUrlExpires,
                            },
                        );
                        window.location.href =
                            RouteConstant.Login.path +
                            "?" +
                            qs.stringify({ redirect_url: currentUrl });

                        return false;
                    }

                    if (
                        contractType === ContractConstant.Type.SignWhenLoginAndVerified &&
                        !loginUserVerified()
                    ) {
                        setMustVerified(true);

                        return false;
                    }
                }

                setContract(result.datas);
                setThumbnailData(result.datas.file_before_sign_file.thumbnail_data);
                setSignatureId(result.datas.userSignature.id);

                // signatureRef.current.fromDataURL();
                // signatureRef.current.off();
            }
            setLoading(false);
        }

        handleFetchAssignmentContract();
    }, [shareToken]);

    const canEditForm = () => {
        return (
            contract.assignmentType === ContractAssignmentConstant.Type.Sign &&
            !_.includes(
                [
                    ContractAssignmentConstant.Status.Sign,
                    ContractAssignmentConstant.Status.Reject,
                    ContractAssignmentConstant.Status.Signing,
                ],
                contract.status,
            )
        );
    };

    const handleChangeAttribute = (value, attrKey) => {
        const inputAttributes = contract.attributes;
        for (let i = 0; i < inputAttributes.length; i++) {
            if (inputAttributes[i].key === attrKey) {
                inputAttributes[i].value = value;
            }
        }

        setContract({
            ...contract,
            attributes: inputAttributes,
        });
    };

    const handleSigningContract = async () => {
        const inputAttributes = contract.attributes;
        const outputErrors = [];

        if (inputAttributes.length === 0) {
            return false;
        }

        for (let i = 0; i < inputAttributes.length; i++) {
            if (
                parseInt(inputAttributes[i].type) ===
                ContractAssignmentConstant.AttributeType.Signature
            ) {
                inputAttributes[i].value = signatureId;
            }

            if (!inputAttributes[i].key || !inputAttributes[i].value) {
                outputErrors.push({
                    key: inputAttributes[i].key,
                    message: t("validate.required", { attr: inputAttributes[i].key }),
                });
            }
        }

        setContract({
            ...contract,
            attributes: inputAttributes,
        });

        setErrors({
            ...errors,
            formFields: outputErrors,
        });
        if (outputErrors.length > 0) {
            return false;
        }

        if (!modalSigningStatus) {
            setModalSigningStatus(true);

            return false;
        }

        setLoading(true);
        const result = await api.post(ApiConstant.Contract.Confirm, {
            share_token: shareToken,
            attributes: contract.attributes,
        });

        if (result.api_result.result === ApiConstant.Result.Success) {
            setIsComplete(true);
        } else {
            setErrors({
                ...errors,
                global: [result.api_result.message],
            });
        }

        setModalSigningStatus(false);
        setLoading(false);
    };

    const handleDenySigningContract = async () => {
        setLoading(true);
        const result = await api.post(ApiConstant.Contract.DenySigning, {
            share_token: shareToken,
        });

        if (result.api_result.result === ApiConstant.Result.Success) {
            setIsComplete(true);
        } else {
            setErrors({
                ...errors,
                global: [result.api_result.message],
            });
        }
        setModalDenySigningStatus(false);
        setLoading(false);
    };

    const handleShowErrors = () => <p>{errors.global.join(", ")}</p>;

    const handleRenderContractAttributes = () => {
        let html = "";

        if (!_.isEmpty(contract.attributes)) {
            html = contract.attributes.map((attr, attrKey) => {
                const isTypeSignature =
                    parseInt(attr.type) === ContractAssignmentConstant.AttributeType.Signature;
                const attrErrors = errors.formFields.filter((error) => {
                    return error.key === attr.key;
                });

                const msgErrors = [];
                for (let i = 0; i < attrErrors.length; i++) {
                    msgErrors.push(attrErrors[i].message);
                }

                return (
                    <div key={attrKey} className="form-group row item-company update-row-84">
                        <div className="col-sm-3 col-xs-8 text-right">
                            <label className="btn yellow button-submit btn-orange">
                                {attr.key}
                            </label>
                        </div>
                        <div className="col-sm-5 col-xs-4">
                            <div className="item_form">
                                {isTypeSignature ? (
                                    <>
                                        <div className="contract-signature-wrapper">
                                            {contract.contractSignedData.dataURL ? (
                                                <div className="contract-signature-image">
                                                    <img
                                                        src={contract.contractSignedData.dataURL}
                                                        alt="Signature"
                                                    />
                                                </div>
                                            ) : null}
                                            {canEditForm() ? (
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    type="button"
                                                    onClick={handleShowDrawingSignature}
                                                >
                                                    {t("button.btn_draw_signing")}
                                                </button>
                                            ) : null}
                                        </div>
                                        <div className="clearfix" />
                                    </>
                                ) : null}

                                <Input
                                    onChangeValue={(e) =>
                                        handleChangeAttribute(e.target.value, attr.key)
                                    }
                                    type={isTypeSignature ? "hidden" : "text"}
                                    value={isTypeSignature ? signatureId : attr.value}
                                    error={msgErrors.join(", ")}
                                    className="form-control"
                                    disabled={!canEditForm()}
                                />
                            </div>
                        </div>
                    </div>
                );
            });
        }

        return html;
    };

    const handleRenderDrawingSignature = () => {
        return (
            <div className="contract-draw-signature-wrapper">
                <div className="contract-draw-signature-message text-danger">
                    {signatureMessage}
                </div>
                <div className="contract-draw-signature-action">
                    <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={handleClearSignature}
                    >
                        {t("button.btn_clear")}
                    </button>
                </div>
                <SignatureCanvas
                    penColor="black"
                    ref={signatureRef}
                    canvasProps={{ className: "contract-draw-signature-canvas" }}
                />
            </div>
        );
    };

    const handleShowDrawingSignature = () => {
        setModalSignatureStatus(true);
    };

    const handleClearSignature = () => {
        signatureRef.current.clear();
    };

    const handleCloseDrawingSignature = () => setModalSignatureStatus(false);

    const handleResizeSignatureBeforeUpload = () => {
        const signatureCanvas = signatureRef.current.getTrimmedCanvas();

        const resizedCanvas = document.createElement("canvas");
        const resizedContext = resizedCanvas.getContext("2d");

        resizedCanvas.height = "80";
        resizedCanvas.width = "124";

        resizedContext.drawImage(signatureCanvas, 0, 0, 124, 80);
        return resizedCanvas.toDataURL();
    };

    const handleSubmitSignature = async () => {
        if (signatureRef.current.isEmpty()) {
            handleCloseDrawingSignature();

            return false;
        }

        const dataURL = handleResizeSignatureBeforeUpload();

        if (!dataURL) {
            return false;
        }

        setLoading(true);
        const result = await api.post(ApiConstant.Profile.AddSignature, {
            signature: dataURL,
        });

        if (result.api_result.result === ApiConstant.Result.Success) {
            setContract({
                ...contract,
                contractSignedData: {
                    ...contract.contractSignedData,
                    dataURL: dataURL,
                },
            });
            setSignatureId(result.datas.id);

            handleCloseDrawingSignature();
        } else {
            setSignatureMessage(result.api_result.message);
        }
        setLoading(false);
    };

    const contractFileContent = () => {
        if (contract.status === ContractAssignmentConstant.Status.Sign) {
            return contract.contractSignedData.content;
        }

        return contract.content;
    };

    const hanldeBackToPreviousStep = () => {
        redirectToAppSchemeOrWebPath(queryParams, RouteConstant.Contract.List.path);
    };

    if (mustVerified) {
        return <ConfirmContractMustVerified qParams={queryParams} />;
    }

    return (
        <>
            {loading ? <Loading /> : null}
            <div className="clearfix" />
            <div className="page-container">
                <div className="page-content-wrapper">
                    {isComplete ? (
                        <ContractSuccess
                            qParams={queryParams}
                            title={t("Contract.msg_confirm_contract_success")}
                        />
                    ) : (
                        <div className="page-content contract_edit contract_creat">
                            <div className="container">
                                <div className="main-body-content">
                                    <div className="content-enter-name-contact">
                                        <div className="contract_setting_title title-header">
                                            <h5>{contract.title}</h5>
                                        </div>
                                        <div className="contract_list_setting">
                                            {console.log(contract)}
                                            <PdfViewer thumbnailData={thumbnailData} />
                                        </div>
                                    </div>
                                    <div className="content-input-item">
                                        <div className="contract_setting_title title-header">
                                            <h5>{t("Contract.msg_please_input_attributes")}</h5>
                                        </div>
                                        <div className="contract_list-input">
                                            {handleShowErrors()}

                                            <div className="form-edit">
                                                <form method="POST">
                                                    {handleRenderContractAttributes()}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="submit-form">
                                        {canEditForm() ? (
                                            <button
                                                onClick={() => setModalDenySigningStatus(true)}
                                                className="btn red button-deny-signing"
                                                type="button"
                                            >
                                                {t("button.btn_deny_signing")}
                                            </button>
                                        ) : null}
                                        {canEditForm() ? (
                                            <button
                                                className="btn blue button-sign"
                                                onClick={handleSigningContract}
                                                type="button"
                                            >
                                                {t("button.btn_sign")}
                                            </button>
                                        ) : null}

                                        {contract.assignmentType ===
                                            ContractAssignmentConstant.Type.View ||
                                        contract.contractType !==
                                            ContractConstant.Type.SignWhenever ? (
                                            <button
                                                className="btn gray btn-back"
                                                onClick={hanldeBackToPreviousStep}
                                                type="button"
                                            >
                                                {t("button.btn_back")}
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <ConfirmModal
                title={t("Contract.draw_signature")}
                showModal={modalSignatureStatus}
                onClose={handleCloseDrawingSignature}
                onConfirm={handleSubmitSignature}
                cancel={t("button.btn_cancel")}
                confirm={t("button.btn_sign")}
                body={handleRenderDrawingSignature()}
                className="contract_assignment modal-draw-signature"
            />

            <ConfirmModal
                onClose={() => setModalSigningStatus(false)}
                onConfirm={handleSigningContract}
                title={contract.title}
                showModal={modalSigningStatus}
                body={t("Contract.msg_confirm_sign")}
                cancel={t("button.btn_no")}
                confirm={t("button.btn_yes")}
                className="contract_assignment modal-confirm-sign"
            />

            <ConfirmModal
                onClose={() => setModalDenySigningStatus(false)}
                onConfirm={handleDenySigningContract}
                title={t("Contract.deny_sign")}
                showModal={modalDenySigningStatus}
                body={t("Contract.msg_confirm_deny_sign")}
                cancel={t("button.btn_no")}
                confirm={t("button.btn_yes")}
                className="contract_assignment modal-confirm-deny-sign"
            />
        </>
    );
}
