import React, {useState, useEffect} from 'react';
import ApiConstant from "../../constants/ApiConstant";
import { useTranslation } from "react-i18next";
import {useParams } from "react-router-dom";
import {api} from "../../services/api";
import Input from "../../shared/form/Input";
import Loading from "../../shared/form/Loading";
import ConfirmModal from '../../shared/form/ConfirmModal';
import RenderDetailUser from "./RenderDetailUser"
import ButtonConfirmRequest from "./ButtonConfirmRequest"
import { toast } from 'react-toastify';
import RouteConstant from '../../constants/RouteConstant';
import { useHistory } from "react-router-dom";

export default function Group() {
    const params = useParams();
    const [dataGroup, setDataGroup] = useState("");
    const [dataUser, setDataUser] = useState([]);
    const [userGroup, setUserGroup] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [showAddAttr, setShowAddAttr] = useState(false);
    const [attributes, setAttributes] = useState([]);
    const [newAttr, setNewAttr] = useState("");
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [errors, setError] = useState([]);
    const history = useHistory();

    const handleCloseAddAttr = () => setShowAddAttr(false);
    const handleShowAddAttr = () => {
        setShowAddAttr(true);
    };

    const searchItemEmail = async (e) => {
        setDataUser ([])
        setLoading(true);
        const path = ApiConstant.Group.User.Search
        const result = await api.get(path, keyword);
        if (result.api_result.result === 0){
            setLoading(false);
            let data = result.datas
            setDataUser(
                data
            )
        } else{
            setLoading(false)
            setError({
                ...errors,
                keyword: result.api_result.message
            })
        }
    }
    
    const handleChangeValue = (e) => {
        e.preventDefault();
        setKeyword({
            ...keyword,
            [e.target.name]: e.target.value,
            group_id: params.groupId
        });
        setError({
            keyword: ''
        })
    }

    const handleAddAttr = async (e) => {
        let paramCreate = {
            userGroup,
            group_id: params.groupId
        }
        setLoading(true);
        const path = ApiConstant.Group.User.Create
        const result = await api.post(path, paramCreate);
        if (result.api_result.result === 0){
            setLoading(false);
            toast.success(t('group.added_member_success'))
            getDataGroup()
        } else {
            setLoading(false);
            toast.error(t('group.added_member_fail'))
            setError({
                ...errors,
                msg: result.api_result.message
            })
        }
        attributes.push(newAttr);
        setAttributes([...attributes]);
        setShowAddAttr(false);
        setNewAttr("");
    }

    const getDataGroup = async (e) => {
        setLoading(true);
        const path = ApiConstant.Group.Detail
        const result = await api.get(path.replace(':groupId', params.groupId));
        if (result.api_result.result === 0){
            setLoading(false);
            let datas = result.datas;
            setDataGroup(datas);
        } else{
            setError({
                ...errors,
                msg: result.api_result.message
            })
        }
    }

    const handleOutGroup = async (e) => {
        if (window.confirm(t("group.confirm_out_group", { attr: dataGroup.group_name }))) {
            let pathOutGroup = ApiConstant.Group.User.Cancel
            const cancelJoin = await api.post(pathOutGroup, {
                user_group_id: params.groupId,
            });
            if (cancelJoin.api_result.result === ApiConstant.Result.Success) {
                history.push(RouteConstant.Group.List.path);
                toast.success(t('group.out_group_success', { attr: dataGroup.group_name }))
            } else {
                toast.error(t('group.out_group_fail', { attr: dataGroup.group_name }))
            }
            return cancelJoin
        }
    }

    useEffect( () => {
        document.title = t("group.title");
        getDataGroup()
    },[t]);
    
    const OWNER_GROUP = 1;
    const ADMIN_GROUP = 2;
    const MEMBER_GROUP = 0

    function renderListUser(user, index) {
        return (
            <li key={index}>
                <div className="avatar-group"><span>{'00'+user.user_id}</span></div>
                <p className="title">{user.name_kana}</p>
                <p>{user.email}</p>
                <input type="checkbox" onChange={(e) => selectValueUser(e,user)} name="getUser" className="check-box"/>
            </li>
        )
    }

    const selectValueUser = (e, user) => {
        if(e.target.checked) {
            setUserGroup([
                ...userGroup,
                user
            ])
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            searchItemEmail();
            return false;
        }
    }

    function renderModalAddUser() {
        return (
            <div>
                <div className="list-group">
                    <div className="md-full mt-10">
                        <form method="POST" target="hidden_iframe">
                            <div className="form-group form-search">
                                <Input 
                                    onChangeValue={(e) => handleChangeValue(e, true)}
                                    name="keyword"
                                    type="text"
                                    onKeyDown={handleKeyDown}
                                    value={keyword.keyword}
                                    placeholder={t("group.place_holder_email_user")}
                                    error={errors.keyword}
                                    className="form-control"
                                />
                                <button type="button" onClick={searchItemEmail} className="btn green button-submit">{t("group.search")}</button>
                            </div>
                        </form>
                    </div>
                    <div className="list-group-content">
                        <ul>
                            {dataUser && dataUser.length > 0 ? (
                                dataUser.map((user, index) => {
                                    return renderListUser(user, index)    
                                })
                            ) : ""
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="clearfix" />
            <div className="page-container">
                <div className="page-content-wrapper">
                    <div className="page-content contract_group">
                        <div className="container">
                            <div className="main-body-content">
                                <div className="contract_suport_title border-bottom mrb-10">
                                    <h3 className="mt-30">{dataGroup.group_name}</h3>
                                </div>
                                <div className="row">
                                    <div className="col-sm-2 com-xs-2" />
                                    <div className="col-sm-8 com-xs-8">
                                        <div className="list-group md-full mt-10">
                                        <div className="list-group-note">
                                        {dataGroup.role_in_group === OWNER_GROUP || dataGroup.role_in_group === ADMIN_GROUP ?
                                            <ButtonConfirmRequest
                                                dataGroup={dataGroup}
                                            />
                                            :""
                                        }
                                        </div>
                                        <div className="list-group-content">
                                            <div className="list-item">
                                            <p className="font-16 mt-20">{t("group.role_admin")}</p>
                                            <ul>
                                                {dataGroup && dataGroup.user_in_group.admin.length > 0 ? (
                                                    dataGroup.user_in_group.admin.map((group, index) => {
                                                        return (
                                                            <RenderDetailUser
                                                                group={group}
                                                                roleCurrentUser={dataGroup.role_in_group}
                                                                key={index}
                                                                role='admin'
                                                                role_in_group={dataGroup.role_in_group}
                                                                getDataGroup={getDataGroup}
                                                            />
                                                        )
                                                    })
                                                ) : (
                                                    <li className="sub-item text-center">
                                                        <span>{t("group.nodata")}</span>
                                                    </li>
                                                )}
                                            </ul>
                                            <p className="font-16 mt-20">{t("group.role_member")}</p>
                                            <ul>
                                                {dataGroup.user_in_group && dataGroup.user_in_group.member ? (
                                                    dataGroup.user_in_group.member.map((group, index) => {
                                                        return (
                                                            <RenderDetailUser
                                                                group={group}
                                                                roleCurrentUser={dataGroup.role_in_group}
                                                                key={index}
                                                                role='member'
                                                                role_in_group={dataGroup.role_in_group}
                                                                getDataGroup={getDataGroup}
                                                            />
                                                        )
                                                    })
                                                ) : (
                                                    <li className="sub-item text-center">
                                                        <span>{t("group.nodata")}</span>
                                                    </li>
                                                )}
                                            </ul>
                                            
                                            </div>
                                        </div>
                                        <div className="add-group">
                                            {dataGroup.role_in_group === OWNER_GROUP ?
                                            <button type="button" 
                                                    onClick={handleShowAddAttr} 
                                                    className="btn button-add-group float-right"
                                                >
                                                    <img alt="" src="/assets/images/plus_color.png" />
                                            </button>
                                            :
                                            dataGroup.role_in_group === ADMIN_GROUP ||  dataGroup.role_in_group === MEMBER_GROUP?
                                            <button type="button"
                                                onClick={handleOutGroup}
                                                className="btn button-submit button-add-group float-right"
                                            >
                                                    {t("group.out_group")}
                                            </button>
                                            : ""
                                            }
                                            <ConfirmModal
                                                title={t("group.add_member")} 
                                                showModal={showAddAttr}
                                                onClose={handleCloseAddAttr}
                                                onConfirm={handleAddAttr}
                                                body={renderModalAddUser()}
                                                cancel={t("group.cancel")}
                                                confirm={t("group.save")}
                                                className="contract_group modal-add-group"
                                            />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
            {
                loading ? <Loading /> : ''
            }
        </div>
    );
}