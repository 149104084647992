import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ApiConstant from "../../constants/ApiConstant";
import Loading from "../../shared/form/Loading";
import RouteConstant from "../../constants/RouteConstant";
import { api } from "../../services/api";
import { redirectToAppSchemeOrWebPath } from "../../helpers/MobileAppHelper";
import { toast } from "react-toastify";

function RegisterSuccess(props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        document.title = t("Register.register");
    });

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const form = {
                user_id: props.match.params.userId,
                token: props.match.params.token,
            };
            const result = await api.post(ApiConstant.Auth.ActiveUser, form);
            if (result.api_result.result === ApiConstant.Result.Success) {
                toast.success(t("Register.active_success"));
            } else {
                toast.error(t("Register.active_failed"));
            }
            setLoading(false);
        }
        fetchData();
    }, [props.match.params.userId, props.match.params.token]);

    const handleBackToLogin = () => {
        const queryParams = new URLSearchParams(props.location.search);
        redirectToAppSchemeOrWebPath(queryParams, RouteConstant.Login.path);
    };

    return (
        <div className="login">
            {loading ? <Loading /> : null}
            <div className="page-container">
                <div className="login_wrapper register register_active">
                    <div className="form login_form login">
                        <section className="login_content">
                            <form method="POST" target="hidden_iframe">
                                <h1>{t("Register.user_active")}</h1>
                                <div className="login-note">
                                    <p className="text-center">
                                        {t("Register.your_account_has_been_activated")}
                                    </p>
                                </div>
                                <div className="submit-form">
                                    <button
                                        className="btn green button-submit"
                                        type="button"
                                        onClick={handleBackToLogin}
                                    >
                                        {t("Register.done")}
                                    </button>
                                </div>
                                <div className="clearfix" />
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegisterSuccess;
