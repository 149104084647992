import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";

const PdfViewer = ({ thumbnailData }) => {
    const choseImage = (e, href) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const imgRef = useRef();
    const [width, setWidth] = useState();
    const handleZoomIn = () => {
        setWidth(width + (width * 10) / 100);
    };
    const handleZoomOut = () => {
        setWidth(width - (width * 10) / 100);
    };
    const onDownload = () => {
        ///TODo download
    };
    useEffect(() => {
        if (imgRef.current) {
            setWidth(imgRef.current.offsetWidth);
        }
    }, [imgRef]);
    const imgStyle = { width };

    return (
        <div className="page-content-wrapper">
            <div className="page-content contract_slider">
                <div className="main-body-content">
                    <div className="contract_slider">
                        <div className="toolbar">
                            <div className="icon_tool">
                                <ul>
                                    <li className="zoom_out">
                                        <span>
                                            <img
                                                alt=""
                                                src="/images/zooming-magnifying-glass.png"
                                                onClick={handleZoomIn}
                                            />
                                        </span>
                                    </li>
                                    <li className="zoom_in">
                                        <span>
                                            <img
                                                alt=""
                                                src="/images/zoom-out-lens.png"
                                                onClick={handleZoomOut}
                                            />
                                        </span>
                                    </li>
                                    {/* <li className="zoom_in">
                                        <span>
                                            <img
                                                alt=""
                                                src="/images/download2.png"
                                                onClick={onDownload}
                                            />
                                        </span>
                                    </li> */}
                                    {/* <li className="zoom_in">
                                        <span>
                                            <img alt="" src="/images/printing-tool.png" />
                                        </span>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="icon_thumbnail">
                            <ul>
                                {thumbnailData && thumbnailData.length > 0 &&
                                    thumbnailData.map((thumbnail_url, index) => (
                                        <li>
                                            <a
                                                href={"#" + index}
                                                className={"item_thumnail" + index + 1}
                                                onClick={(e) => choseImage(e, "#" + index + 1)}
                                            >
                                                <img alt="" src={thumbnail_url} />
                                                <span>{index + 1}</span>
                                            </a>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                        <div className="main-slider">
                            <div
                                id="body_slider"
                                ref={imgRef}
                                style={imgStyle}
                                className="container"
                            >
                                <div className="slider_content">
                                    <ul>
                                        {thumbnailData && thumbnailData.length > 0 &&
                                            thumbnailData.map((thumbnail_url, index) => (
                                                <li id={index + 1}>
                                                    <img alt="" src={thumbnail_url} />
                                                    <span>
                                                        Page {index + 1}/{thumbnailData.length}
                                                    </span>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default React.memo(PdfViewer);
