import React from "react";
import { useTranslation } from "react-i18next";
import SupportConstants from "../../constants/SupportConstants";

function LayoutSuccess (props) {
  const { t } = useTranslation();
    return (
        <div className="page-content contract_suport">
        <div className="container">
          <div className="main-body-content">
            <div className="contract_suport_title border-bottom mrb-100">
              <h3 className="mt-30">{t("Support.title")}</h3>
            </div>
            <div className="contract_suport_ct">
              <p className="note-form text-center font-24">{t("Support.send_success")}</p>
            </div>
            <div className="call_button">
              <button className="btn green button-submit" onClick={(e) => props.handleChangeStep(e, SupportConstants.Status.Handle, SupportConstants.Status.Success)}>{t("button.btn_back")}</button>
            </div>
          </div>
        </div>
      </div>
    );
}

export default LayoutSuccess