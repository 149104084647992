import React, {useState} from 'react';
import ApiConstant from "../../constants/ApiConstant";
import {api} from "../../services/api";
import {useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ConfirmModal from '../../shared/form/ConfirmModal';
import { toast } from "react-toastify";
import Loading from "../../shared/form/Loading";

function RenderDetailUser(props) {
    const params = useParams();
    const OWNER_GROUP = 1;
    const ADMIN_GROUP = 2;
    const USER_NOMARL = 0;
    const REMOVE_GROUP = "";
    const [showDetailUser, setShowDetailUser] = useState(false);
    const group = props.group
    const index = props.index
    const roleCurrentUser = props.roleCurrentUser
    const role = props.role
    const handleShowDetailUser = () => {
        setShowDetailUser(true);
    }
    const { t } = useTranslation();
    const [userNomarl, setUserNormal] = useState(USER_NOMARL)
    const [adminGroup, setAdminGroup] = useState(ADMIN_GROUP)
    const handleCloseDetailUser = () => setShowDetailUser(false);
    const [loading, setLoading] = useState(false);

    const changeEvent = async (e, group) => {
        const userId = group.user_id
        const userName = group.user_name
        const email = group.email
        const pathDeleteUserInGroup = ApiConstant.Group.User.DeleteRoleInGroup
        if (parseInt(e.target.value) === ADMIN_GROUP) {
            if (window.confirm(t("group.confirm_change_admin",{ attr: userName }))) {
                setLoading(true);
                setUserNormal(e.target.value)
                confirmChangeRole(ADMIN_GROUP, userId)
            }
        }
        if (parseInt(e.target.value) === USER_NOMARL) {
            if (window.confirm(t("group.confirm_change_user", { attr: userName }))) {
                setAdminGroup(e.target.value)
                confirmChangeRole(USER_NOMARL, userId)
            }
        }
        if (e.target.value === "") {
            if (window.confirm(t("group.remove_user", { attr: userName }))) {
                const resultRemoveUser = await api.post(pathDeleteUserInGroup, {
                    group_id: params.groupId,
                    user_id: userId,
                    email: email
                });
                
                if (resultRemoveUser.api_result.result === ApiConstant.Result.Success) {
                    setLoading(false);
                    toast.success(t("group.remove_user_success", { attr:userName }))
                } else {
                    setLoading(false);
                    toast.error(t('group.remove_user_fail', { attr:userName }))
                }
                props.getDataGroup()
                return resultRemoveUser
            }
        } 
    }

    const confirmChangeRole = async (role, userId) => {
        const pathRoleInGroup = ApiConstant.Group.User.UpdateRoleInGroup
        const resultUser = await api.post(pathRoleInGroup, {
            group_id: params.groupId,
            user_id: userId,
            role_in_group: role
        });
        if (resultUser.api_result.result === ApiConstant.Result.Success) {
            setLoading(false);
            toast.success(t("group.update_role_sucess"))
        } else {
            setLoading(false);
            toast.error(t('group.update_role_fail'))
        }
        props.getDataGroup()
        return resultUser
    }

    function renderModalDetailUser () {
        const group = props.group
        return (
            <div className="md-full mt-10">
                <form method="POST" target="hidden_iframe">
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("group.user_id")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={'00'+group.user_id} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("group.user_name")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={group.user_name} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("group.full_name_kana")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={group.full_name_kana} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("group.email")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={group.email} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("group.mobile")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={group.mobile} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("group.address")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={group.address} className="form-control" />
                    </div>
                    </div>
                </form>
            </div>
        )
    }

    const renderDetail = () => {
        if (role === 'admin') {
            if (group.role_in_group === OWNER_GROUP) {
                return (
                    <li key={index} className="color1">
                        <div onClick={handleShowDetailUser}>
                            <img alt="" src="/assets/images/avatar-talk.jpg" className="avatar-group"/>
                            <p className="title">{group.user_name}</p>
                            <p>{group.email}</p>
                        </div>
                    </li>
                )
            } else if (group.role_in_group === ADMIN_GROUP){
                if (roleCurrentUser === OWNER_GROUP) {
                    return (
                        <li key={index}>
                            <img alt="" src="/assets/images/avatar-talk.jpg" className="avatar-group"/>
                            <div  onClick={handleShowDetailUser}>
                                <p className="title">{group.user_name}</p>
                                <p>{group.email}</p>
                            </div>
                            <select onChange={(e) => changeEvent(e,group)} value={adminGroup} name="role_in_group" className="form-control select-group">
                                <option value={ADMIN_GROUP}>{t("group.admin_group")}</option>
                                <option value={USER_NOMARL}>{t("group.member_group")}</option>
                                <option value={REMOVE_GROUP}>{t("group.remove_group")}</option>
                            </select>
                        </li>
                    )
                } else {
                    return (
                        <li key={index}>
                            <div onClick={handleShowDetailUser}>
                                <img alt="" src="/assets/images/avatar-talk.jpg" className="avatar-group"/>
                                <p className="title">{group.user_name}</p>
                                <p>{group.email}</p>
                            </div>
                        </li>
                    )
                }   
            }
        }  
        if (role === 'member' && group.role_in_group === USER_NOMARL) {
            if (roleCurrentUser === OWNER_GROUP || roleCurrentUser === ADMIN_GROUP) {
                return (
                    <li key={index}>
                        <img alt="" src="/assets/images/avatar-talk.jpg" className="avatar-group"/>
                        
                        <div onClick={handleShowDetailUser}>
                            <p className="title">{group.user_name}</p>
                            <p>{group.email}</p>
                        </div>
                        <select onChange={(e) => changeEvent(e, group)} value={userNomarl} name="role_in_group" className="form-control select-group">
                            <option value={ADMIN_GROUP}>{t("group.admin_group")}</option>
                            <option value={USER_NOMARL}>{t("group.member_group")}</option>
                            <option value={REMOVE_GROUP}>{t("group.remove_group")}</option>
                        </select>
                    </li>
                )
            } else {
                return (
                    <li key={index}>
                        <div onClick={handleShowDetailUser}>
                            <img alt="" src="/assets/images/avatar-talk.jpg" className="avatar-group"/>
                            <p className="title">{group.user_name}</p>
                            <p>{group.email}</p>
                        </div>
                    </li>
                )
            }
        }
    }
    return (
        <>
            {renderDetail()}
            <ConfirmModal
                title={t("group.add_member")} 
                showModal={showDetailUser}
                onClose={handleCloseDetailUser}
                body={renderModalDetailUser()}
                cancel={t("group.cancel")}
                className=""
            />
        </>
    );
}

export default RenderDetailUser;
