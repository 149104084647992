import React from "react";
import Input from "../../../shared/form/Input";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RouteConstant from "../../../constants/RouteConstant";

function LayoutResetPassword(props) {
    const { t } = useTranslation();

    return (
        <div className="reset_password login">
            <div className="page-container">
                <div className="login_wrapper register">
                    <div className="form login_form login">
                        <section style={{ paddingTop: "50px" }} className="login_content">
                            <form
                                method="POST"
                                target="hidden_iframe"
                                onSubmit={props.handleSubmitForm}
                            >
                                <h1>{t("forgot_password.txt_title_reset")}</h1>
                                <div className="form-group row mt-30">
                                    <label className="col-sm-4 col-form-label text-right">
                                        {t("forgot_password.txt_password")}
                                        <span className="note-vali">＊</span>
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="item_form">
                                            <Input
                                                onChangeValue={(e) => props.handleChange(e, true)}
                                                name="password"
                                                type="password"
                                                error={props.dataErrors.password}
                                                placeholder="******"
                                            />
                                            <span className="icon icon_pass" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label text-right">
                                        {t("forgot_password.txt_confirm_password")}
                                        <span className="note-vali">＊</span>
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="item_form">
                                            <Input
                                                onChangeValue={(e) => props.handleChange(e, true)}
                                                name="confirm_password"
                                                type="password"
                                                error={props.dataErrors.confirm_password}
                                                placeholder="******"
                                            />
                                            <span className="icon icon_pass" />
                                            {!props.dataErrors.confirm_password ? (
                                                <span className="note_er">
                                                    {props.dataErrors.confirm_password}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-form">
                                    <Link to={RouteConstant.Login.path}>
                                        <button className="btn green button-submit btn-back">
                                            {t("button.btn_cancel")}
                                        </button>
                                    </Link>
                                    <button className="btn green button-submit" type="submit">
                                        {t("button.btn_save")}
                                    </button>
                                </div>
                                <div className="clearfix" />
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LayoutResetPassword;
