import React, { useContext, useEffect } from 'react';
import Context from "../../../contexts/Context";
import axiosAuth from "../../../services/RequestService";
import {useTranslation} from "react-i18next";
import QRCode from 'qrcode.react';
// import loadTrustDock from "../../../services/loadTrustDock.js";

export default function PublishBlock({initPublish, disableForm, action}) {
    const disabledPublish =  {
        is_public: 0,
        contract_url_token: "",
        url_code: "",
        url_qr_base64: "",
        contract_full_url: "",
    };
    const [publish, setPublish] = useContext(Context);
    const { t } = useTranslation();
    const changePublic = () => {
        if(publish.is_public){
            //  call API generate QR and URL
            axiosAuth.get('/generate-contract-link').then(result => {
                publish.contract_url_token = result.data.datas.contract_url_token;
                publish.contract_full_url = result.data.datas.contract_full_url;
                // publish.url_qr_base64 = 'data:image/jpeg;base64, ' + result.data.datas.url_qr_base64;
                setPublish({...publish})
            })
            
        }
        else{
            setPublish(disabledPublish)
        }
    }
    useEffect(() => {
        if(action === "create")
            changePublic();
    }, [])

    // useEffect(() => {

    //     loadTrustDock(() => {
    //         const trustDockPlan = new window.Trustdock({
    //             publicId: '84d79033-c030-4bee-bdee-f7bab823d81e',
    //             openerSelector: '.opener'
    //           });
    //       });
        
    //   return () => {
    //     //  document.body.removeChild(script);
    //     }
    //   }, []);

    const changePublish = (type) => (e) =>  {
        if(type === 'is_public'){
            publish.is_public = !publish.is_public;
            changePublic();
        }            
        else{
            publish[type] = e.target.value; 
            setPublish({...publish})
        }
    }

    const qrPhoto = () => {
        if(publish.url_qr_base64)
            return publish.url_qr_base64;
        else 
            return 'data:image/jpeg;base64, ' + 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII='
    }

    return (
        <div className="content-send-settings">
            <div className="contract_setting_title title-header">
                <h5>{t('Contract.create.pulishingSetting')}</h5>
            </div>
            <div className="contract-list-send-setting">
                <div className="send_settings_wrapper register">
                    <div className="form send_settings">
                        <section className="send_settings_content">
                            <form method="POST" target="hidden_iframe">
                                <div className="form-group row item-company">
                                    <label className="col-sm-2 col-form-label text-left">{t('Contract.create.pulishingSettingPulic')}</label>
                                    <div className="col-sm-8">
                                        <div className="item_form line-35 12 sw-input">
                                            <label className="switch">
                                              <input  disabled={ disableForm}  onChange={changePublish('is_public')} type="checkbox" checked={publish.is_public} data-toggle="toggle" data-size="sm" />
                                              <span className="slider round" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row item-company">
                                    <label className="col-sm-2 col-form-label text-left">URL</label>
                                    <div className="col-sm-8">
                                        <div className="item_form" style={{ wordWrap: 'break-word' }}>
                                            <input type="hidden" value={publish.contract_url_token ?? ''} />
                                            <p>{publish.contract_full_url}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row item-company">
                                    <label className="col-sm-2 col-form-label text-left">{t('Contract.create.pulishingSettingCode')}</label>
                                    <div className="col-sm-8">
                                        <div className="item_form">
                                            <input
                                             disabled={ disableForm || !publish.is_public}
                                            onChange={changePublish('url_code')} 
                                            value={publish.url_code ?? ''}  
                                            type="text" 
                                            // placeholder="123456" 
                                            className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                { (publish.is_public) ?  (
                                <div className="form-group row item-company">
                                    <label className="col-sm-2 col-form-label text-left">{t('Contract.create.pulishingSettingQRCode')}</label>
                                    <div className="col-sm-8">
                                        <div className="item_form">
                                            {/* <img className="creat_qrcode" width="200" src={qrPhoto()} /> */}
                                            {
                                                (publish.contract_full_url) ? 
                                            
                                            <QRCode
                                                className="creat_qrcode"
                                                // width="200"
                                                id='qrcode'
                                                value={publish.contract_full_url}
                                                size={200}
                                                level='M'
                                                includeMargin={false}
                                                style={{'verticalAlign': 'middle'}}
                                            /> : ""
                                            }
                                            <span className="col-orange pdl-20"> {t('Contract.create.pulishingSettingQRCodeSample')}</span>
                                        </div>
                                    </div>
                                </div> 
                                ) : ''}
                                
                               
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}