import ListContract from "../containers/contracts/ListContract";
import Login from "../containers/auth/Login";
import ForgotPassword from "../containers/auth/forgot_password/forgotPassword";
import ResetPassword from "../containers/auth/reset_password/resetPassword";
import Register from "../containers/auth/Register";
import InitContract from "../containers/contracts/InitContract";
import UpdateContract from "../containers/contracts/UpdateContract";
import ContractEvidence from "../containers/contract_evidences/ContractEvidence";
import ProfileUser from "../containers/profile/ProfileUser";
import ProfileUserCompany from "../containers/profile/ProfileUserCompany";
import ListUserInCompany from "../containers/company/ListUserInCompany";
import ListContractCompany from "../containers/company/ListContract";
import ListPaymentCompany from "../containers/company/ListPayment";
import Group from "../containers/group/Group";
import DetailGroup from "../containers/group/DetailGroup";
import SearchGroup from "../containers/group/SearchGroup";
import Setting from "../containers/setting/Setting";
import ConfirmContract from "../containers/contracts/ConfirmContract";
import RegisterSuccess from "../containers/auth/RegisterSuccess";
import Home from "../containers/Home";
import ConfirmContractPublic from "../containers/contracts/ConfirmContractPublic";
import Support from "../containers/support/Support";
import Page404 from "../components/errors/Page404";
import Page403 from "../components/errors/Page403";
import TestTrustDock from "../containers/trustdock/TestTrustDock";

export const RouteConstant = {
    Home: {
        path: "/",
        component: Home,
    },
    Login: {
        path: "/login",
        component: Login,
    },
    ForgotPassword: {
        path: "/forgot-password",
        component: ForgotPassword,
    },
    ResetPassword: {
        path: "/reset-password",
        component: ResetPassword,
    },
    Register: {
        path: "/register",
        component: Register,
    },
    Profile: {
        User: {
            path: "/profile/user",
            component: ProfileUser,
            page: "profile",
        },
        UserCompany: {
            path: "/profile/user/company",
            component: ProfileUserCompany,
            page: "profile",
        },
    },
    Company: {
        ListUser: {
            path: "/company/user",
            component: ListUserInCompany,
            page: "company",
        },
        ListContract: {
            path: "/company/contract",
            component: ListContractCompany,
            page: "company",
        },
        ListPayment: {
            path: "/company/payment",
            component: ListPaymentCompany,
            page: "company",
        },
    },
    Group: {
        List: {
            path: "/group",
            component: Group,
            page: "group",
        },
        Detail: {
            path: "/group/detail/:groupId?",
            component: DetailGroup,
            page: "group",
        },
        Search: {
            path: "/search",
            component: SearchGroup,
            page: "group",
        },
    },
    RegisterSuccess: {
        path: "/register/success/:userId?/:token",
        component: RegisterSuccess,
    },
    Contract: {
        List: {
            path: "/contract/list",
            component: ListContract,
            page: "contract",
        },
        InitContract: {
            path: "/contract/select-template",
            component: InitContract,
            page: "contract",
        },
        CreateContract: {
            path: "/contract/create",
            component: UpdateContract,
            page: "contract",
        },
        DetailContract: {
            path: "/contract/detail/:id",
            component: UpdateContract,
            page: "contract",
        },
        Evidence: {
            path: "/contract/evidence/:contractId",
            component: ContractEvidence,
        },
        Confirm: {
            path: "/contract/confirm/:shareToken",
            component: ConfirmContract,
        },
        Public: {
            path: "/contract/public/:publicKey",
            component: ConfirmContractPublic,
        },
    },
    Setting: {
        path: "/setting",
        component: Setting,
        page: "setting",
    },
    Support: {
        path: "/support",
        component: Support,
    },
    Page: {
        NotFound: {
            path: "/404",
            component: Page404,
        },
        Forbidden: {
            path: "/403",
            component: Page403,
        },
    },
    Test : {
        TrustDock : {
            path : "/test-trustdock",
            component : TestTrustDock
        }
    }
};

export default RouteConstant;
