import React from "react";
import { useTranslation } from "react-i18next";
import Input from "../../../shared/form/Input";
import UserConstant from "../../../constants/UserConstant";
import _ from "lodash";

function ForgotPasswordRole(props) {
    const { t } = useTranslation();

    return (
        <div className="login">
            <div className="page-container">
                <div className="login_wrapper">
                    <div className="form login_form login">
                        <section className="login_content">
                            <form method="POST" onSubmit={props.handleSubmitFormForgotPassword}>
                                <h1>{t("forgot_password.txt_title_forgot_password")}</h1>
                                <div className="login-note">
                                    <p className="text-center">
                                        {t("Login.you_have_some_accounts", {
                                            email: props.email,
                                            number: 2,
                                        })}
                                    </p>
                                    <p className="text-center">{t("forgot_password.choose_account")}</p>
                                </div>
                                <div
                                    data-error-container="#form_2_membership_error"
                                    className="radio-list login_company_step2"
                                >
                                    <label className="text-left">
                                            <Input
                                                name="user_type"
                                                type="radio"
                                                defaultChecked="checked"
                                                onClick={() =>
                                                    props.setDataForgotPassword({
                                                        ...props.dataForgotPassword,
                                                        user_type : UserConstant.UserType.Personal
                                                    })
                                                }
                                            />
                                            {t("Login.login_role_1")}
                                        </label>

                                        <label className="text-left">
                                            <Input
                                                name="user_type"
                                                type="radio"
                                                onClick={() =>
                                                    props.setDataForgotPassword({
                                                        ...props.dataForgotPassword,
                                                        user_type : UserConstant.UserType.Company
                                                    })
                                                }
                                            />
                                            {t("Login.login_role_2")}
                                        </label>
                                        {!_.isEmpty(props.dataForgotPassword.errors) &&
                                        props.dataForgotPassword.errors.map((error, index) => {
                                            return (
                                                <span className="note_er" key={index}>
                                                    {error}
                                                </span>
                                            );
                                        })}
                                </div>
                                <div className="submit-form">
                                    <a href="#" onClick={props.handleBackLayoutEmail} className="btn green button-submit btn-back">{t("button.btn_cancel_2")}</a>
                                    <button className="btn green button-submit" type="submit">
                                        {t("button.btn_next")}
                                    </button>
                                </div>
                                <div className="clearfix" />
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPasswordRole;
