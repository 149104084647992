import React from "react";
import { Link } from "react-router-dom";
import RouteConstant from "../../constants/RouteConstant";
import { useTranslation } from "react-i18next";
import Input from "../../shared/form/Input";

function LoginEmail(props) {
    const { t } = useTranslation();

    return (
        <div className="login">
            <div className="page-container">
                <div className="login_wrapper">
                    <div className="form login_form login">
                        <section className="login_content">
                            <form method="POST" onSubmit={props.handleSubmitEmail}>
                                <h1>{t("Login.title")}</h1>
                                <div className="login-note">
                                    <p className="text-center">{t("Login.description")}</p>
                                </div>
                                <div className="item_form">
                                    <Input
                                        name="email"
                                        type="text"
                                        placeholder={t("Login.email_placeholder")}
                                        value={props.email}
                                        onChangeValue={(e) => props.setEmail(e.target.value)}
                                        className="form-control"
                                        error={props.errors.join(", ")}
                                    />
                                    <span className="icon icon_mail" />
                                </div>
                                <div className="submit-form">
                                    <button className="btn green button-submit" type="submit">
                                        {t("button.btn_next")}
                                    </button>
                                </div>
                                <div className="note-form mt-10">
                                    <p className="text-center">
                                        <Link to={RouteConstant.Register.path}>
                                            {t("Login.click_hear_if_no_account")}
                                        </Link>
                                    </p>
                                </div>
                                <div className="clearfix" />
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginEmail;
