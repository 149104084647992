import React from 'react';
import ApiConstant from "../../constants/ApiConstant";
import {api} from "../../services/api";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

function RenderListGroup(props) {
    const group = props.group
    const { t } = useTranslation();
    const cancelJoinGroup = async (e, group) => {
        if (window.confirm(t("group.confirm_cancel_join_group", { attr: group.group_name }))) {
            let pathDeleteUserInGroup = ApiConstant.Group.User.Cancel
            const cancelJoin = await api.post(pathDeleteUserInGroup, {
                group_id: group.group_id,
            });
            if (cancelJoin.api_result.result === ApiConstant.Result.Success) {
                props.searchDataGroup()
                toast.success(t('group.cancel_join_success', { attr: group.group_name }))
            } else {
                toast.error(t('group.cancel_join_fail', { attr: group.group_name }))
            }
            return cancelJoin
        }
    }
    
    const requestJoinGroup = async (e, group) => {
        if (window.confirm(t("group.request_join_group", { attr: group.group_name }))) {
            let pathRequestUserInGroup = ApiConstant.Group.User.Request
            const confirmJoin = await api.post(pathRequestUserInGroup, {
                group_id: group.group_id,
            });
            if (confirmJoin.api_result.result === ApiConstant.Result.Success) {
                props.searchDataGroup()
                toast.success(t('group.confirm_join_success', { attr: group.group_name }))
            } else {
                toast.error(t('group.confirm_join_fail', { attr: group.group_name }))
            }
            return confirmJoin
        }
    }
    return (
        <>
           {(group && group.not_join_group) ?
            group.not_join_group.map((group, index) => {
                return (
                    <li key={index}>
                        <img alt="" src="/assets/images/avatar-talk.jpg" className="avatar-group"/>
                        <p className="title">{group.group_name}</p>
                        <p className="group"><img alt="" src="/assets/images/user-group.png" className="icon-group" /><span>{group.count_user}</span></p>
                        <button type="button" onClick={(e) => requestJoinGroup(e, group, true)} className="btn btn-primary">{t("group.send_request_join_group")}</button>
                    </li>
                )}
                ):""
            }
            {(group && group.pending_request_group) ?
            group.pending_request_group.map((group, index) => {
                return (
                    <li key={index}>
                        <img alt="" src="/assets/images/avatar-talk.jpg" className="avatar-group"/>
                        <p className="title">{group.group_name}</p>
                        <p className="group"><img alt="" src="/assets/images/user-group.png" className="icon-group" /><span>{group.count_user}</span></p>
                        <button type="button" onClick={(e) => cancelJoinGroup(e, group, true)} className="btn btn-danger cancel">{t("group.cancel_request_join_group")}</button>
                    </li>
               )}
               ):""
            }
            {(group && group.joined_group) ?
            group.joined_group.map((group, index) => {
                return (
                    <li key={index}>
                        <img alt="" src="/assets/images/avatar-talk.jpg" className="avatar-group"/>
                        <p className="title">{group.group_name}</p>
                        <p className="group"><img alt="" src="/assets/images/user-group.png" className="icon-group" /><span>{group.count_user}</span></p>
                    </li>
                )}
                ):""
            }
            {group && group.length==0 ? 
                <li className="sub-item text-center">
                   <span>{t("group.nodata_join")}</span>
                </li> 
                : ""
            }
        </>
    );
}

export default RenderListGroup;
