export const ContractConstant = {
    Status: {
        Handle: 0,
        Complete: 1,
    },
    RequestStatus: {
        NeedToHandle: 0,
        RequestSign: 1,
        CompleteSign: 2,
    },
    Type: {
        SignWhenever: 1,
        SignWhenLogin: 2,
        SignWhenLoginAndVerified: 3,
    },
};

export default ContractConstant;
