import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import RouteConstant from "./constants/RouteConstant";
import AuthRoute from "./middlewares/Auth";
import AuthOrNotAuth from "./middlewares/AuthOrNotAuth";
const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route
                    exact
                    path={RouteConstant.Test.TrustDock.path}
                    component={RouteConstant.Test.TrustDock.component}
                />
                <Route
                    exact
                    path={RouteConstant.Login.path}
                    component={RouteConstant.Login.component}
                />
                <Route
                    exact
                    path={RouteConstant.ForgotPassword.path}
                    component={RouteConstant.ForgotPassword.component}
                />
                <Route
                    exact
                    path={RouteConstant.ResetPassword.path}
                    component={RouteConstant.ResetPassword.component}
                />
                <Route
                    exact
                    path={RouteConstant.Register.path}
                    component={RouteConstant.Register.component}
                />
                <Route
                    exact
                    path={RouteConstant.RegisterSuccess.path}
                    component={RouteConstant.RegisterSuccess.component}
                />

                <AuthOrNotAuth
                    exact
                    path={RouteConstant.Contract.Confirm.path}
                    component={RouteConstant.Contract.Confirm.component}
                />
                <AuthOrNotAuth
                    exact
                    path={RouteConstant.Contract.Public.path}
                    component={RouteConstant.Contract.Public.component}
                />
                <AuthOrNotAuth
                    exact
                    path={RouteConstant.Page.NotFound.path}
                    component={RouteConstant.Page.NotFound.component}
                />
                <AuthOrNotAuth
                    exact
                    path={RouteConstant.Page.Forbidden.path}
                    component={RouteConstant.Page.Forbidden.component}
                />
                <AuthRoute>
                    <Route
                        exact
                        path={RouteConstant.Home.path}
                        component={RouteConstant.Home.component}
                    />
                    <Route
                        exact
                        path={RouteConstant.Home.path}
                        component={RouteConstant.Home.component}
                    />
                    <Route
                        exact
                        path={RouteConstant.Contract.List.path}
                        component={RouteConstant.Contract.List.component}
                    />
                    <Route
                        exact
                        path={RouteConstant.Contract.InitContract.path}
                        component={RouteConstant.Contract.InitContract.component}
                    />
                    <Route
                        exact
                        path={RouteConstant.Contract.CreateContract.path}
                        component={RouteConstant.Contract.CreateContract.component}
                    />
                    <Route
                        exact
                        path={RouteConstant.Contract.DetailContract.path}
                        component={(props) => (
                            <RouteConstant.Contract.DetailContract.component
                                action="detail"
                                props={props}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={RouteConstant.Contract.Evidence.path}
                        component={RouteConstant.Contract.Evidence.component}
                    />
                    <Route
                        exact
                        path={RouteConstant.Profile.User.path}
                        component={RouteConstant.Profile.User.component}
                    />
                    <Route
                        exact
                        path={RouteConstant.Profile.UserCompany.path}
                        component={RouteConstant.Profile.UserCompany.component}
                    />
                    <Route
                        exact
                        path={RouteConstant.Company.ListUser.path}
                        component={RouteConstant.Company.ListUser.component}
                    />
                    <Route
                        exact
                        path={RouteConstant.Company.ListContract.path}
                        component={RouteConstant.Company.ListContract.component}
                    />
                    <Route
                        exact
                        path={RouteConstant.Company.ListPayment.path}
                        component={RouteConstant.Company.ListPayment.component}
                    />
                    <Route
                        exact
                        path={RouteConstant.Group.List.path}
                        component={RouteConstant.Group.List.component}
                    />
                    <Route
                        exact
                        path={RouteConstant.Group.Detail.path}
                        component={RouteConstant.Group.Detail.component}
                    />
                    <Route
                        exact
                        path={RouteConstant.Group.Search.path}
                        component={RouteConstant.Group.Search.component}
                    />
                    <Route
                        exact
                        path={RouteConstant.Setting.path}
                        component={RouteConstant.Setting.component}
                    />
                    <Route
                        exact
                        path={RouteConstant.Support.path}
                        component={RouteConstant.Support.component}
                    />
                </AuthRoute>
            </Switch>
        </BrowserRouter>
    );
};
export default Routes;
