import React, { useState, useEffect } from "react";
import Input from "../../shared/form/Input";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import ApiConstant from "../../constants/ApiConstant";
import { checkEmail } from "../../utility";
import Loading from "../../shared/form/Loading";
import ActiveUser from "./ActiveUser";
import { api } from "../../services/api";
import { Alert } from "react-bootstrap";
import ConditionalRender from "../../helpers/ConditionalRender";
import { Link } from "react-router-dom";
import RouteConstant from "../../constants/RouteConstant";
import { redirectToAppSchemeOrWebPath } from "../../helpers/MobileAppHelper";
import UserConstant from "../../constants/UserConstant";

function Register(props) {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = t("Register.user_register");
    });
    const USER_PERSONAL = UserConstant.UserType.Personal;
    const USER_COMPANY = UserConstant.UserType.Company;

    const [form, setValues] = useState({
        company_name: "",
        company_position: "",
        company_department: "",
        last_name: "",
        first_name: "",
        last_name_in_kana: "",
        first_name_in_kana: "",
        email: "",
        password: "",
        confirmPassword: "",
        user_type: 1,
        callback_query: "",
    });
    // const errorMsg = t("validate.msg_field_required");
    const [errors, setError] = useState([]);
    const [activeUser, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [privacy, setPrivacy] = useState(false);
    const handleChangeValue = (e, required) => {
        setValues({
            ...form,
            [e.target.name]: e.target.value,
        });
        if (required) {
            if (e.target.name === "email") {
                setError({
                    ...errors,
                    email:
                        !e.target.value || !checkEmail(e.target.value)
                            ? t("validate.email.is_email")
                            : "",
                });
            }
            // else {
            //     setError({
            //         ...errors,
            //         [e.target.name]: !e.target.value ? errorMsg : "",
            //     });
            // }
        }
    };

    const handleChangeUserType = (userType) => {
        setValues({
            ...form,
            user_type: userType,
        });
        setError([]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const errors = {
            company_name:
                !form.company_name.trim() && form.user_type === USER_COMPANY
                    ? t("validate.required", { attr: t("Register.company_name") })
                    : "",
            company_position:
                !form.company_position.trim() && form.user_type === USER_COMPANY
                    ? t("validate.required", { attr: t("Register.company_position") })
                    : "",
            company_department:
                !form.company_department.trim() && form.user_type === USER_COMPANY
                    ? t("validate.required", { attr: t("Register.company_department") })
                    : "",
            first_name: !form.first_name.trim()
                ? t("validate.required", { attr: t("Register.first_kanji") })
                : form.first_name.length > 256
                ? t("Register.max_last_kanji")
                : "",
            last_name: !form.last_name.trim()
                ? t("validate.required", { attr: t("Register.last_kanji") })
                : form.last_name.length > 256
                ? t("Register.max_first_kanji")
                : "",
            last_name_in_kana: !form.last_name_in_kana.trim()
                ? t("validate.required", { attr: t("Register.first_kana") })
                : form.last_name_in_kana.length > 256
                ? t("Register.max_first_kana")
                : "",
            first_name_in_kana: !form.first_name_in_kana.trim()
                ? t("validate.required", { attr: t("Register.last_kana") })
                : form.first_name_in_kana.length > 256
                ? t("Register.max_last_kana")
                : "",
            password: !form.password.trim()
                ? t("validate.required", { attr: t("Register.password") })
                : form.password.length > 256
                ? t("Register.max_password")
                : form.password.length < 6
                ? t("Register.min_password")
                : "",
            confirmPassword: !form.confirmPassword.trim()
                ? t("validate.required", { attr: t("Register.confirm_password") })
                : form.confirmPassword.length > 256
                ? t("Register.max_confirm_password")
                : form.confirmPassword.length < 6
                ? t("Register.min_confirm_password")
                : "",
            email: !form.email ? t("validate.required", { attr: t("Register.email") }) : "",
        };

        if (
            _.size(
                _.filter(errors, (error) => {
                    return error;
                }),
            )
        ) {
            setError(errors);
        } else {
            if (form.password !== form.confirmPassword) {
                setError({ confirmPassword: t("validate.msg_confirm_password") });
                return;
            }
            if (!checkEmail(form.email)) {
                setError({ email: t("validate.email.is_email") });
                return;
            }
            if (!privacy) {
                setError({ msg: t("Register.error_privacy") });
                return;
            }
            const REGEX_JA = /^([ァ-ン]|ー)+$/;
            if (!form.last_name_in_kana.match(REGEX_JA)) {
                setError({ msg: t("Register.error_lang_ja") });
                return;
            }
            if (!form.first_name_in_kana.match(REGEX_JA)) {
                setError({ msg: t("Register.error_lang_ja") });
                return;
            }
            form.callback_query = props.location.search;
            setLoading(true);
            const path =
                form.user_type === USER_PERSONAL
                    ? ApiConstant.Auth.RegisterPersonal
                    : ApiConstant.Auth.RegisterCompany;
            const result = await api.post(path, form);
            if (result.api_result.result === ApiConstant.Result.Success) {
                setActive(true);
            } else {
                setError({ msg: result.api_result.message });
            }
            setLoading(false);
        }
    };
    const company = () => {
        return (
            <>
                <div className="form-group row item-company">
                    <label className="col-sm-4 col-form-label text-right">
                        {t("Register.company_name")}
                        <span className="note-vali">＊</span>
                    </label>
                    <div className="col-sm-8">
                        <div className="item_form">
                            <Input
                                onChangeValue={(e) => handleChangeValue(e, true)}
                                name={"company_name"}
                                type={"text"}
                                value={form.company_name}
                                error={errors.company_name}
                            />
                            <span className="icon icon_company" />
                        </div>
                    </div>
                </div>
                <div className="form-group row item-company">
                    <label className="col-sm-4 col-form-label text-right">
                        {t("Register.company_position")}
                        <span className="note-vali">＊</span>
                    </label>
                    <div className="col-sm-8">
                        <div className="item_form">
                            <Input
                                onChangeValue={(e) => handleChangeValue(e, true)}
                                name={"company_position"}
                                type={"text"}
                                value={form.company_position}
                                error={errors.company_position}
                            />
                            <span className="icon icon_company" />
                        </div>
                    </div>
                </div>
                <div className="form-group row item-company">
                    <label className="col-sm-4 col-form-label text-right">
                        {t("Register.company_department")}
                        <span className="note-vali">＊</span>
                    </label>
                    <div className="col-sm-8">
                        <div className="item_form">
                            <Input
                                onChangeValue={(e) => handleChangeValue(e, true)}
                                name={"company_department"}
                                type={"text"}
                                value={form.company_department}
                                error={errors.company_department}
                            />
                            <span className="icon icon_title" />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const handleBackToLogin = () => {
        const queryParams = new URLSearchParams(props.location.search);
        redirectToAppSchemeOrWebPath(queryParams, RouteConstant.Login.path);
    };
    if (activeUser)
        return (
            <ActiveUser
                handleBackToLogin={handleBackToLogin}
                email={form.email}
                userType={form.user_type}
                queryString={props.location.search}
            />
        );
    return (
        <div className="login">
            {loading ? <Loading /> : null}
            <div className="page-container">
                <div className="login_wrapper register">
                    <div className="form login_form login">
                        <section className="login_content">
                            <form method="POST" onSubmit={onSubmit}>
                                <h1>{t("Register.user_register")}</h1>
                                <div className="login-note mt-30">
                                    <div
                                        data-error-container="#form_2_membership_error"
                                        className="radio-list"
                                    >
                                        <label className="text-left">
                                            <input
                                                type="radio"
                                                name="userType"
                                                defaultValue={USER_PERSONAL}
                                                defaultChecked={
                                                    form.user_type === USER_PERSONAL ? true : false
                                                }
                                                onClick={(e) => handleChangeUserType(USER_PERSONAL)}
                                            />
                                            {t("Register.register_personal")}
                                        </label>
                                        <label className="text-left">
                                            <input
                                                type="radio"
                                                name="userType"
                                                defaultValue={USER_COMPANY}
                                                defaultChecked={
                                                    form.user_type === USER_COMPANY ? true : false
                                                }
                                                onClick={(e) => handleChangeUserType(USER_COMPANY)}
                                            />
                                            {t("Register.register_company")}
                                        </label>
                                    </div>
                                </div>
                                {form.user_type === USER_COMPANY ? company() : null}
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label text-right">
                                        {t("Register.name_kanji")}
                                        <span className="note-vali">＊</span>
                                    </label>
                                    <div className="col-sm-4">
                                        <div className="item_form">
                                            <Input
                                                onChangeValue={(e) => handleChangeValue(e, true)}
                                                name={"last_name"}
                                                type={"text"}
                                                value={form.last_name}
                                                placeholder={t("Register.surname")}
                                                error={errors.last_name}
                                            />
                                            <span className="icon icon_user" />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="item_form">
                                            <Input
                                                onChangeValue={(e) => handleChangeValue(e, true)}
                                                name={"first_name"}
                                                type={"text"}
                                                value={form.first_name}
                                                placeholder={t("Register.name")}
                                                error={errors.first_name}
                                            />
                                            <span className="icon icon_user" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label text-right">
                                        {t("Register.name_kana")}
                                        <span className="note-vali">＊</span>
                                    </label>
                                    <div className="col-sm-4">
                                        <div className="item_form">
                                            <Input
                                                onChangeValue={(e) => handleChangeValue(e, true)}
                                                name={"last_name_in_kana"}
                                                type={"text"}
                                                value={form.last_name_in_kana}
                                                placeholder="セイ"
                                                error={errors.last_name_in_kana}
                                            />
                                            <span className="icon icon_user" />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="item_form">
                                            <Input
                                                onChangeValue={(e) => handleChangeValue(e, true)}
                                                name={"first_name_in_kana"}
                                                type={"text"}
                                                value={form.first_name_in_kana}
                                                placeholder="メイ"
                                                error={errors.first_name_in_kana}
                                            />
                                            <span className="icon icon_user" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label text-right">
                                        {t("Register.email")}
                                        <span className="note-vali">＊</span>
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="item_form">
                                            <Input
                                                onChangeValue={(e) => handleChangeValue(e, true)}
                                                name={"email"}
                                                type={"text"}
                                                value={form.email}
                                                placeholder="sample@gmail.com"
                                                error={errors.email}
                                            />
                                            <span className="icon icon_mail" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label text-right">
                                        {t("Register.password")}
                                        <span className="note-vali">＊</span>
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="item_form">
                                            <Input
                                                onChangeValue={(e) => handleChangeValue(e, true)}
                                                name={"password"}
                                                type={"password"}
                                                value={form.password}
                                                placeholder="******"
                                                error={errors.password}
                                            />
                                            <span className="icon icon_pass" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label text-right">
                                        {t("Register.confirm_password")}
                                        <span className="note-vali">＊</span>
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="item_form">
                                            <Input
                                                onChangeValue={(e) => handleChangeValue(e, true)}
                                                name={"confirmPassword"}
                                                type={"password"}
                                                value={form.confirmPassword}
                                                placeholder="******"
                                                error={errors.confirmPassword}
                                            />
                                            <span className="icon icon_pass" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label text-right"></label>
                                    <div className="col-sm-8">
                                        <ConditionalRender condition={errors.msg !== undefined}>
                                            <Alert variant="danger">{errors.msg}</Alert>
                                        </ConditionalRender>
                                    </div>
                                </div>

                                <div className="form-group row mt-30">
                                    <div className="col-sm-4" />
                                    <div className="col-sm-8">
                                        <div className="if_check">
                                            <input
                                                type="checkbox"
                                                name="membership"
                                                defaultChecked={privacy ? true : false}
                                                onClick={(e) => setPrivacy(!privacy)}
                                            />
                                            <Link to="">{t("Register.terms_of_service")}</Link>
                                            <span> {t("Register.and")}</span>
                                            <Link to="">{t("Register.privacy_policy")}</Link>
                                            <span>{t("Register.i_agree_with")}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-form">
                                    <button
                                        type="button"
                                        className="btn green button-submit btn-back"
                                        onClick={handleBackToLogin}
                                    >
                                        {t("Register.cancel")}
                                    </button>
                                    <button className="btn green button-submit" type="submit">
                                        {t("Register.sign_up")}
                                    </button>
                                </div>
                                <div className="clearfix" />
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
