import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ApiConstant from "../../constants/ApiConstant";
import Loading from "../../shared/form/Loading";
import { api } from "../../services/api";

function ActiveUser(props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const sendEmail = async () => {
        setLoading(true);
        const result = await api.post(ApiConstant.Auth.ResendEmail, {
            email: props.email,
            user_type: props.userType,
            callback_query: props.queryString,
        });
        if (result.api_result.result === ApiConstant.Result.Success) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    };

    return (
        <div className="login">
            {loading ? <Loading /> : null}
            <div className="page-container">
                <div className="login_wrapper register register_active">
                    <div className="form login_form login">
                        <section className="login_content">
                            <form method="POST" target="hidden_iframe">
                                <h1>{t("Register.user_active")}</h1>
                                <div className="login-note">
                                    <p className="text-center">
                                        {props.email}{" "}
                                        {t(
                                            "Register.an_activation_email_has_been_sent_to_your_email_address",
                                        )}
                                    </p>
                                    <p className="text-center">{t("Register.pls_check_email")}</p>
                                </div>
                                <div className="submit-form">
                                    <button
                                        type="button"
                                        className="btn green button-submit btn-back"
                                        onClick={sendEmail}
                                        style={{ width: 280 }}
                                    >
                                        {t("Register.resend_email")}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn green button-submit"
                                        onClick={props.handleBackToLogin}
                                        style={{ width: 280 }}
                                    >
                                        {t("Register.redirect_login")}
                                    </button>
                                </div>
                                <div className="clearfix" />
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(ActiveUser);
