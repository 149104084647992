import React from "react";
import ClassNames from "classnames";
import "./Common.css";

function Input(props) {
    const {
        type,
        name,
        placeholder,
        value,
        error,
        hidden,
        defaultChecked,
        disabled,
        disableClassFormGroup,
        id,
        errorNoText,
    } = props;

    const onChangeValue = (e) => {
        if (props.onChangeValue) props.onChangeValue(e);
    };

    const onClick = (e) => {
        if (props.onClick) props.onClick(e);
    };
    const onKeyDown = (e) => {
        if (props.onKeyDown) props.onKeyDown(e);
    };

    return (
        <div
            className={ClassNames(
                { "form-group": !disableClassFormGroup },
                { "has-error": error || errorNoText },
            )}
        >
            <input
                id={id}
                type={type}
                className="form-control"
                value={value}
                name={name}
                defaultChecked={defaultChecked}
                placeholder={placeholder}
                onChange={onChangeValue}
                onClick={onClick}
                onKeyDown={onKeyDown}
                disabled={disabled}
            />
            <span className={ClassNames("error", { hidden: !error })}>{error}</span>
        </div>
    );
}

export default React.memo(Input);
