import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import RouteConstant from "../constants/RouteConstant";

function Login() {
    const history = useHistory();
    useEffect(()=>{
        history.push(RouteConstant.Contract.List.path);
    })
    
    return <></>;
}
export default Login;
