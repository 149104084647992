import { logout } from './AuthService';

const apiUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_PRO : process.env.REACT_APP_API_DEV;
const axios = require('axios');
const axiosAuth = axios.create({
    baseURL: apiUrl,
});
const axiosGuest = axios.create({
    baseURL: apiUrl,
});

// Request interceptor for API calls
axiosAuth.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('user_verify_token');
        config.headers = {
            duid: `${token}`,
            /*
            Accept: 'application/json',
            'Content-Type': 'application/json',
            */
        };
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

// Request interceptor for API calls
axiosGuest.interceptors.request.use(
    async (config) => {
        config.headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

export { axiosAuth, axiosGuest };
export default axiosAuth;

// // Response interceptor for API calls
axiosAuth.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        if(!error.response){
            return false;
            window.location.reload(true);
        }
            
        if (error.response.status === 401) {
            logout();
            window.location.reload(true);
        }
        return Promise.reject(error);
    },
);

// // Response interceptor for API calls
axiosGuest.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        console.log(error.response)
        if (error.response.status === 401) {
            logout();
            window.location.reload(true);
        }
        return Promise.reject(error);
    },
);
