import React, { useState, useEffect } from "react";
import ApiConstant from "../../constants/ApiConstant";
import { useTranslation } from "react-i18next";
import _, { trim } from "lodash";
import Input from "../../shared/form/Input";
import { api } from "../../services/api";
import { checkEmail } from "../../utility";
import Loading from "../../shared/form/Loading";
import { toast } from "react-toastify";
import RouteConstant from "../../constants/RouteConstant";
import { useHistory } from "react-router-dom";
import ClassNames from "classnames";
import axiosAuth from "../../services/RequestService";
import ProfileSignature from "./ProfileSignature";
import VerifyMobilePhone from "./VerifyMobilePhone";

export default function UserProfileCompany() {
    const [form, setValues] = useState({
        company_name: "",
        address: "",
        first_name: "",
        last_name: "",
        first_name_in_kana: "",
        last_name_in_kana: "",
        profile_avatar: "",
        email: "",
        department: "",
        position: "",
        signature: "",
    });

    const [errors, setError] = useState([]);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const errorMsg = t("profile.required");
    const handleChangeValue = (e, required) => {
        if (required) {
            if (e.target.name === "email") {
                setError({
                    ...errors,
                    email: !e.target.value || !checkEmail(e.target.value) ? errorMsg : "",
                });
            } else {
                setError({
                    ...errors,
                    [e.target.name]: !e.target.value ? errorMsg : "",
                });
            }
        }
        setValues({
            ...form,
            [e.target.name]: e.target.value,
        });
    };
    const getDataUser = async (e) => {
        setLoading(true);
        const path = ApiConstant.Profile.DetailUserCompany;
        const result = await api.get(path);
        if (result.api_result.result === ApiConstant.Result.Success) {
            setLoading(false);
            const resData = result.datas;
            setValues({
                user_id: resData.user_id,
                company_id: resData.company.length > 0 ? resData.company[0].company_id : "",
                company_name: resData.company.length > 0 ? resData.company[0].company_name : "",
                address: resData.company.length > 0 ? resData.company[0].address : "",
                profile_avatar:
                    resData.profile_avatar !== ""
                        ? resData.profile_avatar
                        : "/images/avatar_men.png",
                first_name: resData.first_name,
                last_name: resData.last_name,
                first_name_in_kana: resData.first_name_in_kana,
                last_name_in_kana: resData.last_name_in_kana,
                email: resData.email,
                department: resData.department,
                position: resData.position,
                signature: resData.signature,
                mobile: resData.mobile,
                is_verified_mobile_number: resData.is_verified_mobile_number,
            });
            console.log(form);
        } else {
            setError({ msg: result.api_result.message });
        }
    };
    const history = useHistory();
    useEffect(() => {
        document.title = t("profile.title");
        // check nếu là user thường thì return ra /login
        const userType = localStorage.getItem("user_type");
        if (parseInt(userType) === 1) {
            return history.push(RouteConstant.Login.path);
        } else {
            getDataUser();
        }
    }, [t]);

    const handleChangeAvatar = (e) => {
        e.preventDefault();
        const file = e.target.files[0];

        const formData = new FormData();
        formData.append("avatar", file);
        if (file.type === "image/jpeg" || file.type === "image/png") {
            axiosAuth
                .post(ApiConstant.Profile.Avatar, formData)
                .then((response) => {
                    setValues({
                        ...form,
                        profile_avatar: response.data.datas.file_url,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setError({
                ...errors,
                profile_avatar: t("profile.type_image"),
            });
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const errors = {
            company_name: !trim(form.company_name) ? t("profile.company") + errorMsg : "",
            first_name: !trim(form.first_name) ? t("profile.first_name") + errorMsg : "",
            last_name: !trim(form.last_name) ? t("profile.last_name") + errorMsg : "",
            first_name_in_kana: !trim(form.first_name_in_kana)
                ? t("profile.first_name_kana") + errorMsg
                : "",
            last_name_in_kana: !trim(form.last_name_in_kana)
                ? t("profile.last_name_kana") + errorMsg
                : "",
            email: !trim(form.email) ? t("profile.email") + errorMsg : "",
            department: !trim(form.department) ? t("profile.department") + errorMsg : "",
            position: !trim(form.position) ? t("profile.position") + errorMsg : "",
        };
        if (
            _.size(
                _.filter(errors, (error) => {
                    return error;
                }),
            )
        ) {
            setLoading(false);
            setError(errors);
        } else {
            const path = ApiConstant.Profile.UpdateUserCompany;
            const result = await api.post(path, form);
            if (result.api_result.result === ApiConstant.Result.Success) {
                setLoading(false);
                localStorage.setItem("profile_avatar", form.profile_avatar);
                toast.success(t("profile.update_sucess"));
            } else {
                setLoading(false);
                toast.error(t("profile.update_fail"));
                setError({ msg: result.api_result.message });
            }
        }
    };

    const formProfileUser = () => {
        return (
            <>
                <Input type="hidden" name="company_id" value={form.company_id} />
                <Input type="hidden" name="user_id" value={form.user_id} />
                <div className="form-group row item-company">
                    <label className="col-sm-3 col-form-label text-right">
                        {t("profile.user_company")}
                        <span className="note-vali">＊</span>
                    </label>
                    <div className="col-sm-6">
                        <div className="item_form">
                            <Input
                                onChangeValue={(e) => handleChangeValue(e, true)}
                                name="company_name"
                                type="text"
                                value={form.company_name}
                                placeholder={t("profile.place_holder_company")}
                                error={errors.company_name}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <label className="col-sm-3">
                        <div className="avartar-upload">
                            <div className="img-avarta">
                                <img src={form.profile_avatar} alt="thumbnail" />
                                <input
                                    type="file"
                                    onChange={(e) => handleChangeAvatar(e, false)}
                                    className="avatar_user camera_upload"
                                    name="avatar"
                                />
                                <button>
                                    <img src="/images/camera.png" alt="thumbnail" />
                                </button>
                                <span
                                    className={ClassNames("note_er", {
                                        hidden: !errors.profile_avatar,
                                    })}
                                >
                                    {errors.profile_avatar}
                                </span>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="form-group row item-company">
                    <label className="col-sm-3 col-form-label text-right">
                        {t("profile.address_user_company")}
                    </label>
                    <div className="col-sm-6">
                        <div className="item_form">
                            <Input
                                onChangeValue={(e) => handleChangeValue(e, true)}
                                name="address"
                                type="text"
                                value={form.address}
                                placeholder={t("profile.place_holder_address")}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group row item-company">
                    <Input name="user_id" type="hidden" value={form.user_id} />
                    <label className="col-sm-3 col-form-label text-right">
                        {t("profile.full_name")}
                        <span className="note-vali">＊</span>
                    </label>
                    <div className="col-sm-3">
                        <div className="item_form">
                            <Input
                                onChangeValue={(e) => handleChangeValue(e, true)}
                                name="last_name"
                                type="text"
                                value={form.last_name}
                                placeholder={t("profile.place_holder_last_name")}
                                error={errors.last_name}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="item_form">
                            <Input
                                onChangeValue={(e) => handleChangeValue(e, true)}
                                name="first_name"
                                type="text"
                                value={form.first_name}
                                placeholder={t("profile.place_holder_first_name")}
                                error={errors.first_name}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group row item-company">
                    <label className="col-sm-3 col-form-label text-right">
                        {t("profile.full_name_kana")}
                        <span className="note-vali">＊</span>
                    </label>
                    <div className="col-sm-3">
                        <div className="item_form">
                            <Input
                                onChangeValue={(e) => handleChangeValue(e, true)}
                                name="last_name_in_kana"
                                type="text"
                                value={form.last_name_in_kana}
                                placeholder={t("profile.place_holder_last_name_kana")}
                                error={errors.last_name_in_kana}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="item_form">
                            <Input
                                onChangeValue={(e) => handleChangeValue(e, true)}
                                name="first_name_in_kana"
                                type="text"
                                value={form.first_name_in_kana}
                                placeholder={t("profile.place_holder_first_name_kana")}
                                error={errors.first_name_in_kana}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group row item-company">
                    <label className="col-sm-3 col-form-label text-right">
                        {t("profile.email")}
                        <span className="note-vali">＊</span>
                    </label>
                    <div className="col-sm-6">
                        <div className="item_form">
                            <Input
                                onChangeValue={(e) => handleChangeValue(e, true)}
                                name="email"
                                type="text"
                                value={form.email}
                                placeholder={t("profile.place_holder_email")}
                                error={errors.email}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>

                <VerifyMobilePhone
                    setForm={setValues}
                    setLoading={setLoading}
                    handleChangeValue={handleChangeValue}
                    form={form}
                    formErrors={errors}
                    setFormErrors={setError}
                />

                <div className="form-group row item-company">
                    <label className="col-sm-3 col-form-label text-right">
                        {t("profile.position")}
                        <span className="note-vali">＊</span>
                    </label>
                    <div className="col-sm-6">
                        <div className="item_form">
                            <Input
                                onChangeValue={(e) => handleChangeValue(e, true)}
                                name="position"
                                type="text"
                                value={form.position}
                                placeholder={t("profile.place_holder_position")}
                                error={errors.position}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group row item-company">
                    <label className="col-sm-3 col-form-label text-right">
                        {t("profile.department")}
                        <span className="note-vali">＊</span>
                    </label>
                    <div className="col-sm-6">
                        <div className="item_form">
                            <Input
                                onChangeValue={(e) => handleChangeValue(e, true)}
                                name="department"
                                type="text"
                                value={form.department}
                                placeholder={t("profile.place_holder_department")}
                                error={errors.department}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group row item-company">
                    <label className="col-sm-3 col-form-label text-right">
                        {t("profile.signature")}
                    </label>
                    <div className="col-sm-9">
                        <ProfileSignature
                            setLoading={(status) => setLoading(status)}
                            dataURL={form.signature}
                            setDataURL={(url) => setValues({ ...form, signature: url })}
                        />
                    </div>
                </div>

                <div className="submit-form">
                    <button className="btn blue button-submit float-right">
                        {t("profile.save")}
                    </button>
                </div>
            </>
        );
    };
    return (
        <div>
            <div className="clearfix" />
            <div className="page-container">
                <div className="page-content-wrapper">
                    <div className="page-content contract_creat">
                        <div className="container">
                            <div className="main-body-content">
                                <div className="form login_form login_user_profile">
                                    <section className="user_profile_content">
                                        <form method="POST" onSubmit={onSubmit}>
                                            {!_.isEmpty(form) ? formProfileUser() : null}
                                        </form>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <Loading /> : ""}
        </div>
    );
}
