const loadTrustDock = (callback) => {
    const existingScript = document.getElementById('trustDock');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://helper.test.trustdock.io/v2/verification_helper.js';
      script.id = 'trustDock';
      document.body.appendChild(script);
      script.onload = () => { 
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };
  export default loadTrustDock;