import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import ApiConstant from "../constants/ApiConstant";
import RouteConstant from "../constants/RouteConstant";
import { api } from "../services/api";
import { login } from "../services/AuthService";
import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import { isMobileByQueryParams } from "../helpers/MobileAppHelper";

function AuthOrNotAuth({ children, ...rest }) {
    const params = new URLSearchParams(rest.location.search);
    const token = params.get("_token");
    const [loading, setLoading] = useState(token);

    useEffect(() => {
        if (token) {
            const fetchData = async () => {
                const result = await api.post(ApiConstant.Auth.VerifyToken, { duid: token });
                if (result.api_result.result === ApiConstant.Result.Success) {
                    setLoading(null);
                    login(result.datas);
                } else {
                    setLoading(null);
                    window.location.href = RouteConstant.Login.path;
                }
            };
            fetchData();
        }
    }, [token]);
    if (loading) return <></>;

    return (
        <>
            {isMobileByQueryParams(params) ? null : <Header />}
            <Route {...rest} render={() => children} />
            {isMobileByQueryParams(params) ? null : <Footer />}
        </>
    );
}

export default AuthOrNotAuth;
