import React, { useEffect, useState } from "react";
import axiosAuth from "../../services/RequestService";
import ApiConstant from "../../constants/ApiConstant";
import {
    Link,
    useParams
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pagination from "rc-pagination";
import moment from "moment-timezone";
import RouteConstant from "../../constants/RouteConstant";

export default function ContractEvidence(props) {
    let { contractId } = useParams();
    const { t } = useTranslation();
    const [contract, setContract] = useState({
        datas: {
            contract_title: "",
            created_at: "",
            created_by_user_name: "",
            status: "",
            updated_at: "",
            status_text: "",
            status_last_evidence: ""
        },
    });
    const [contractEvidences, setContractEvidences] = useState({
        datas: {
            data: [],
        },
        current_page: 0,
        per_page: 0,
        from: 1,
        to: 1,
        total: 0,
    });
    const [formData, setFormData] = useState({
        contract_id: contractId,
    });

    const getListContract = (formData) => {
        axiosAuth
            .post(ApiConstant.Contract.Evidence, formData)
            .then((response) => {
                setContractEvidences(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getContractDetail = (contractId) => {
        axiosAuth
            .get(ApiConstant.Contract.Detail.replace(":contractId", contractId))
            .then((response) => {
                setContract(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getListContract(formData);
        getContractDetail(contractId);
    }, [contractId, formData]);

    const handlePaginate = (page) => {
        setFormData((prevState) => ({
            ...prevState,
            contract_id: contractId,
            page: page,
        }));
    };

    const formatDateEvidence = (date) => {
        return moment(date).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm');
    }

    const formatDateContract = (date) => {
        return moment(date).tz('Asia/Tokyo').format('YYYY/MM/DD | HH:mm');
    }

    const downloadPDF = (contract_id) => {
        const url = ApiConstant.Contract.EvidenceDownloadPdf.replace(':contract_id', contract_id);
        axiosAuth({
            url: url,
            method: 'GET',
        }).then((response) => {
            const fileName = 'contract-evidence-'+ moment(new Date()).format('YYYY-MM-DD')+'.pdf';
            const link = document.createElement('a');
            link.href = response.data;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
    }

    return (
        <>
            <div className="page-container">
                <div className="page-content-wrapper">
                    <div className="page-content list_contract kri_contract_license">
                        <div className="container">
                            <div className="main-body-content member-page">
                                <div className="contract_content">
                                    <div className="contract_content_license_header">
                                        <form method="POST" target="hidden_iframe">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group row item-company">
                                                        <label className="col-sm-12 col-form-label bold">{t('Contract.contract_title')}</label>
                                                        <div className="item_form col-sm-8">
                                                            <input
                                                                type="text"
                                                                disabled
                                                                className="form-control"
                                                                value={
                                                                    contract.datas.contract_title
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row item-company">
                                                        <label className="col-sm-12 col-form-label bold">{t('Contract.send_at')}</label>
                                                        <div className="item_form col-sm-8">
                                                            <input type="text"
                                                                   className="form-control" disabled value={contract.datas.created_at ? formatDateContract(contract.datas.created_at) : ""}/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row item-company">
                                                        <label className="col-sm-12 col-form-label bold">{t('Contract.updated_at')}</label>
                                                        <div className="item_form col-sm-8">
                                                            <input type="text" disabled
                                                                   className="form-control" value={contract.datas.updated_at ? formatDateContract(contract.datas.updated_at) : ""}/>
                                                        </div>
                                                    </div>
                                                    {/*<div className="form-group row item-company">*/}
                                                    {/*    <label className="col-sm-12 col-form-label bold">{t('Contract.created_at')}</label>*/}
                                                    {/*    <div className="item_form col-sm-8">*/}
                                                    {/*        <input type="text"*/}
                                                    {/*               disabled*/}
                                                    {/*               className="form-control" value={contract.datas.created_at ? formatDateContract(contract.datas.created_at) : ""}/>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group row item-company">
                                                        <label className="col-sm-12 col-form-label bold">{t('Contract.created_by_user_name')}</label>
                                                        <div className="item_form col-sm-8">
                                                            <input
                                                                type="text"
                                                                disabled
                                                                className="form-control"
                                                                value={
                                                                    contract.datas
                                                                        .created_by_user_name
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row item-company">
                                                        <label className="col-sm-12 col-form-label bold">{t('Contract.status')}</label>
                                                        <div className="item_form col-sm-8">
                                                            <input
                                                                type="text"
                                                                disabled
                                                                className="form-control"
                                                                value={contract.datas.status_last_evidence}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="contract_content_main">
                                        <div className="list-title-table-button">
                                            <h3 className="title-header-table">{t('ContractEvidence.history')}</h3>
                                            <div className="botton-download">
                                                <button onClick={event => downloadPDF(contract.datas.contract_id)} className="btn green button-submit btn-back">{t('ContractEvidence.download_pdf')}</button>
                                            </div>
                                        </div>
                                        <ul className="list-content list-history">
                                            <li className="item item-title">
                                                <ul className="item-content">
                                                    <li className="sub-item">{t('ContractEvidence.created_at')}</li>
                                                    <li className="sub-item">{t('ContractEvidence.created_by_user_name')}</li>
                                                    {/* <li className="sub-item">{t('ContractEvidence.action')}</li> */}
                                                    <li className="sub-item">{t('ContractEvidence.content')}</li>
                                                    <li className="sub-item">{t('ContractEvidence.status')}</li>
                                                    {/*<li className="sub-item">ステータス</li>*/}
                                                </ul>
                                            </li>
                                            {contractEvidences.datas.data.length ? contractEvidences.datas.data.map((contract_evidence, index) => {
                                                return (
                                                    <li key={index} className="item">
                                                        <ul className="item-content">
                                                            <li className="sub-item">{formatDateEvidence(contract_evidence.created_at)}</li>
                                                            <li className="sub-item"><span>{contract_evidence.created_by_user_name}</span></li>
                                                            <li className="sub-item"><span>{contract_evidence.content}</span></li>
                                                            <li className="sub-item"><span>{contract_evidence.action}</span></li>
                                                            {/*<li className="sub-item">{contract_evidence.status}</li>*/}
                                                        </ul>
                                                    </li>
                                                )
                                            }) : <li className="sub-item text-center"><span>{t('nodata')}</span></li>}
                                        </ul>
                                    </div>
                                </div>

                                <div style={{display: "flex",
                                    justifyContent: "space-between"}}>
                                    {contractEvidences.datas.data.length ? (
                                        <Pagination
                                            className="align-items-center"
                                            onChange={handlePaginate}
                                            current={contractEvidences.datas.current_page}
                                            total={contractEvidences.datas.total}
                                            pageSize={contractEvidences.datas.per_page}
                                        />
                                    ) : (
                                        ""
                                    )}
                                    <Link style={{marginTop: '20px'}} to={RouteConstant.Contract.List.path} className="btn btn-default" href="">Back</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
