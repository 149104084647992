import React from "react";
import Input from "../../shared/form/Input";
import { useTranslation } from "react-i18next";

export default function ConfirmContractPublicCode(props) {
    const { t } = useTranslation();

    return (
        <div className="page-content contract_code">
            <div className="container">
                <div className="main-body-content">
                    <div className="contract_suport_ct mrt-100">
                        <h3 className="note-form text-center">{t("Contract.input_pass_code")}</h3>
                    </div>
                    <form>
                        <div className="form-group">
                            <Input
                                type="text"
                                onChangeValue={props.handleChangePassCode}
                                error={props.error}
                            />
                        </div>
                    </form>

                    <div className="call_button">
                        <button
                            className="btn green button-submit mrt-30"
                            type="button"
                            onClick={props.handleSubmitPassCode}
                        >
                            {t("button.btn_next")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
