import React from "react";
import { useTranslation } from "react-i18next";
import RouteConstant from "../../constants/RouteConstant";
import { redirectToAppSchemeOrWebPath } from "../../helpers/MobileAppHelper";

export default function ContractSuccess(props) {
    const { t } = useTranslation();

    const handleBackToListContract = () => {
        redirectToAppSchemeOrWebPath(props.qParams, RouteConstant.Contract.List.path);
    };

    return (
        <div className="page-content contract_suport">
            <div className="container">
                <div className="main-body-content">
                    <div className="contract_suport_ct mrt-100">
                        <h3 className="note-form text-center">{props.title}</h3>
                    </div>
                    <div className="call_button">
                        <button
                            className="btn green button-submit mrt-30"
                            onClick={handleBackToListContract}
                        >
                            {t("button.btn_back_to_contract_list")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
