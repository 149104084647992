/* eslint-disable no-unused-vars */
import { Modal, Button } from "react-bootstrap";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import ContractAttributes from "../../components/contract/update/ContractAttributes";
import AssigmentsBlock from "../../components/contract/update/AssigmentsBlock";
import PublishBlock from "../../components/contract/update/PublishBlock";
import PdfViewer from "../../components/contract/update/PdfViewer";
import axiosAuth from "../../services/RequestService";
import ApiConstant from "../../constants/ApiConstant";
import Context from "../../contexts/Context";
import { useTranslation } from "react-i18next";
import Loading from "../../shared/form/Loading";
import _ from "lodash";
import ContractSuccess from "./ContractSuccess";
import ContractConstant from "../../constants/ContractConstant";
import ContractAssignmentConstant from "../../constants/ContractAssignmentConstant";
import { checkEmail } from "../../utility";
import { toast } from "react-toastify";
import { redirectToAppSchemeOrWebPath } from "../../helpers/MobileAppHelper";
import RouteConstant from "../../constants/RouteConstant";

let initValidate = false;

function UpdateContract(props) {
    // init
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    let action = "create";
    let disableForm = false;
    const params = useParams();
    const queryParams = new URLSearchParams(
        props.action === "detail" ? props.props.location.search : props.location.search,
    );
    // validate form
    const errorInputClass = "is-invalided";

    const initContract = {
        contract_template_id: null,
        contract_type: 2,
        contract_title: "",
        contract_content: "",
        message: "",
        subject: "",
        status: 0,
        is_public: 1,
        contract_code: "",
        contract_url_token: "",
        contract_full_url: "",
        url_code: "",
        url_qr_base64: "",
    };
    const arrTypes = [
        {
            index: ContractConstant.Type.SignWhenever,
            placeholder: t("Contract.create.typeSettingNoLogin"),
        },
        {
            index: ContractConstant.Type.SignWhenLogin,
            placeholder: t("Contract.create.typeSettingNeedLogin"),
        },
        {
            index: ContractConstant.Type.SignWhenLoginAndVerified,
            placeholder: t("Contract.create.typeSettingNeedVerify"),
        },
    ];

    const initAttributes = [
        { key: "署名", type: ContractAssignmentConstant.AttributeType.Signature },
        { key: "氏名", type: ContractAssignmentConstant.AttributeType.Text },
        { key: "会社名", type: ContractAssignmentConstant.AttributeType.Text },
        { key: "金額", type: ContractAssignmentConstant.AttributeType.Text },
        { key: "メールアドレス", type: ContractAssignmentConstant.AttributeType.Text },
        { key: "日付", type: ContractAssignmentConstant.AttributeType.Text },
        { key: "役職", type: ContractAssignmentConstant.AttributeType.Text },
        { key: "テキスト", type: ContractAssignmentConstant.AttributeType.Text },
    ];
    const initPublish = {
        is_public: 1,
        contract_url_token: "",
        url_code: "",
        url_qr_base64: "",
        contract_full_url: "",
    };

    const initAssignemts = {
        user_group_id: 0,
        assigned_with_email: "",
        assignment_full_name: "",
        assignment_type: "",
    };

    //  set hook useState
    const [contract, setContract] = useState(initContract);
    const [showAddAttr, setShowAddAttr] = useState(false);
    const [attributes, setAttributes] = useState([]);
    const [newAttr, setNewAttr] = useState("");
    const [assignments, setAssignments] = useState([]);
    const [groups, setGroups] = useState([]);
    const [publish, setPublish] = useState(initPublish);
    const [isLoading, setLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [errors, setErrors] = useState({});
    // number assignment exist - for edit page
    const [existAssign, setExistAssign] = useState(0);
    const [thumbnailData, setThumbnailData] = useState([]);
    const [fileStorage, setFileStorage] = useState({});

    const handleCloseAddAttr = () => setShowAddAttr(false);
    const handleShowAddAttr = () => {
        setShowAddAttr(true);
    };

    // methods

    function removeAttr(index) {
        attributes.splice(index, 1);
        setAttributes([...attributes]);
    }

    const changeAttrType = (index, newVal) => {
        attributes[index].type = parseInt(newVal);
        setAttributes([...attributes]);
    };

    const changeContractInfo = (type) => (e) => {
        contract[type] = e.target.value;
        setContract({ ...contract });
        validateData();
    };

    function handleAddAttr() {
        if (_.isEmpty(newAttr.trim())) return false;
        attributes.push({
            key: newAttr.trim(),
            type: ContractAssignmentConstant.AttributeType.Text,
        });
        setAttributes([...attributes]);
        setShowAddAttr(false);
        setNewAttr("");
        validateData();
    }

    function setType(type) {
        const nContract = { ...contract };
        nContract.contract_type = type;
        setContract(nContract);
    }

    const loadGroups = async () => {
        axiosAuth.get("/user/list-group-current-user").then((result) => {
            const listGroups = result.data.datas.map((data) => {
                return { id: data.user_group_id, name: data.group_name };
            });
            if (result.data.datas) setGroups(listGroups);
        });
    };
    const addAssign = () => {
        if (action === "detail")
            initAssignemts.assignment_type = ContractAssignmentConstant.Type.View;
        // const newAssignment = JSON.parse(JSON.stringify(initAssignemts))
        setAssignments([...assignments, initAssignemts]);
    };

    /* validate object */
    function validateData() {
        if (!initValidate) return false;
        const data = getFinalData();
        let errObj = {};
        let errContract = {};
        let errAssignment = [];
        if (!data.contract.contract_title.trim())
            errObj.contract_title = t("Contract.errors.contract_title");
        if (!data.contract.subject.trim()) errObj.subject = t("Contract.errors.contract_subject");
        if (!data.contract.message.trim()) errObj.message = t("Contract.errors.contract_message");
        data.assignments.forEach((assign, index) => {
            let errAssign = {};
            if (!assign.user_group_id || assign.user_group_id === "0") {
                if (!assign.assigned_with_email.trim()) {
                    errObj["assignment_" + index + "_group"] = t("Contract.errors.group");
                    errObj["assignment_" + index + "_email_empty"] = t(
                        "Contract.errors.email_empty",
                    );
                } else if (!checkEmail(assign.assigned_with_email)) {
                    errObj["assignment_" + index + "_email"] = t("Contract.errors.email");
                } else if (!assign.assignment_full_name.trim()) {
                    errObj["assignment_" + index + "_fullname"] = t("Contract.errors.fullname");
                }
            }
            if (!assign.assignment_type) {
                errObj["assignment_" + index + "_type"] = t("Contract.create.roleViewSign");
            }
            if (!_.isEmpty(errAssign)) errAssignment[index] = errAssign;
        });
        if (_.isEmpty(attributes)) errObj.attribute = t("Contract.errors.attribute");
        if (!_.isEmpty(errContract)) errObj.contract = errContract;
        if (errAssignment.length > 0) errObj.assignments = errAssignment;
        setErrors(errObj);
        return errObj;
    }

    /* merge block publish info to contract object */
    const getFinalData = () => {
        const contractInfo = { ...contract, ...publish };
        const data = {
            contract: contractInfo,
            assignments,
            attributes,
            action,
        };
        return data;
    };

    /* create contract */
    const createNewContract = (data) => {
        axiosAuth.post(ApiConstant.Contract.Create, data).then((result) => {
            setLoading(false);
            if (result.data.api_result.result === 0) {
                setIsComplete(true);
            } else {
                alert(result.data.api_result.message);
            }
        });
    };

    /*  update contract */
    const updateContract = (data) => {
        data.exist_assignment = existAssign;
        axiosAuth.post(ApiConstant.Contract.Update, data).then((result) => {
            setLoading(false);
            if (result.data.api_result.result === 0) {
                setIsComplete(true);
            } else {
                alert(result.data.api_result.message);
            }
        });
    };

    /* action submit */
    const submitContract = () => {
        initValidate = true;
        const data = getFinalData();
        const errObj = validateData();

        if (!_.isEmpty(errObj)) {
            if (action === "create") toast.error(t("Contract.errors.cannot_create"));
            else if (action === "detail") toast.error(t("Contract.errors.cannot_update"));
            return false;
        }

        setLoading(true);
        data.assignments.map(function (assign) {
            assign.user_group_id =
                parseInt(assign.user_group_id) === 0 ? null : assign.user_group_id;
            return assign;
        });
        if (action === "create") createNewContract(data);
        else if (action === "detail") updateContract(data);
    };

    if (props.action) {
        if (props.action === "detail") {
            action = "detail";
            disableForm = true;
        }
    }
    // set hook useEffect
    useEffect(function () {
        // detect current page action

        const loadData = async () => {
            await loadGroups();
            if (action === "detail") {
                axiosAuth("/contract/detail/" + params.id).then((res) => {
                    if (res.data.api_result.result === 0) {
                        const dataContract = res.data.datas;
                        dataContract.assignments.map(function (assign) {
                            assign.user_group_id =
                                assign.user_group_id === null ? 0 : assign.user_group_id;
                            return assign;
                        });

                        setAssignments(dataContract.assignments);
                        setExistAssign(dataContract.assignments.length);
                        setAttributes(dataContract.attributes);
                        setFileStorage(dataContract.file_before_sign_file);
                        setThumbnailData(dataContract.file_before_sign_file.thumbnail_data)
                        delete dataContract.assignments;
                        delete dataContract.attributes;
                        setContract(dataContract);
                        for (const index in initPublish) {
                            initPublish[index] = dataContract[index];
                        }
                        setPublish({ ...initPublish });
                    }
                });
            } else {
                if (
                    location.state.contract_template &&
                    location.state.contract_template.attribute
                ) {
                    //    const templateAttribute = location.state.contract_template.attribute.map(attr => { return {key : attr , type :  ContractAssignmentConstant.AttributeType.Text} });
                    // console.log(templateAttribute);
                    const templateAttribute = location.state.contract_template.attribute;
                    setAttributes(templateAttribute);
                } else {
                    setAttributes(initAttributes);
                }
                initContract.contract_content = location.state.content;
                initContract.file_before_sign_file_id = location.state.fileStorageId;
                if (
                    location.state.contract_template &&
                    location.state.contract_template.contract_template_id
                ) {
                    initContract.contract_template_id =
                        location.state.contract_template.contract_template_id;
                }
                setAssignments([initAssignemts]);

                if (location.state.thumbnailData){
                    setThumbnailData(location.state.thumbnailData)
                }
            }
            setContract({ ...initContract });
        };

        if (action === "detail") {
            document.title = t("Title.contractDetail");
            loadData();
        } else if (action === "create") {
            document.title = t("Title.contractCreate");
            if (!location.state) {
                history.push("/contract/select-template");
            }
            loadData();
        }
    }, []);

    const hanldeBackToPreviousStep = () => {
        const pageProps = action === "create" ? props : props.props;
        const queryParams = new URLSearchParams(pageProps.location.search);
        const url =
            action === "create"
                ? RouteConstant.Contract.InitContract.path
                : RouteConstant.Contract.List.path;

        redirectToAppSchemeOrWebPath(queryParams, url);
    };

    return (
        <div>
            {isLoading ? <Loading /> : ""}
            <div className="clearfix" />
            <div className="page-container">
                <div className="page-content-wrapper">
                    {isComplete ? (
                        <ContractSuccess
                            qParams={queryParams}
                            title={t("Contract.msg_confirm_contract_success")}
                        />
                    ) : (
                        <div className="page-content contract_creat">
                            <div className="container">
                                <div className="main-body-content">
                                    <div className="content-enter-name-contact">
                                        <div className="contract_setting_title title-header">
                                            <input
                                                disabled={disableForm}
                                                type="text"
                                                className={
                                                    "form-control " +
                                                    (errors.contract_title ? errorInputClass : "")
                                                }
                                                placeholder={t("Contract.create.fillName")}
                                                value={contract.contract_title}
                                                onChange={changeContractInfo("contract_title")}
                                            />
                                            <span className="error hidden">
                                                {errors.contract_title}
                                            </span>
                                        </div>
                                        <div className="contract_list_setting">
                                            {/* <div className="row">
                                            <p>{contract.contract_content}</p>
                                        </div> */}
                                            <PdfViewer thumbnailData={thumbnailData} />
                                        </div>
                                    </div>
                                    <div className="content-input-item">
                                        <div className="contract_setting_title title-header">
                                            <h5>
                                                <span>{t("Contract.create.itemsSetting")}</span>
                                                {disableForm ? (
                                                    ""
                                                ) : (
                                                    <button
                                                        type="button"
                                                        onClick={handleShowAddAttr}
                                                        className="btn add_contract_sub"
                                                    >
                                                        <img
                                                            src="/images/plus_w.png"
                                                            alt="thumbnail"
                                                        />
                                                    </button>
                                                )}
                                            </h5>
                                        </div>

                                        <Modal
                                            show={showAddAttr}
                                            onHide={handleCloseAddAttr}
                                            animation={false}
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <h5
                                                        id="exampleModalLabel"
                                                        className="modal-title text-left"
                                                    >
                                                        {t("Contract.create.addNewItem")}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                        className="close"
                                                    >
                                                        <span aria-hidden="true">×</span>
                                                    </button>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <input
                                                    type="text"
                                                    value={newAttr}
                                                    placeholder=""
                                                    name="new_attr"
                                                    className="form-control"
                                                    onChange={(e) => setNewAttr(e.target.value)}
                                                />
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button
                                                    variant="secondary"
                                                    onClick={handleCloseAddAttr}
                                                >
                                                    Close
                                                </Button>
                                                <Button variant="primary" onClick={handleAddAttr}>
                                                    Save Changes
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                        <div className="contract_list-input">
                                            <ContractAttributes
                                                attributes={attributes}
                                                attrType={ContractAssignmentConstant.AttributeType}
                                                removeAttr={(index) => removeAttr(index)}
                                                changeAttrType={(index, newVal) =>
                                                    changeAttrType(index, newVal)
                                                }
                                                disableForm={disableForm}
                                            />
                                        </div>
                                    </div>
                                    <span className="error hidden">{errors.attribute}</span>
                                    <div className="content-radio-item">
                                        <div className="contract_setting_title title-header">
                                            <h5>{t("Contract.create.typeSetting")} </h5>
                                        </div>
                                        <div className="contract-list-radio">
                                            <form method="POST" target="hidden_iframe">
                                                {arrTypes.map((type) => (
                                                    <div
                                                        className="form-group row"
                                                        key={type.index}
                                                    >
                                                        <label className="col-sm-2 col-form-label text-right">
                                                            <input
                                                                type="radio"
                                                                name="membership"
                                                                checked={
                                                                    contract.contract_type ===
                                                                    type.index
                                                                }
                                                                value={type.index}
                                                                onChange={() => setType(type.index)}
                                                            />
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <div className="item_form">
                                                                <input
                                                                    type="text"
                                                                    placeholder={type.placeholder}
                                                                    className="form-control"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </form>
                                        </div>
                                    </div>

                                    <div className="content-send-settings">
                                        <div className="contract_setting_title title-header">
                                            <h5>{t("Contract.create.sendSetting")}</h5>
                                        </div>
                                        <div className="contract-list-send-setting">
                                            <div className="send_settings_wrapper register">
                                                <div className="form send_settings">
                                                    <section className="send_settings_content">
                                                        <form method="POST" target="hidden_iframe">
                                                            <div className="form-group row item-company">
                                                                <label className="col-sm-2 col-form-label text-left">
                                                                    {t(
                                                                        "Contract.create.mailSubject",
                                                                    )}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <div className="item_form">
                                                                        <input
                                                                            disabled={disableForm}
                                                                            onChange={changeContractInfo(
                                                                                "subject",
                                                                            )}
                                                                            value={contract.subject}
                                                                            type="text"
                                                                            placeholder={t(
                                                                                "Contract.create.sendSubjectSample",
                                                                            )}
                                                                            className={
                                                                                "form-control " +
                                                                                (errors.subject
                                                                                    ? errorInputClass
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <span className="error">
                                                                            {errors.subject}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row item-company mb-60">
                                                                <label className="col-sm-2 col-form-label text-left">
                                                                    {t(
                                                                        "Contract.create.mailMessage",
                                                                    )}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <div className="item_form">
                                                                        <input
                                                                            disabled={disableForm}
                                                                            value={contract.message}
                                                                            onChange={changeContractInfo(
                                                                                "message",
                                                                            )}
                                                                            type="text"
                                                                            placeholder={t(
                                                                                "Contract.create.sendMessageSample",
                                                                            )}
                                                                            className={
                                                                                "form-control " +
                                                                                (errors.message
                                                                                    ? errorInputClass
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <span className="error">
                                                                            {errors.message}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Context.Provider
                                                                value={[
                                                                    assignments,
                                                                    setAssignments,
                                                                    errorInputClass,
                                                                    disableForm,
                                                                    errors,
                                                                    validateData,
                                                                    existAssign,
                                                                ]}
                                                            >
                                                                <AssigmentsBlock groups={groups} />
                                                            </Context.Provider>
                                                        </form>
                                                    </section>
                                                    {/* {
                                                        (disableForm) ? '':   */}
                                                    <div className="add_button row">
                                                        <div className="col-sm-10">
                                                            <button
                                                                onClick={addAssign}
                                                                className="btn add_contract_sub"
                                                            >
                                                                <img
                                                                    src="/images/plus_color.png"
                                                                    alt="thumbnail"
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {/* } */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Context.Provider value={[publish, setPublish]}>
                                        <PublishBlock
                                            initPublish={initPublish}
                                            disableForm={disableForm}
                                            action={action}
                                        />
                                    </Context.Provider>

                                    <div className="content-send-settings">
                                        <div className="contract-list-send-setting">
                                            <div className="send_settings_wrapper register">
                                                <div className="form send_settings">
                                                    <div className="send_settings_content">
                                                        <div className="submit-form full">
                                                            {/* {action === "create" ? ( */}
                                                            <button
                                                                onClick={() => submitContract()}
                                                                className="btn blue button-submit btn-back"
                                                            >
                                                                {t("Contract.create.btnSubmit")}
                                                            </button>
                                                            {/*
                                                                )
                                                                  : (
                                                                    ""
                                                                )} */}
                                                            <button
                                                                className="btn blue button-submit"
                                                                onClick={hanldeBackToPreviousStep}
                                                            >
                                                                {t("Contract.create.btnBack")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
export default UpdateContract;
