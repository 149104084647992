import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import RouteConstant from "../../../constants/RouteConstant";
import { useTranslation } from "react-i18next";

function LayoutForgotPassword(props) {
    const { t } = useTranslation();

    return (
        <div className="forgot_password login">
            <div className="page-container">
                <div className="login_wrapper">
                    <div className="form login_form login">
                        <section className="login_content">
                            <form
                                method="POST"
                                target="hidden_iframe"
                                onSubmit={props.handleSubmitFormForgotPassword}
                            >
                                <h1>{t("forgot_password.txt_title_forgot_password")}</h1>
                                <div className="login-note">
                                    <p className="text-center">
                                        {t("forgot_password.txt_desc_forgot_password")}
                                    </p>
                                </div>
                                <div className="item_form">
                                    <input
                                        type="text"
                                        placeholder={t("forgot_password.txt_placeholder_mail")}
                                        name="email"
                                        className="form-control"
                                        onChange={(e) => {
                                            props.setDataForgotPassword({
                                                ...props.dataForgotPassword,
                                                email: e.target.value,
                                            });
                                        }}
                                    />
                                    <span className="icon icon_mail" />
                                    {!_.isEmpty(props.dataForgotPassword.errors) &&
                                        props.dataForgotPassword.errors.map((error, index) => {
                                            return (
                                                <span className="note_er" key={index}>
                                                    {error}
                                                </span>
                                            );
                                        })}
                                </div>
                                <div className="submit-form">
                                    <Link to={RouteConstant.Login.path} className="btn green button-submit btn-back">
                                        {t("button.btn_back")}
                                    </Link>
                                    <button className="btn green button-submit" type="submit">
                                        {t("button.btn_submit")}
                                    </button>
                                </div>
                                <div className="clearfix" />
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LayoutForgotPassword;
