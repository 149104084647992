import React, { useEffect, useState } from "react";
import RouteConstant from "../../constants/RouteConstant";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {api} from "../../services/api";
import Loading from "../../shared/form/Loading";
import Input from "../../shared/form/Input";
import ConfirmModal from '../../shared/form/ConfirmModal';
import ApiConstant from "../../constants/ApiConstant";
import { toast } from "react-toastify";

function Setting() {
    const [form, setValues] = useState({
        accept_search_information: "",
        accept_push_from_contract: "",
        accept_push_from_admin: "",
        accept_access_location: ""
    });

    const ON = 1;
    const OFF = 0;

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [showLocation, setShowLocation] = useState(false);
    const handleCloseLocation = () => setShowLocation(false);

    useEffect(() => {
        document.title = t("setting.title");
        getDataSetting(form);
    }, [t]);

    const getDataSetting = async(e) => {
        setLoading(true);
        const pathSetting = ApiConstant.Setting.GetSetting
        const result = await api.get(pathSetting, form)
        if (result.api_result.result === ApiConstant.Result.Success) {
            setLoading(false);
            const datas = result.datas
            setValues(datas)
        } else {
            setLoading(false);
        }
    }
    const renderModalLocation = () => {
        return (
            <>
                <div>
                    {t('setting.confirm_location')}
                </div>
            </>            
        )
    }

    const checkAcceptSearchInfo = (e) => {
        if (e.target.value === 1) {
            form.accept_search_information = 0
        } else {
            form.accept_search_information = 1
        }
    }

    const acceptPushFromContract = (e) => {
        console.log(e.target.value)
    }
    
    const acceptPushFromAdmin = (e) => {
        console.log(e.target.value)
    }
    const checkAllowLocation = (e) => {
        if (e.target.value === 1) {
            setShowLocation(true);
        }
    }

    const onSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);
        const pathUpdateSetting = ApiConstant.Setting.UpdateSetting
        const result = await api.post(pathUpdateSetting, form)
        if (result.api_result.result === ApiConstant.Result.Success) {
            setLoading(false);
            toast.success(t("setting.updated_success"))
        } else {
            setLoading(false);
            toast.success(t("setting.updated_fail"))
        }
    }


    return (
        <>
        <div className="page-container">
            <div className="page-content-wrapper">
                <div className="page-content contract_creat contract_setting">
                    <div className="container">
                        <form method="POST" onSubmit={onSubmit}>
                            <div className="main-body-content">
                                <div className="content-radio-item">
                                <div className="contract_setting_title title-header">
                                    <h5>{t("setting.setting_security")}</h5>
                                </div>
                                <div className="contract-list-radio">
                                    <div className="form-group row">
                                        <label className="col-md-3 col-sm-4 text-right line-30">{t("setting.allow_search")}</label>
                                        <div className="col-md-8 col-sm-8">
                                        <div className="item_form sw-input">
                                            <label className="switch">
                                            <input 
                                                type="checkbox"
                                                data-toggle="toggle"
                                                data-size="sm"
                                                onChange={checkAcceptSearchInfo}
                                                value={form.accept_search_information}
                                                checked={form.accept_search_information ? true : false}
                                            />
                                            <span className="slider round" />
                                            </label>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="content-radio-item">
                                <div className="contract_setting_title title-header">
                                    <h5>{t("setting.notify_push")}</h5>
                                </div>
                                <div className="contract-list-radio">
                                    <div className="form-group row">
                                        <label className="col-md-3 col-sm-4 text-right line-30">{t("setting.activities_related_contract")}</label>
                                        <div className="col-md-8 col-sm-8">
                                        <div className="item_form sw-input">
                                            <label className="switch">
                                            <input 
                                                type="checkbox"
                                                data-toggle="toggle"
                                                data-size="sm"
                                                checked={form.accept_push_from_contract}
                                                onChange={acceptPushFromContract}
                                            />
                                            <span className="slider round" />
                                            </label>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-3 col-sm-4 text-right line-30">{t("setting.notify_to_admin")}</label>
                                        <div className="col-md-8 col-sm-8">
                                        <div className="item_form sw-input">
                                            <label className="switch">
                                            <input 
                                                type="checkbox"
                                                data-toggle="toggle"
                                                data-size="sm"
                                                checked={form.accept_push_from_admin}
                                                onChange={acceptPushFromAdmin}
                                            />
                                            <span className="slider round" />
                                            </label>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="content-radio-item">
                                <div className="contract_setting_title title-header">
                                    <h5>{t("setting.setting_custom")}</h5>
                                </div>
                                <div className="contract-list-radio">
                                    <div className="form-group row">
                                        <label className="col-md-3 col-sm-4 text-right line-30">{t("setting.terms_of_use")}</label>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-3 col-sm-4 text-right line-30">{t("setting.privacy_policy")}</label>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-3 col-sm-4 text-right line-30">
                                        </label>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-3 col-sm-4 text-right line-30">{t("setting.infor_version")}</label>
                                    </div>
                                </div>
                                </div>
                                <div className="content-radio-item">
                                <div className="contract_setting_title title-header">
                                    <h5>{t("setting.setting_account")}</h5>
                                </div>
                                <div className="contract-list-radio">
                                    <div className="form-group row">
                                        <label className="col-md-3 col-sm-4 text-right line-30">{t("setting.allow_location_info")}</label>
                                        <div className="col-md-8 col-sm-8">
                                        <div className="item_form sw-input">
                                            <label className="switch">
                                            <input 
                                                type="checkbox" 
                                                onChange={checkAllowLocation}
                                                checked={form.accept_access_location}
                                                data-toggle="toggle"
                                                data-size="sm"
                                            />
                                                <span className="slider round" />
                                            </label>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-3 col-sm-4 text-right line-30">{t("setting.plan_curent")}</label>
                                        <div className="col-md-8 col-sm-8">
                                        <div className="item_form mrb-10">
                                            <button className="btn blue button-submit">{t("setting.standard")}</button>
                                        </div>
                                        <div className="item_form">
                                            <button className="btn blue button-submit">{t("setting.upgrade_plan")}</button>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-3 col-sm-4 text-right line-30">{t("setting.skin")}</label>
                                        <div className="col-md-8 col-sm-8"><a href="#" className="line-30">{t("setting.apply_skin")}</a></div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-3 col-sm-4 text-right line-30">{t("setting.forgot_password")}</label>
                                        <div className="col-md-8 col-sm-8"><Link to={RouteConstant.ForgotPassword.path} className="line-30">{t("setting.change_password")}</Link></div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-3 col-sm-4 text-right line-30">{t("setting.group")}</label>
                                        <div className="col-md-8 col-sm-8"><Link to={RouteConstant.Group.List.path} className="line-30">{t("setting.go_to_group")}</Link></div>
                                    </div>
                                </div>
                                </div>
                                <div className="submit-form full mt-30">
                                    <button className="btn blue button-submit">{t("setting.save")}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <ConfirmModal
            title={t("setting.change_active_location")} 
            showModal={showLocation}
            onClose={handleCloseLocation}
            body={renderModalLocation()}
            cancel={t("setting.cancel")}
            confirm={t("setting.save")}
        />
        {loading ? <Loading /> : ""}
        </>
    )
}
export default Setting;
