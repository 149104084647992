export const ContractAssignmentConstant = {
    Status: {
        NotSignYet: 0,
        Sign: 1,
        Reject: 2,
        Signing: 3,
    },
    Type: {
        View: 0,
        Sign: 1,
    },
    AttributeType: {
        Signature: 1,
        Text: 2,
    },
    SigningContractDataKey: "contract_signing_data",
    SigningContractUrlExpires: 3600,
};

export default ContractAssignmentConstant;
