import React from "react";
import RouteConstant from "../../constants/RouteConstant";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MenuManager() {
    const { t } = useTranslation();
    
    return (
        <div className="col-sm-3 com-xs-3">
            <div className="menu_group">
                <ul>
                    <li>
                        <Link to={RouteConstant.Company.ListUser.path}>
                            {t("company.manager_member")}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouteConstant.Company.ListContract.path}>
                            {t("company.manager_contract")}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouteConstant.Company.ListPayment.path}>
                            {t("company.manager_payment")}
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default MenuManager;