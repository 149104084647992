import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import ConditionalRender from "../../helpers/ConditionalRender";

function ConfirmModal(props) {
    return (
        <Modal
            className={props.className}
            show={props.showModal}
            onHide={props.onClose}
            animation={false}
        >
            <ConditionalRender condition={props.title !== ""}>
                <Modal.Header translate="yes" closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
            </ConditionalRender>

            <Modal.Body>{props.body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onClose}>
                    {props.cancel}
                </Button>
                {props.confirm ? (
                    <Button variant="primary" onClick={props.onConfirm}>
                        {props.confirm}
                    </Button>
                ) : null}
            </Modal.Footer>
        </Modal>
    );
}

ConfirmModal.propTypes = {
    showModal: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    body: PropTypes.any,
    cancel: PropTypes.string,
    confirm: PropTypes.string,
    className: PropTypes.string,
};

export default React.memo(ConfirmModal);
