import React, { useState, useEffect } from "react";
import ApiConstant from "../../constants/ApiConstant";
import LoginEmail from "./LoginEmail";
import LoginRole from "./LoginRole";
import LoginPassword from "./LoginPassword";
import { login } from "../../services/AuthService";
import RouteConstant from "../../constants/RouteConstant";
import { useTranslation } from "react-i18next";
import { api } from "../../services/api";
import { checkEmail } from "../../utility";
import Loading from "../../shared/form/Loading";
import _ from "lodash";
import UserConstant from "../../constants/UserConstant";
import AuthConstant from "../../constants/AuthConstant";
import Cookies from "universal-cookie";
import ContractAssignmentConstant from "../../constants/ContractAssignmentConstant";

function Login(props) {
    useEffect(() => {
        document.title = t("Login.document_title");
    });

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState([]);
    const [users, setUsers] = useState([]);
    const [form, setForm] = useState({
        email: "",
        role: UserConstant.UserType.Personal,
        password: "",
    });

    const handleSubmitEmail = async (event) => {
        event.preventDefault();

        const errors = [];

        if (!form.email) {
            errors.push(t("validate.required", { attr: t("Login.attr_email") }));
        } else {
            if (!checkEmail(form.email)) {
                errors.push(t("validate.type.email", { attr: t("Login.attr_email") }));
            } else if (form.email.length > 256) {
                errors.push(t("validate.max.string", { attr: t("Login.attr_email"), max: 256 }));
            }
        }

        setErrors(errors);

        if (errors.length > 0) {
            return false;
        }

        setLoading(true);
        const result = await api.post(ApiConstant.Auth.Login.Email, { email: form.email });
        if (result.api_result.result === ApiConstant.Result.Success) {
            setUsers(result.datas);

            if (result.datas.length > 1) {
                moveToStep(AuthConstant.Login.Step.SelectingUserType);
            } else {
                moveToStep(AuthConstant.Login.Step.TypingPassword);

                setForm({
                    ...form,
                    role: result.datas[0].user_type,
                });
            }
        } else {
            setErrors([t("Login.msg_user_not_exist")]);
        }
        setLoading(false);
    };

    const handleSubmitRole = (event) => {
        event.preventDefault();

        moveToStep(AuthConstant.Login.Step.TypingPassword);
    };

    const handleSubmitPassword = async (event) => {
        event.preventDefault();

        const errors = [];

        if (!form.password) {
            errors.push(t("validate.required", { attr: t("Login.attr_password") }));
        } else {
            if (form.password.length < 6) {
                errors.push(t("validate.min.string", { attr: t("Login.attr_password"), min: 6 }));
            }

            if (form.password.length > 256) {
                errors.push(t("validate.max.string", { attr: t("Login.attr_password"), max: 256 }));
            }
        }

        setErrors(errors);

        if (errors.length > 0) {
            return false;
        }

        const data = {
            email: form.email,
            user_type: form.role,
            password: form.password,
        };

        setLoading(true);
        const result = await api.post(ApiConstant.Auth.Login.Password, data);
        if (result.api_result.result === ApiConstant.Result.Success) {
            login(result.datas);

            const qs = new URLSearchParams(props.location.search);
            if (!_.isEmpty(qs.get("redirect_url"))) {
                window.location.href = qs.get("redirect_url");
            } else {
                const cookies = new Cookies();
                const contractData = cookies.get(ContractAssignmentConstant.SigningContractDataKey);

                if (!_.isEmpty(contractData) && form.email === contractData.signEmail) {
                    window.location.href = contractData.url;
                } else {
                    window.location.href = RouteConstant.Contract.List.path;
                }
            }
        } else {
            setErrors([t("Login.msg_password_wrong")]);
        }
        setLoading(false);
    };

    const moveToStep = (step) => {
        setStep(step);
    };

    const setFormField = (field, value) => {
        setForm({
            ...form,
            [field]: value,
        });
    };

    const setEmail = (email) => {
        setFormField("email", email);
    };

    const setRole = (role) => {
        setFormField("role", role);
    };

    const setPassword = (password) => {
        setFormField("password", password);
    };

    if (step === AuthConstant.Login.Step.TypingPassword) {
        return (
            <div>
                {loading ? <Loading /> : ""}
                <LoginPassword
                    email={form.email}
                    role={form.role}
                    users={users}
                    errors={errors}
                    setPassword={setPassword}
                    handleSubmitPassword={handleSubmitPassword}
                    moveToStep={moveToStep}
                />
            </div>
        );
    } else if (step === AuthConstant.Login.Step.SelectingUserType) {
        return (
            <div>
                {loading ? <Loading /> : ""}
                <LoginRole
                    email={form.email}
                    role={form.role}
                    errors={errors}
                    users={users}
                    setRole={setRole}
                    handleSubmitRole={handleSubmitRole}
                    moveToStep={moveToStep}
                />
            </div>
        );
    }

    return (
        <div>
            {loading ? <Loading /> : ""}
            <LoginEmail
                email={form.email}
                errors={errors}
                setEmail={setEmail}
                handleSubmitEmail={handleSubmitEmail}
            />
        </div>
    );
}

export default Login;
