import _ from "lodash";
import { isMobile } from "react-device-detect";

export const isMobileByQueryParams = (qParams) => {
    const device = qParams.get("device");

    return !_.isEmpty(device) && _.includes(["ios", "android"], device);
};

export const redirectToSchema = (qParams) => {
    window.location.href = qParams.get("schema");
};

export const redirectToAppSchemeOrWebPath = (qParams, path) => {
    if (isMobileByQueryParams(qParams) && isMobile) {
        redirectToSchema(qParams);
    } else {
        window.location.href = path;
    }
};
