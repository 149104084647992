import React from "react";
import "./Loading.css";

const Loading = (props) => {
    return (
        <div id="loadingContainer">
            <div className="d-flex justify-content-center loading">
                <div
                    className="spinner-border text-primary"
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                >
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    );
};

export default Loading;
