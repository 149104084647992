import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import RouteConstant from "../../constants/RouteConstant";
import { useTranslation } from "react-i18next";
import Input from "../../shared/form/Input";
import UserConstant from "../../constants/UserConstant";
import AuthConstant from "../../constants/AuthConstant";

function LoginRole(props) {
    const { t } = useTranslation();

    return (
        <div className="login">
            <div className="page-container">
                <div className="login_wrapper">
                    <div className="form login_form login">
                        <section className="login_content">
                            <form method="POST" onSubmit={props.handleSubmitRole}>
                                <h1>{t("Login.title")}</h1>
                                <div className="login-note">
                                    <p className="text-center">
                                        {t("Login.you_have_some_accounts", {
                                            email: props.email,
                                            number: 2,
                                        })}
                                    </p>
                                    <p className="text-center">{t("Login.choose_account")}</p>
                                </div>
                                <div
                                    data-error-container="#form_2_membership_error"
                                    className="radio-list login_company_step2"
                                >
                                    {!_.isEmpty(props.users) && props.users.length > 0 ? (
                                        <label className="text-left">
                                            <Input
                                                name="user_type"
                                                type="radio"
                                                defaultChecked={
                                                    props.role === UserConstant.UserType.Personal
                                                        ? "checked"
                                                        : ""
                                                }
                                                onClick={() =>
                                                    props.setRole(UserConstant.UserType.Personal)
                                                }
                                            />
                                            {t("Login.login_role_1")}
                                        </label>
                                    ) : null}

                                    {!_.isEmpty(props.users) && props.users.length > 1 ? (
                                        <label className="text-left">
                                            <Input
                                                name="user_type"
                                                type="radio"
                                                defaultChecked={
                                                    props.role === UserConstant.UserType.Company
                                                        ? "checked"
                                                        : ""
                                                }
                                                onClick={() =>
                                                    props.setRole(UserConstant.UserType.Company)
                                                }
                                            />
                                            {t("Login.login_role_2")}
                                        </label>
                                    ) : null}
                                </div>
                                <div className="submit-form">
                                    <button
                                        className="btn green button-submit btn-back"
                                        type="button"
                                        onClick={() =>
                                            props.moveToStep(AuthConstant.Login.Step.TypingEmail)
                                        }
                                    >
                                        {t("button.btn_cancel_2")}
                                    </button>
                                    <button className="btn green button-submit" type="submit">
                                        {t("button.btn_next")}
                                    </button>
                                </div>
                                <div className="note-form mt-10">
                                    <p className="text-center">
                                        <Link to={RouteConstant.ForgotPassword.path}>
                                            {t("Login.you_forgot_password")}
                                        </Link>
                                    </p>
                                    <p
                                        className="text-center mt-10"
                                        onClick={() =>
                                            props.moveToStep(AuthConstant.Login.Step.TypingEmail)
                                        }
                                    >
                                        {t("Login.login_with_another_account")}
                                    </p>
                                </div>
                                <div className="clearfix" />
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginRole;
