import React from "react";
import { Link } from "react-router-dom";
import RouteConstant from "../../../constants/RouteConstant";
import { useTranslation } from "react-i18next";

function LayoutForgotSuccess() {
    const { t } = useTranslation();

    return (
        <div className="forgot_password login">
            <div className="page-container">
                <div className="login_wrapper register register_active">
                    <div className="form login_form login">
                        <section style={{ paddingTop: "50px" }} className="login_content">
                            <h1>{t("forgot_password.txt_title_forgot_password")}</h1>
                            <div className="login-note">
                                <p className="text-center">{t("forgot_password.txt_msg_notify")}
                                <br />
                                {t("forgot_password.txt_msg_notify2")}</p>
                            </div>
                            <div className="submit-form">
                                <Link to={RouteConstant.Login.path}>
                                    <button className="btn green button-submit">
                                        {t("button.btn_done")}
                                    </button>
                                </Link>
                            </div>
                            <div className="clearfix" />
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LayoutForgotSuccess;
