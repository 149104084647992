import React, {useEffect, useState} from 'react';
import ApiConstant from "../../constants/ApiConstant";
import Input from "../../shared/form/Input";
import Loading from "../../shared/form/Loading";
import {api} from "../../services/api";
import { useTranslation } from "react-i18next";
import RenderListGroup from "./RenderListGroup"

function SearchGroup(props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [dataGroup, setDataGroup] = useState({
        
    });
    const [groupName, setGroupName] = useState("");
    const [errors, setError] = useState([]);


    const handleChangeValue = (e) => {
        setGroupName(e.target.value);
    };

    const searchDataGroup = async () => {
        const paramGroup = new URLSearchParams(props.location.search);
        const group_name = groupName ? groupName : paramGroup.get("group_name");
        setLoading(true);
        const path = ApiConstant.Group.SearchGroup;
        const result = await api.get(path, {group_name: group_name});
        if (result.api_result.result === ApiConstant.Result.Success) {
            setLoading(false);
            let datas = result.datas;
            setDataGroup(datas);
        } else {
            setLoading(false);
            setDataGroup([]);
            setError({
                ...errors,
                msg: result.api_result.message,
            });
        }
    }
    useEffect(() => {
        document.title = t("group.title");
        searchDataGroup();
    }, [t]);

    return (
        <>
            <div>
                <div className="clearfix" />
                <div className="page-container">
                    <div className="page-content-wrapper">
                        <div className="page-content contract_group">
                            <div className="container">
                                <div className="main-body-content">
                                    <div className="contract_suport_title border-bottom mrb-10">
                                        <h3 className="mt-30">{t("group.manager_group")}</h3>
                                    </div>
                                    <div className="contract_group_ct">
                                        <div className="row">
                                            <div className="col-sm-2 com-xs-2" />
                                            <div className="col-sm-8 com-xs-8">
                                                <div className="md-full mt-20">
                                                    <form method="GET" target="hidden_iframe">
                                                        <div className="form-group form-search">
                                                            <Input
                                                                onChangeValue={(e) =>
                                                                    handleChangeValue(e, true)
                                                                }
                                                                name="group_name"
                                                                type="text"
                                                                value={groupName.group_name}
                                                                placeholder={t(
                                                                    "group.place_holder_group_name",
                                                                )}
                                                                className="form-control"
                                                            />
                                                            <button
                                                                type="button"
                                                                onClick={searchDataGroup}
                                                                className="btn green button-submit"
                                                            >
                                                                {t("group.search_group")}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-2 com-xs-2" />
                                            <div className="col-sm-8 com-xs-8">
                                                <div className="list-group md-full mt-10">
                                                    <div className="list-group-content search-cotnent">
                                                        <ul>
                                                            <RenderListGroup
                                                                group={dataGroup}
                                                                searchDataGroup={searchDataGroup}
                                                            />
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading ? <Loading /> : ""}
            </div>
        </>
    )
}

export default SearchGroup