import React from "react";
import SupportConstants from "../../constants/SupportConstants";
import { useTranslation } from "react-i18next";
import ClassNames from "classnames";

function LayoutConfirm (props) {
  const { t } = useTranslation();
    return (
      <div className="page-content contract_suport">
        <div className="container">
          <div className="main-body-content">
            <div className="contract_suport_title border-bottom mrb-30">
              <h3 className="mt-30">{t("Support.title")}</h3>
            </div>
            <div className="contract_suport_ct">
              <p className="note-form text-center font-16">{t("Support.confirm_desc")}</p>
              <div className="row">
                <div className="col-sm-2 com-xs-2" />
                <div className="col-sm-8 com-xs-8">
                  <div className="md-full mt-30">
                    <form method="POST" target="hidden_iframe">
                      {props.data.title ?
                        <div className="form-group row">
                          <div className="col-sm-3 text-right line-42">{t("Support.txt_title")}</div>
                          <div className="col-sm-9">
                            <input type="text" className="form-control" disabled value={props.data.title}/>
                          </div>
                        </div>
                       : ""}
                      {props.data.type_question ?
                      <div className="form-group row">
                        <div className="col-sm-3 text-right line-42">{t("Support.txt_type")}</div>
                        <div className="col-sm-9">
                          <input type="text" className="form-control" disabled value={t("Support.type_question_"+props.data.type_question)}/>
                        </div>
                      </div>
                      : ""}
                      {props.data.id_contract ?
                      <div className="form-group row">
                        <div className="col-sm-3 text-right line-42">{t("Support.txt_id_contract")}</div>
                        <div className="col-sm-9">
                          <input type="text" className="form-control" disabled value={props.data.id_contract}/>
                        </div>
                      </div>
                      : ""}
                      {props.data.content ?
                      <div className="form-group row">
                        <div className="col-sm-3 text-right line-42">{t("Support.txt_content")}</div>
                        <div className="col-sm-9">
                          <textarea name="editor2" rows={6} className="rw-ckeditor form-control" disabled defaultValue={props.data.content}/>
                          <span className={ClassNames("error", { hidden: !props.dataErrors.error_api })}>{props.dataErrors.error_api}</span>
                        </div>
                      </div>
                      : ""}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="call_button">
              <button className="btn blue button-submit btn-back" onClick={(e) => props.handleChangeStep(e, SupportConstants.Status.Handle)}>{t("Register.cancel")}</button>
              <button className="btn green button-submit" onClick={(e) => props.handleSend(e)}>{t("button.btn_submit")}</button>
            </div>
          </div>
        </div>
      </div>
    );
}

export default LayoutConfirm