import React, {useState, useEffect, useRef} from 'react';
import ApiConstant from "../../constants/ApiConstant";
import {api} from "../../services/api";
import Loading from "../../shared/form/Loading";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RouteConstant from "../../constants/RouteConstant";
import moment from "moment-timezone";
import _ from "lodash";
import Pagination from "rc-pagination";
import ConfirmModal from '../../shared/form/ConfirmModal';
import axiosAuth from "../../services/RequestService";
import MenuManager from './MenuManager';

function ListContractCompany() {
    const [dataContract, setDataContract] = useState([]);
    const [companyName, setCompanyName] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataPaginate, setDataPaginate] = useState({});
    const [keyword, setKeyword] = useState("");
    const [formData, setFormData] = useState({});
    const { t } = useTranslation();
    const thumbnailRef = useRef(null);
    const [contract, setContract] = useState({
        contract_id: "",
        contract_title: "",
        created_at: "",
        created_by_user_name: "",
        status: "",
        updated_at: "",
        status_text: "",
        status_last_evidence: ""
    });
    const formatDateContract = (date) => {
        return moment(date).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm');
    }
    const [showAddAttr, setShowAddAttr] = useState(false);
    const handleCloseAddAttr = () => setShowAddAttr(false);

    const getDataContract = async(e) => {
        const form = {
            user_in_company: "",
            keyword: keyword,
            page:1
        };
        let pathListContract = ApiConstant.Company.ListContract
        setLoading(true);
        
        const result = await api.post(pathListContract, form);
        if (result.api_result.result === ApiConstant.Result.Success) {
            setLoading(false);
            let datas = result.datas.data.data;
            setCompanyName(result.datas.company_name)
            setDataContract(datas);
            setDataPaginate(result.datas.data);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        document.title = t("company.title");
        getDataContract(formData);
    }, [t]);
    
    const formatDate = (date) => {
        return moment(date).tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm:ss");
    };
    const handleDownloadFile = (url, fileName) => {
        window.open(url, '_blank');
    }
    
    const detailEvidence = (contractId) => {
        setShowAddAttr(true);
        setLoading(true);
        axiosAuth
            .get(ApiConstant.Contract.Detail.replace(":contractId", contractId))
            .then((response) => {
                setLoading(false);
                setContract(response.data.datas);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }

    const renderModalDetailContract = () => {
        return(
            <div className="md-full mt-10">
                <form method="POST" target="hidden_iframe">
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("company.id_contract")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={contract.contract_id ? '00'+contract.contract_id: ""} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("company.name_contract")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={contract.contract_title} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("company.date_time_create")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={contract.created_at ? formatDateContract(contract.created_at) : ""} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("company.id_owner")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={contract.created_by_user_name} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("company.last_status")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={contract.status_last_evidence} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("company.date_time_last_update")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={contract.updated_at ? formatDateContract(contract.updated_at) : ""} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("company.public_status")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={contract.status_text} className="form-control" />
                    </div>
                    </div>
                </form>
            </div>
        )
    }
    
    const handlePaginate = (page) => {
        setFormData((prevState) => ({
            ...prevState,
            page: page,
        }));
    };

    const handleSearchByKeyword = (e) => {
        e.preventDefault();        
        setFormData((prevState) => ({
            ...prevState,
            keyword: keyword,
            page: 1,
        }));
        getDataContract()

    };

    return (
        <div>
            <div className="clearfix" />
            <div className="page-container">
                <div className="page-content-wrapper">
                    <div className="page-content contract_group list_contract list_contract_company">
                        <div className="container">
                            <div className="main-body-content">
                                <div className="contract_suport_title border-bottom mrb-10">
                                    <h3 className="mt-30">{ companyName ? t("company.company_name") + companyName : ''}</h3>
                                    <h3 className="mt-30">{t("company.manager_contract")}</h3>
                                </div>
                                <div className="contract_group_ct">
                                    <div className="row">
                                        <MenuManager />
                                        <div className="col-sm-9 com-xs-9">
                                            <div className="md-full mt-20 contract_form_search_group">
                                                <form
                                                    action=""
                                                    method="POST"
                                                    onSubmit={handleSearchByKeyword}
                                                >
                                                    <div className="form-group form-search">
                                                        <input
                                                            onChange={(event) =>
                                                                setKeyword(event.target.value)
                                                            }
                                                            type="text"
                                                            placeholder="テキスト／ユーザーなどの検索"
                                                            className="form-control"
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={handleSearchByKeyword}
                                                            className="btn green button-submit"
                                                        >
                                                            {t("company.search_company")}
                                                        </button>
                                                        
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="contract_content">
                                        <div
                                            className="contract_content_main"
                                        >
                                            <ul className="checkbox-list checkall list-content">
                                                <li className="item item-title">
                                                    <ul className="item-content">
                                                        <li className="sub-item">
                                                            <span>{t("Contract.contract_id")}</span>
                                                        </li>
                                                        <li className="sub-item">
                                                            <span>
                                                                {t("Contract.contract_title_detail")}
                                                            </span>
                                                        </li>
                                                        <li className="sub-item">
                                                            <span>{t("Contract.time_sign")}</span>
                                                        </li>
                                                        <li className="sub-item">
                                                            <span>{t("Contract.user_sign")}</span>
                                                        </li>
                                                        <li className="sub-item">
                                                            <span>明細確認</span>
                                                        </li>
                                                        <li className="sub-item">
                                                            <span>{t("Contract.download")}</span>
                                                        </li>
                                                    </ul>
                                                </li>
                                                {dataContract && dataContract.length > 0 ? (
                                                    dataContract.map((contract, index) => {
                                                        return (
                                                            <li className="item" key={index}>
                                                                <ul style={ contract.thumbnail_url ? {backgroundImage: `url(${contract.thumbnail_url})`} : {}} className="item-content" ref={thumbnailRef}>
                                                                   
                                                                    <li className="sub-item">
                                                                        <Link
                                                                                to={
                                                                                    !contract.is_owner
                                                                                        ? RouteConstant.Contract.Confirm.path.replace(
                                                                                            ":shareToken",
                                                                                            contract.share_token,
                                                                                        )
                                                                                        : RouteConstant.Contract.DetailContract.path.replace(
                                                                                            ":id",
                                                                                            contract.contract_id,
                                                                                        )
                                                                                }
                                                                            >
                                                                                {contract.contract_id}
                                                                            </Link>
                                                                    </li>
                                                                    <li className="sub-item">
                                                                        <span>
                                                                            {contract.contract_title}
                                                                        </span>
                                                                    </li>
                                                                    <li className="sub-item">
                                                                        <span>
                                                                            {contract.last_sign_contract_date ? formatDate(contract.last_sign_contract_date) : formatDate(contract.updated_at)}
                                                                        </span>
                                                                    </li>
                                                                    <li className="sub-item">
                                                                        {!_.isEmpty(contract.users)
                                                                            ? contract.users
                                                                                .slice(0, 3)
                                                                                .map(
                                                                                    (user, index) => {
                                                                                        return (
                                                                                            <img
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                                className="number"
                                                                                                src={
                                                                                                    user.profile_avatar ? user.profile_avatar : "/images/avatar_men.png"
                                                                                                }
                                                                                                alt=""
                                                                                            />
                                                                                        );
                                                                                    },
                                                                                )
                                                                            : ""}
                                                                        {!_.isEmpty(contract.users) &&
                                                                        contract.users.length > 3 ? (
                                                                            <span className="number">
                                                                                +
                                                                                {contract.users.length -
                                                                                    3}
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </li>
                                                                    <li className="sub-item">
                                                                        <button className="btn btn-primary" onClick={() => detailEvidence(contract.contract_id)}>{t("Contract.view_detail_contract")}</button>
                                                                    </li>
                                                                    <li className="sub-item">
                                                                        <button
                                                                            className="btn-download-pdf"
                                                                            onClick={() => handleDownloadFile(contract.download_url, contract.contract_id)}
                                                                        >
                                                                            <img
                                                                                alt=""
                                                                                src="/images/download.png"
                                                                            />
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        );
                                                    })
                                                ) : (
                                                    <li className="sub-item text-center mrt-10">
                                                        <span>{t("nodata")}</span>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>

                                    {!_.isEmpty(dataPaginate) ? (
                                        <Pagination
                                            className="align-items-center"
                                            onChange={handlePaginate}
                                            current={dataPaginate.current_page}
                                            total={dataPaginate.total}
                                            pageSize={dataPaginate.per_page}
                                        />
                                    ) : (
                                        ""
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmModal
                title={t("company.info_contract")} 
                showModal={showAddAttr}
                onClose={handleCloseAddAttr}
                body={renderModalDetailContract()}
                cancel={t("company.cancel")}
            />
            {loading ? <Loading /> : ""}
        </div>
    );
}

export default ListContractCompany;
