import React, { useEffect, useState } from "react";
import loadTrustDock from "../../services/loadTrustDock.js";

export default function TestTrustDock(props) {
    useEffect(() => {

        loadTrustDock(() => {
            // const trustDockPlan = new window.Trustdock({
            //     publicId: '722487e2-a411-42a4-b248-78494bae7c72',
            //     plans: [
            //         {
            //           id: '528e6386-153a-4319-adda-253eff357479'
            //         }
            //       ],
            //     openerSelector: '.one-one-option'
            //   });

            //   const trustDockPlanTwo = new window.Trustdock({
            //     publicId: '99468ecb-a61f-4e73-8929-c074070894e8',
            //     plans: [
            //       {
            //         id: '528e6386-153a-4319-adda-253eff357479' // 公的身分証の確認プランのID
            //       },
            //       {
            //         id: '5da10ebc-7541-4212-941d-221d30a3d9fe', // セルフィーの確認プランのID
            //         document_source_plan_id: '528e6386-153a-4319-adda-253eff357479' // 確認書類の複製元の公的身分証の確認プランのID
            //       }
            //     ],
            //     openerSelector: '.two-one'
            //   });

            //   const trustDockPlanThree = new window.Trustdock({
            //     publicId: '99468ecb-a61f-4e73-8929-c074070894e8',
            //     plans: [
            //       {
            //         id: '528e6386-153a-4319-adda-253eff357479' // 公的身分証の確認プランのID
            //       },
            //       {
            //         id: '6dc72931-868e-4888-803a-c1d588c6f908', // セルフィーの確認プランのID
            //         document_source_plan_id: '528e6386-153a-4319-adda-253eff357479' // 確認書類の複製元の公的身分証の確認プランのID
            //       }
            //     ],
            //     openerSelector: '.two-two'
            //   });

            //   const trustDockPlanFour = new window.Trustdock({
            //     publicId: '99468ecb-a61f-4e73-8929-c074070894e8',
            //     plans: [
            //       {
            //         id: '5da10ebc-7541-4212-941d-221d30a3d9fe' // 公的身分証の確認プランのID
            //       },
            //       {
            //         id: '528e6386-153a-4319-adda-253eff357479', // セルフィーの確認プランのID
            //         document_source_plan_id: '5da10ebc-7541-4212-941d-221d30a3d9fe' // 確認書類の複製元の公的身分証の確認プランのID
            //       }
            //     ],
            //     openerSelector: '.three-one'
            //   });

            //   const trustDockPlanFive = new window.Trustdock({
            //     publicId: '99468ecb-a61f-4e73-8929-c074070894e8',
            //     plans: [
            //       {
            //         id: '5da10ebc-7541-4212-941d-221d30a3d9fe' // 公的身分証の確認プランのID
            //       },
            //       {
            //         id: '6dc72931-868e-4888-803a-c1d588c6f908', // セルフィーの確認プランのID
            //         document_source_plan_id: '5da10ebc-7541-4212-941d-221d30a3d9fe' // 確認書類の複製元の公的身分証の確認プランのID
            //       }
            //     ],
            //     openerSelector: '.three-two'
            //   });

            //   const trustDockPlanSix = new window.Trustdock({
            //     publicId: '99468ecb-a61f-4e73-8929-c074070894e8',
            //     plans: [
            //       {
            //         id: '6dc72931-868e-4888-803a-c1d588c6f908' // 公的身分証の確認プランのID
            //       },
            //       {
            //         id: '528e6386-153a-4319-adda-253eff357479', // セルフィーの確認プランのID
            //         document_source_plan_id: '6dc72931-868e-4888-803a-c1d588c6f908' // 確認書類の複製元の公的身分証の確認プランのID
            //       }
            //     ],
            //     openerSelector: '.four-one'
            //   });

            //   const trustDockPlanSeven = new window.Trustdock({
            //     publicId: '99468ecb-a61f-4e73-8929-c074070894e8',
            //     plans: [
            //       {
            //         id: '6dc72931-868e-4888-803a-c1d588c6f908' // 公的身分証の確認プランのID
            //       },
            //       {
            //         id: '5da10ebc-7541-4212-941d-221d30a3d9fe', // セルフィーの確認プランのID
            //         document_source_plan_id: '6dc72931-868e-4888-803a-c1d588c6f908' // 確認書類の複製元の公的身分証の確認プランのID
            //       }
            //     ],
            //     openerSelector: '.four-two'
            //   });


            //   const trustDockPlanGa = new window.Trustdock({
            //     publicId: '722487e2-a411-42a4-b248-78494bae7c72',
            //     plans: [
            //       {
            //         id: '528e6386-153a-4319-adda-253eff357479' // 公的身分証の確認プランのID
            //       },
            //       {
            //         id: '5da10ebc-7541-4212-941d-221d30a3d9fe', // セルフィーの確認プランのID
            //       }
            //     ],
            //     openerSelector: '.five-one'
            //   });

            //   const trustDockPlanEG = new window.Trustdock({
            //     publicId: '99468ecb-a61f-4e73-8929-c074070894e8',
            //     plans: [
            //       {
            //         id: '6dc72931-868e-4888-803a-c1d588c6f908' // 公的身分証の確認プランのID
            //       },
            //       {
            //         id: '528e6386-153a-4319-adda-253eff357479', // セルフィーの確認プランのID
            //       }
            //     ],
            //     openerSelector: '.five-two'
            //   });

            //   const trustDockPlanAg = new window.Trustdock({
            //     publicId: '99468ecb-a61f-4e73-8929-c074070894e8',
            //     plans: [
            //       {
            //         id: '6dc72931-868e-4888-803a-c1d588c6f908' 
            //       },
            //       {
            //         id: '5da10ebc-7541-4212-941d-221d30a3d9fe', // セルフィーの確認プランのID
            //       }
            //     ],
            //     openerSelector: '.five-three'
            //   });

              const trustDockPlanAg = new window.Trustdock({
                publicId: 'd1f37378-3b83-4e6d-8e56-f47dad55ecf3',
                plans: [
                  {
                    id: '6dc72931-868e-4888-803a-c1d588c6f908' 
                  }
                ],
                openerSelector: '.chin-one'
              });
              const trustDockPlanAb = new window.Trustdock({
                publicId: 'd1f37378-3b83-4e6d-8e56-f47dad55ecf3',
                plans: [
                  {
                    id: '528e6386-153a-4319-adda-253eff357479' 
                  }
                ],
                openerSelector: '.chin-two'
              });
              const trustDockPlanAc = new window.Trustdock({
                publicId: 'd1f37378-3b83-4e6d-8e56-f47dad55ecf3',
                plans: [
                  {
                    id: '5da10ebc-7541-4212-941d-221d30a3d9fe' 
                  }
                ],
                openerSelector: '.chin-three'
              });
          });
        
      return () => {
        //  document.body.removeChild(script);
        }
      }, []);
    return (
        <div>
            {/* <button className="one-one-option">publicID : 722</button>
            <button className="two-one">身元確認プランの提出を行う 2</button>
            <button className="two-two">身元確認プランの提出を行う</button>
            <button className="three-one">身元確認プランの提出を行う</button>
            <button className="three-two">身元確認プランの提出を行う</button>
            <button className="four-one">身元確認プランの提出を行う</button>
            <button className="four-two">身元確認プランの提出を行う</button>
            <button className="five-one">身元確認プランの提出を行う</button>
            <button className="five-two">身元確認プランの提出を行う</button>
            <button className="five-three">身元確認プランの提出を行う</button> */}
            <button className="chin-one">身元確認プランの提出を行う</button>
            <button className="chin-two">身元確認プランの提出を行う</button>
            <button className="chin-three">身元確認プランの提出を行う</button>
        </div>
    )
}