import React from 'react';
import { useTranslation } from "react-i18next";
export default function ContractAttributes({attributes, attrType, removeAttr, changeAttrType, disableForm}) {
    const { t } = useTranslation();
    return (
        <ul>
            {
                attributes.map((attr,index) => (
                    <li key={index}>
                        <div className="item-left">
                            <button type="button"  className="btn yellow button-submit btn-orange">{attr.key}</button>
                            { (disableForm) ? "" : 
                            <button type="button" onClick={()=>removeAttr(index)} data-toggle="modal" data-target="#modal_remove_contract" className="btn button_remove"><img src="/images/delete.png" alt="thumbnail"/></button>
                            }
                        </div>
                        <div className="item-right">
                            <select disabled={disableForm} name="" className="form-control select2me" value={attr.type} onChange={(e) => changeAttrType(index,e.target.value)}>
                                {
                                  Object.entries(attrType).map(([key,value],i) => <option key={i} value={value}>{t("Contract.attr_type." + key)}</option>)  
                                }
                            </select>
                        </div>
                    </li>
                ))
                
            }
            
        </ul>
    )
}