import React from "react";
import { Link } from "react-router-dom";
import RouteConstant from "../../constants/RouteConstant";
import { useTranslation } from "react-i18next";
import Input from "../../shared/form/Input";
import AuthConstant from "../../constants/AuthConstant";

function LoginPassword(props) {
    const { t } = useTranslation();

    return (
        <div className="login">
            <div className="page-container">
                <div className="login_wrapper">
                    <div className="form login_form login">
                        <section className="login_content">
                            <form method="POST" onSubmit={props.handleSubmitPassword}>
                                <h1>{t("Login.title")}</h1>
                                <div className="login-note">
                                    <p className="text-center">{t("Login.enter_your_password")}</p>
                                    <p className="text-center">{props.email}</p>
                                </div>
                                <div className="item_form">
                                    <Input
                                        name="password"
                                        type="password"
                                        placeholder="******"
                                        className="form-control"
                                        onChangeValue={(e) => props.setPassword(e.target.value)}
                                        error={props.errors.join(", ")}
                                    />
                                    <span className="icon icon_pass" />
                                </div>
                                <div className="submit-form">
                                    <button
                                        className="btn green button-submit btn-back"
                                        type="button"
                                        onClick={() =>
                                            props.moveToStep(
                                                props.users.length > 1
                                                    ? AuthConstant.Login.Step.SelectingUserType
                                                    : AuthConstant.Login.Step.TypingEmail,
                                            )
                                        }
                                    >
                                        {t("button.btn_cancel_2")}
                                    </button>
                                    <button className="btn green button-submit" type="submit">
                                        {t("button.btn_next")}
                                    </button>
                                </div>
                                <div className="note-form mt-10">
                                    <p className="text-center">
                                        <Link to={RouteConstant.ForgotPassword.path}>
                                            {t("Login.you_forgot_password")}
                                        </Link>
                                    </p>
                                    <p
                                        className="text-center mt-10 link"
                                        onClick={() =>
                                            props.moveToStep(AuthConstant.Login.Step.TypingEmail)
                                        }
                                    >
                                        {t("Login.login_with_another_account")}
                                    </p>
                                </div>
                                <div className="clearfix" />
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPassword;
