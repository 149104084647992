import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import axiosAuth from "../../services/RequestService";
import ApiConstant from "../../constants/ApiConstant";
import Loading from "../../shared/form/Loading";
import { useTranslation } from "react-i18next";

function InitContract(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [listContract, setList] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(function () {
        async function loadListContract() {
            axiosAuth
                .get(ApiConstant.Contract.ListTemplate)
                .then((res) => {
                    if (res.data.api_result.result === 0) {
                        setList(res.data.datas.templates);
                    } else {
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        loadListContract();
    }, []);

    const redirectPageCreate = (res, contract_template = null) => {
        if (res.data.api_result.result === 0) {
            const url = res.data.datas.file_url;
            const fileStorage = res.data.datas;
            const fileStorageId = res.data.datas.file_id;

            let thumbnailData = [];
            if (contract_template){
                thumbnailData = contract_template.thumbnail_data;
            }else{
                thumbnailData = res.data.datas.thumbnail_data;
            }

            if (url) {
                history.push({
                    pathname: "/contract/create",
                    search: props.location.search,
                    state: { content: url, fileStorageId, contract_template, fileStorage, thumbnailData },
                });
            }
        }
    };

    const fileRef = useRef(null);
    const onButtonClick = () => {
        fileRef.current.click();
    };
    const uploadFile = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();

        formData.append("file", file);
        setLoading(true);
        axiosAuth
            .post("/contract/upload", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                if (res.data.api_result.result === 0) redirectPageCreate(res);
                else {
                    alert(res.data.api_result.message);
                    setLoading(false);
                }
            });
    };

    const selectTemplate = (contract_template) => {
        const contract_template_id = contract_template.contract_template_id
        setLoading(true);
        axiosAuth.post("/contract/upload", { contract_template_id }).then((res) => {
            redirectPageCreate(res, contract_template);
        });
    };

    return (
        <div>
            {isLoading ? <Loading /> : ""}
            <div className="page-container">
                <div className="page-content-wrapper">
                    <div className="page-content contract_creat">
                        <div className="container">
                            <div className="main-body-content">
                                <div className="contract_creat_title">
                                    <h5 className="mt-30 bold">テンプレートから作成</h5>
                                </div>
                                <div className="contract_list_creat">
                                    <div className="row">
                                        {listContract.map((item) => (
                                            <div
                                                className="col-md-4 item_contract_creat"
                                                key={item.contract_template_id}
                                            >
                                                <div className="md-content">
                                                    <div
                                                        className="item_name_contract"
                                                        onClick={() =>
                                                            selectTemplate(item)
                                                        }
                                                        style={{
                                                            backgroundImage:
                                                                "url(" + item.thumbnail + ")",
                                                            cursor: "pointer",
                                                            backgroundSize: "cover"
                                                        }}
                                                    >
                                                        <span>{item.template_name}</span>
                                                    </div>
                                                    <div className="item_ct_contract">
                                                        <p>
                                                            【<b>{t("Contract.init.signer")}</b> 】自分だけ
                                                        </p>
                                                        <p>
                                                            【<b>{t("Contract.init.attribute_input")}</b>】{" "}
                                                            { item.attribute.length && item.attribute.map((attribute, index) => {
                                                                return (
                                                                    <span key={index}>{ attribute.key } {attribute.key && index < item.attribute.length - 1 ? ', ' : ''}</span>
                                                                )
                                                            }) }
                                                        </p>
                                                    </div>
                                                    <div className="item_ct_link">
                                                        <a href={item.template_url}>
                                                            {t("Contract.init.description", {
                                                                id: item.contract_template_id,
                                                            })}{" "}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="md-full mt-30">
                                    <h5 className="bold">オリジナル契約書</h5>
                                </div>
                                <div className="call_button">
                                    <input
                                        ref={fileRef}
                                        type="file"
                                        hidden
                                        accept="application/pdf"
                                        onChange={(e) => uploadFile(e)}
                                    />
                                    <button
                                        className="btn green button-submit"
                                        onClick={onButtonClick}
                                    >
                                        PDFファイルを選択する
                                    </button>
                                    <button
                                        className="btn green button-submit"
                                        onClick={onButtonClick}
                                    >
                                        文書から作成する
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default InitContract;
