import React, { useState, useEffect } from "react";
import _ from "lodash";
import LayoutFormSupport from "./LayoutFormSupport";
import LayoutConfirm from "./LayoutConfirm";
import LayoutSuccess from "./LayoutSuccess";
import SupportConstants from "../../constants/SupportConstants";
import { useTranslation } from "react-i18next";
import { api } from "../../services/api";
import Loading from "../../shared/form/Loading";
import ApiConstant from "../../constants/ApiConstant";

function Support() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [data, setData] = useState({
        title: '',
        type_question: '',
        id_contract: '',
        content: ''
    });
    const [dataErrors, setErrors] = useState({});

    const handleChange = (e, required) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });

        const txtField =    e.target.name === "title" ? t("Support.txt_title") : 
                            e.target.name === "id_contract" ? t("Support.txt_id_contract") : 
                            e.target.name === "content" ? t("Support.txt_content") : "";

        setErrors({
            ...dataErrors,
            [e.target.name]: !e.target.value.trim() ?
            (
                e.target.name === "type_question" ? t("Support.msg_type_question_required") : t("validate.required", {attr: txtField})
            )
            : 
            ""
        });
    };

    useEffect(() => {
        if(parseInt(data.type_question) !== parseInt(SupportConstants.Type.Contract)){
            setData({
                ...data,
                id_contract: "",
            })
            setErrors({
                ...dataErrors,
                id_contract: "",
            });
        }
    }, [data.type_question])

    const handleSubmitForm = (event) => {
        event.preventDefault();
        const errors = {
            title: !data.title.trim() ? t("validate.required", {attr: t("Support.txt_title")}) : "",
            type_question: !data.type_question ? t("Support.msg_type_question_required") : "",
            id_contract: !data.id_contract.trim() && parseInt(data.type_question) === parseInt(SupportConstants.Type.Contract) ? t("validate.required", {attr: t("Support.txt_id_contract")}) : "",
            content: !data.content.trim() ? t("validate.required", {attr: t("Support.txt_content")}) : "",
        }

        if (_.size(_.filter(errors, (error) => { return error;}))) {
            setErrors(errors);
        } else {
            setStep(SupportConstants.Status.Confirm)
            setErrors({});
        }
    }

    const handleChangeStep = (e, step, oldStep) => {
        e.preventDefault();
        setStep(step);
        if(step === SupportConstants.Status.Handle && oldStep === SupportConstants.Status.Success){
            setData({
                title: '',
                type_question: '',
                id_contract: '',
                content: ''
            })
        }
    }

    const handleSend = async (e) => {
        e.preventDefault();
        const dataAPI = data
        dataAPI.type_question_text = t("Support.type_question_"+data.type_question)

        setIsLoading(true);
        const result = await api.post(ApiConstant.Support.SendMail, dataAPI);
        if (result) setIsLoading(false);

        if (result.api_result.result === ApiConstant.Result.Success) {
            setStep(SupportConstants.Status.Success);
        } else {
            setErrors({
                ...dataErrors,
                error_api: result.api_result.message,
            });
        }
    }

    if(step === SupportConstants.Status.Handle){
        return (<>
            <LayoutFormSupport
                data={data}
                handleChange={handleChange}
                dataErrors={dataErrors}
                handleSubmitForm={handleSubmitForm}
            />
            </>);

    }else if(step === SupportConstants.Status.Confirm){
        return (<>
            <LayoutConfirm
                data={data}
                handleChangeStep={handleChangeStep}
                handleSend={handleSend}
                dataErrors={dataErrors}
            />
            {isLoading ? <Loading /> : ""}
            </>);
    }
    return (<>
            <LayoutSuccess
                handleChangeStep={handleChangeStep}
            />
            </>);
}

export default Support;