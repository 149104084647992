import React from "react";
import { useTranslation } from "react-i18next";
import RouteConstant from "../../constants/RouteConstant";
import UserConstant from "../../constants/UserConstant";
import { redirectToAppSchemeOrWebPath } from "../../helpers/MobileAppHelper";

export default function ConfirmContractMustVerified(props) {
    const { t } = useTranslation();

    const handleRedirectToVerifiedUser = () => {
        redirectToAppSchemeOrWebPath(
            props.qParams,
            parseInt(localStorage.getItem("user_type")) === UserConstant.UserType.Company
                ? RouteConstant.Profile.UserCompany.path
                : RouteConstant.Profile.User.path,
        );
    };

    return (
        <div className="page-content contract_suport">
            <div className="container">
                <div className="main-body-content">
                    <div className="contract_suport_ct mrt-100">
                        <h3 className="note-form text-center">
                            {t("Contract.msg_confirm_contract_must_verified")}
                        </h3>
                    </div>
                    <div className="call_button">
                        <button
                            className="btn green button-submit mrt-30"
                            onClick={handleRedirectToVerifiedUser}
                        >
                            {t("button.btn_go_verify")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
