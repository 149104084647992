import React, { useState, useEffect } from "react";
import ApiConstant from "../../constants/ApiConstant";
import _ from "lodash";
import Input from "../../shared/form/Input";
import { useTranslation } from "react-i18next";
import { api } from "../../services/api";
import RouteConstant from "../../constants/RouteConstant";
import PdfViewer from "../../components/contract/update/PdfViewer";
import Loading from "../../shared/form/Loading";
import { Link } from "react-router-dom";
import ContractAssignmentConstant from "../../constants/ContractAssignmentConstant";
import ConfirmContractPublicCode from "./ConfirmContractPublicCode";
import Cookies from "universal-cookie";

export default function ConfirmContractPublic(props) {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const publicKey = props.match.params.publicKey;
    const publicCookieKey = "public_contract_" + publicKey;

    const [processing, setProcessing] = useState(true);
    const [loading, setLoading] = useState(false);
    const [passCode, setPassCode] = useState(cookies.get(publicCookieKey) || "");
    const [isVerifiedPassCode, setIsVerifiedPassCode] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        document.title = t("Contract.document_title_confirm_contract");
    });

    const [contract, setContract] = useState({
        id: "",
        title: "",
        content: "",
        shareToken: "",
        attributes: [],
    });

    const handleRenderAttributes = () => {
        let html = "";

        if (!_.isEmpty(contract.attributes)) {
            html = contract.attributes.map((attr, attrKey) => {
                const isTypeSignature =
                    attr.type === ContractAssignmentConstant.AttributeType.Signature;

                return (
                    <div key={attrKey} className="form-group row item-company">
                        <div className="col-sm-3 text-right">
                            <label className="btn yellow button-submit btn-orange">
                                {attr.key}
                            </label>
                        </div>
                        <div className="col-sm-5">
                            <div className="item_form">
                                {isTypeSignature ? (
                                    <button
                                        className="btn btn-sm btn-primary"
                                        type="button"
                                        disabled={1}
                                    >
                                        {t("button.btn_draw_signing")}
                                    </button>
                                ) : (
                                    <Input type="text" className="form-control" disabled={1} />
                                )}
                            </div>
                        </div>
                    </div>
                );
            });
        }

        return html;
    };

    async function getConfirmContract(passCode) {
        setLoading(true);
        const result = await api.get(ApiConstant.Contract.Public, {
            public_key: publicKey,
            pass_code: passCode,
        });

        if (result.api_result.result === ApiConstant.Result.Success) {
            if (!_.isEmpty(result.datas.shareToken)) {
                window.location.href = RouteConstant.Contract.Confirm.path.replace(
                    ":shareToken",
                    result.datas.shareToken,
                );
            }

            cookies.set(publicCookieKey, passCode, { maxAge: 3600 });
            setIsVerifiedPassCode(true);
            setContract(result.datas);
        } else {
            if (passCode) {
                setError(t("Contract.msg_wrong_pass_code"));
            }

            cookies.remove(publicCookieKey);
            setIsVerifiedPassCode(false);
        }

        setProcessing(false);
        setLoading(false);
    }

    useEffect(() => {
        async function checkExistPublicContract() {
            setLoading(true);
            setProcessing(true);
            const result = await api.get(ApiConstant.Contract.PublicExist, {
                public_key: publicKey,
            });

            if (result.api_result.result === ApiConstant.Result.Success) {
                if (!result.datas.passCode) {
                    setIsVerifiedPassCode(true);
                    getConfirmContract(passCode);
                }
            }

            setProcessing(false);
            setLoading(false);
        }

        if (passCode) {
            getConfirmContract(passCode);
        } else {
            checkExistPublicContract();
        }
    }, [publicKey]);

    const handleChangePassCode = (event) => {
        setPassCode(event.target.value);
    };

    const handleSubmitPassCode = () => {
        const passCodeError = passCode
            ? null
            : t("validate.required", { attr: t("Contract.pass_code") });
        setError(passCodeError);

        if (passCodeError) {
            return false;
        }

        getConfirmContract(passCode);
    };

    if (!isVerifiedPassCode) {
        return processing ? null : (
            <>
                {loading ? <Loading /> : null}
                <ConfirmContractPublicCode
                    error={error}
                    handleChangePassCode={handleChangePassCode}
                    handleSubmitPassCode={handleSubmitPassCode}
                />
            </>
        );
    }

    return processing ? null : (
        <>
            {loading ? <Loading /> : null}
            <div className="clearfix" />
            <div className="page-container">
                <div className="page-content-wrapper">
                    <div className="page-content contract_edit contract_creat">
                        <div className="container">
                            <div className="main-body-content">
                                <div className="content-enter-name-contact">
                                    <div className="contract_setting_title title-header">
                                        <h5>{contract.title}</h5>
                                    </div>
                                    <div className="contract_list_setting">
                                        <PdfViewer url={contract.content} />
                                    </div>
                                </div>
                                <div className="content-input-item">
                                    <div className="contract_setting_title title-header">
                                        <h5>{t("Contract.msg_please_input_attributes")}</h5>
                                    </div>
                                    <div className="contract_list-input">
                                        <div className="form-edit">
                                            <form>{handleRenderAttributes()}</form>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-form">
                                    <Link to={RouteConstant.Contract.List.path}>
                                        <button className="btn blue button-submit" type="button">
                                            {t("button.btn_back")}
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
