export const AuthConstant = {
    Login: {
        Step: {
            TypingEmail: 1,
            SelectingUserType: 2,
            TypingPassword: 3,
        },
    },
};

export default AuthConstant;
