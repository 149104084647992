import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MenuManager from "./MenuManager";

export default function Company() {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t("company.title");
    }, [t]);

    return (
        <div>
            <div className="clearfix" />
            <div className="page-container">
                <div className="page-content-wrapper">
                    <div className="page-content contract_group">
                        <div className="container">
                            <div className="main-body-content">
                                <div className="contract_suport_title border-bottom mrb-10">
                                    <h3 className="mt-30">{t("company.company_name")}</h3>
                                    <h3 className="mt-30">{t("company.manager_payment")}</h3>
                                </div>
                                <div className="contract_group_ct">
                                    <div className="row">
                                        <MenuManager />
                                        <div className="col-sm-9 com-xs-9">
                                            <div className="md-full mt-20 contract_form_search_group">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3 com-xs-3" />
                                        <div className="col-sm-9 com-xs-9">
                                            <div className="list-group md-full mt-10">
                                                <div className="list-group-content">
                                                    No content
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
