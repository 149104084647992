import React from 'react'

function ConditionalRender(props)
{
    const {condition, children} = props
    if (condition) {
        return children
    } else {
        return ''
    }
}

export default React.memo(ConditionalRender)
