import React, { useState, useEffect } from "react";
import LayoutForgotPassword from "./layoutForgotPassword";
import LayoutForgotSuccess from "./layoutForgotSuccess";
import ApiConstant from "../../../constants/ApiConstant";
import { useTranslation } from "react-i18next";
import { api } from "../../../services/api";
import Loading from "../../../shared/form/Loading";
import { ForgotPasswordConstant } from "../../../constants/ForgotPasswordConstants";
import ForgotPasswordRole from "./forgotPasswordRole";
import UserConstant from "../../../constants/UserConstant";

function ForgotPassword() {
    const { t } = useTranslation();
    const [step, setStep] = useState(ForgotPasswordConstant.Step.Mail);
    const [users, setUsers] = useState([]);
    const [dataForgotPassword, setDataForgotPassword] = useState({
        email: "",
        user_type: '',
        errors: [],
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setDataForgotPassword({
            ...dataForgotPassword,
            user_type: step === ForgotPasswordConstant.Step.Role ? UserConstant.UserType.Personal : ""
        });
    },[step]);

    useEffect(() => {
        document.title = t("forgot_password.title_forgot_password");
    });

    const validateForm = () => {
        const errors = [];

        if (!dataForgotPassword.email) {
            errors.push(t("validate.email.msg_required"));
        } else if (dataForgotPassword.email.length > 256) {
            errors.push(t("validate.email.msg_max_length"));
        } else if (!/\S+@\S+\.\S+/.test(dataForgotPassword.email)) {
            errors.push(t("validate.email.msg_type_email"));
        } else if (step === ForgotPasswordConstant.Step.Role && users.length > 1 && !dataForgotPassword.user_type){
            errors.push(t("validate.msg_user_type_required"));
        }

        return errors;
    };

    const handleBackLayoutEmail = (event) => {
        event.preventDefault();
        setStep(ForgotPasswordConstant.Step.Mail);
        setDataForgotPassword({
            email: "",
            user_type: '',
            errors: []
        })
    }

    const handleSubmitFormForgotPassword = async (e) => {
        e.preventDefault();
        const errors = validateForm();

        if (errors.length) {
            setDataForgotPassword({
                ...dataForgotPassword,
                errors: errors,
            });
        } else {
            setDataForgotPassword({
                ...dataForgotPassword,
                errors: []
            })
            setIsLoading(true);
            const result = await api.post(ApiConstant.Auth.ForgotPassword.Forgot, {
                email: dataForgotPassword.email,
                user_type: dataForgotPassword.user_type,
            });

            if (result) setIsLoading(false);
            if (result.api_result.result === ApiConstant.Result.Success) {
                setUsers(result.datas);
                if (result.datas.length > 1) {
                    setStep(ForgotPasswordConstant.Step.Role)
                }else{
                    setStep(ForgotPasswordConstant.Step.Success)
                }
            } else {
                setDataForgotPassword({
                    ...dataForgotPassword,
                    errors: [result.api_result.message],
                });
            }
        }
    };

    if(step === ForgotPasswordConstant.Step.Role){
        return (
            <>
                <ForgotPasswordRole
                        dataForgotPassword={dataForgotPassword}
                        setDataForgotPassword={setDataForgotPassword}
                        handleSubmitFormForgotPassword={handleSubmitFormForgotPassword}
                        users={users}
                        email={dataForgotPassword.email}
                        handleBackLayoutEmail={handleBackLayoutEmail}
                    />
                {isLoading ? <Loading /> : ""}
            </>
        );
    }else if(step === ForgotPasswordConstant.Step.Success){
        return (<LayoutForgotSuccess />);
    }

    return (
        <>
            <LayoutForgotPassword
                    dataForgotPassword={dataForgotPassword}
                    setDataForgotPassword={setDataForgotPassword}
                    handleSubmitFormForgotPassword={handleSubmitFormForgotPassword}
                />
            {isLoading ? <Loading /> : ""}
        </>
    );
}

export default ForgotPassword;
