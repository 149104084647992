import React, {useState} from 'react';
import ApiConstant from "../../constants/ApiConstant";
import {api} from "../../services/api";
// import {useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ConfirmModal from '../../shared/form/ConfirmModal';
import { toast } from "react-toastify";
function ButtonConfirmRequest(props) {
    const dataGroup = props.dataGroup
    // const params = useParams();
    const [showConfirmRequest, setShowConfirmRequest] = useState(false);
    const { t } = useTranslation();
    const handleCloseModal = () => setShowConfirmRequest(false);
    
    const cancelJoinGroup = async (e, group) => {
        if (window.confirm(t("group.confirm_cancel_join_group", { attr: group.group_name }))) {
            let pathDeleteUserInGroup = ApiConstant.Group.User.Cancel
            const cancelJoin = await api.post(pathDeleteUserInGroup, {
                user_group_id: group.user_group_id,
                user_id: group.user_id
            });
            if (cancelJoin.api_result.result === ApiConstant.Result.Success) {
                toast.success(t('group.cancel_join_success', { attr: group.group_name }))
            } else {
                toast.error(t('group.cancel_join_fail', { attr: group.group_name }))
            }
            return cancelJoin
        }
    }

    const confirmJoinGroup = async (e, group) => {
        if (window.confirm(t("group.confirm_join_group", { attr: group.group_name }))) {
            let pathConfirmUserInGroup = ApiConstant.Group.User.Confirm
            const confirmJoin = await api.post(pathConfirmUserInGroup, {
                user_group_id: group.user_group_id,
                user_id: group.user_id
            });
            if (confirmJoin.api_result.result === ApiConstant.Result.Success) {
                toast.success(t('group.confirm_join_success', { attr: group.group_name }))
            } else {
                toast.error(t('group.confirm_join_fail', { attr: group.group_name }))
            }
            return confirmJoin
        }
    }

    const renderModalUser = () => {
        return (
            <div className="list-group">
                <div className="list-group-content">
                    <ul>
                    {dataGroup.user_in_group && dataGroup.user_in_group.confirm ?
                        dataGroup.user_in_group.confirm.map((group, index) => {
                            return (
                                <li key={index}>
                                    <img alt="" src="../../assets/images/avatar-talk.jpg" className="avatar-group" />
                                    <p className="title">{group.user_name}</p>
                                    <p className="title">{group.email}</p>
                                    <button type="button" onClick={(e) => confirmJoinGroup(e, group, true)} className="btn btn-primary">{t("group.allow_join_group")}</button>
                                    <button type="button" onClick={(e) => cancelJoinGroup(e, group, true)} className="btn btn-danger cancel">{t("group.cancel_join_group")}</button>
                                </li>
                            )
                        })
                        :""
                    }
                    </ul>
                </div>
            </div>
        )
    }
    const renderConfirmUser = () => {
        setShowConfirmRequest(true);
    }
    const renderButton = () => {
        if (dataGroup.user_in_group && dataGroup.user_in_group.confirm) {
            return (
                <button onClick={renderConfirmUser} className="font-16">{t("group.waiting_for_approval")} ({dataGroup.user_in_group.confirm.length}{t("group.people")})</button>
            )
        }
    }

    return (
        <>
            {renderButton()}
            <ConfirmModal 
                title={t("group.add_member")} 
                showModal={showConfirmRequest}
                onClose={handleCloseModal}
                body={renderModalUser()}
                cancel={t("group.cancel")}
                confirm={t("group.save")}
                className="contract_group modal-add-group"
            />
        </>
    )
}

export default ButtonConfirmRequest