import React from "react";
import { useTranslation } from "react-i18next";

function Page403() {
    const { t } = useTranslation();

    return <p className="page-error page-403">{t("PageError.403.content")}</p>;
}

export default Page403;
