import React, {useState} from 'react';
import ApiConstant from "../../constants/ApiConstant";
import {api} from "../../services/api";
import { useTranslation } from "react-i18next";
import ConfirmModal from '../../shared/form/ConfirmModal';
import { toast } from "react-toastify";

function RenderDetailUser(props) {
    const [showDetailUser, setShowDetailUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const company = props.company
    const index = props.index
    const handleShowDetailUser = () => {
        setShowDetailUser(true);
    }
    const { t } = useTranslation();
    const handleCloseDetailUser = () => setShowDetailUser(false);

    function renderModalDetailUser () {
        const company = props.company
        return (
            <div className="md-full mt-10">
                <form method="POST" target="hidden_iframe">
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("group.user_id")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={'00'+company.user_id} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("group.user_name")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={company.name} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("group.full_name_kana")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={company.name_kana} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("group.email")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={company.email} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("group.mobile")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={company.mobile} className="form-control" />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-3 text-right line-42">{t("group.address")}</div>
                    <div className="col-sm-9">
                        <input type="text" defaultValue={company.address} className="form-control" />
                    </div>
                    </div>
                </form>
            </div>
        )
    }

    const deleteMemberInCompany = async (e, userId, userName) => {
        let pathDeleteUser = ApiConstant.Company.DeleteUserInCompany
        
        if (window.confirm(t("company.confirm_delete_user"))) {
            const resultUser = await api.post(pathDeleteUser, {
                user_id: userId,
            });
            if (resultUser.api_result.result === ApiConstant.Result.Success) {
                setLoading(false);
                toast.success(t("company.remove_user_success", { attr:userName }))
            } else {
                setLoading(false);
                toast.error(t('company.remove_user_fail', { attr:userName }))
            }
            props.getDataCompany()
            return resultUser
        }
    }

    const renderDetail = () => {
        if(localStorage.getItem('user_email') !== company.email) {
            return (
                <li key={index} className="company_list">
                    <div onClick={handleShowDetailUser}>
                        <img alt="" src="../../assets/images/avatar-talk.jpg" className="avatar-group"/>
                        <p className="title">{company.last_name + company.first_name}</p>
                        <p>{company.email}</p>
                    </div>
                    <div className="select-group">
                        <button onClick={(e)=>deleteMemberInCompany(e, company.user_id, company.user_name)}><img alt="" src="../../assets/images/dustbin_black.png" className="avatar-group"/></button>
                    </div>
                </li>
            )
        }
    }
    return (
        <>
            {renderDetail()}
            <ConfirmModal
                title={t("group.add_member")} 
                showModal={showDetailUser}
                onClose={handleCloseDetailUser}
                body={renderModalDetailUser()}
                cancel={t("group.cancel")}
                className=""
            />
        </>
    );
}

export default RenderDetailUser;
