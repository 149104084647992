import React, { useContext, useState, useEffect } from "react";
import Context from "../../../contexts/Context";
import { useTranslation } from "react-i18next";
import ContractAssignmentConstant from "../../../constants/ContractAssignmentConstant";

export default function AssignmentsBlock(props) {
    const { t } = useTranslation();
    const [
        assignments,
        setAssignments,
        errorInputClass,
        disableForm,
        errors,
        validateData,
        existAssign
    ] = useContext(Context);

    const changeAssign = (index, type) => (e) => {
        if (type === "user_group_id") {
            if (e.target.value) {
                assignments[index].assigned_with_email = "";
                assignments[index].assignment_full_name = "";
            }
        }
        assignments[index][type] = e.target.value;
        setAssignments([...assignments]);
        validateData();
    };
    const removeAssign = (index) => {
        if (assignments.length <= 1) return false;
        assignments.splice(index, 1);
        setAssignments([...assignments]);
    };
    return assignments.map((assign, index) => {
        let disabledByExist = false;
        if(index < existAssign)
            disabledByExist = true;
        return (
            <div key={index} className="md-full group-company">
                <div className="form-group row item-company">
                    <label className="col-sm-2 col-form-label text-left">
                        {t("Contract.create.assignmentGroup")}
                    </label>
                    <div className="col-sm-8">
                        <div className="item_form">
                            <select
                                disabled={disabledByExist}
                                className={
                                    "form-control select2me " +
                                    (errors["assignment_" + index + "_group"]
                                        ? errorInputClass
                                        : "")
                                }
                                value={assign.user_group_id}
                                onChange={changeAssign(index, "user_group_id")}
                            >
                                <option value="0">{t("Contract.create.noGroup")}</option>
                                {props.groups.map((group) => (
                                    <option value={group.id} key={group.id}>
                                        {group.name}
                                    </option>
                                ))}
                            </select>
                            <span className="error">
                                {errors["assignment_" + index + "_group"]}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="form-group row item-company">
                    <label className="col-sm-2 col-form-label text-left">
                        {t("Contract.create.assignmentFullName")}
                    </label>
                    <div className="col-sm-8">
                        <div className="item_form">
                            <input
                                onChange={changeAssign(index, "assignment_full_name")}
                                disabled={parseInt(assign.user_group_id) !== 0 || disabledByExist}
                                value={assign.assignment_full_name ?? ""}
                                type="text"
                                placeholder={t("Contract.create.assignmentFullNameSample")}
                                className={
                                    "form-control " +
                                    (errors["assignment_" + index + "_fullname"]
                                        ? errorInputClass
                                        : "")
                                }
                            />
                            <span className="error">
                                {errors["assignment_" + index + "_fullname"]}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="form-group row item-company">
                    <label className="col-sm-2 col-form-label text-left">
                        {t("Contract.create.assignmentMail")}
                    </label>
                    <div className="col-sm-8">
                        <div className="item_form">
                            <input
                                disabled={parseInt(assign.user_group_id) !== 0 || disabledByExist}
                                onChange={changeAssign(index, "assigned_with_email")}
                                value={assign.assigned_with_email ?? ""}
                                type="text"
                                placeholder={t("Contract.create.assignmentMailSample")}
                                className={
                                    "form-control " +
                                    (errors["assignment_" + index + "_email"]
                                        ? errorInputClass
                                        : "")
                                }
                            />
                            <span className="error">
                                {errors["assignment_" + index + "_email"]}
                            </span>
                            <span className="error">
                                {errors["assignment_" + index + "_email_empty"]}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="form-group row item-company">
                    <label className="col-sm-2 col-form-label text-left">
                        {t("Contract.create.role")}
                    </label>
                    <div className="col-sm-8">
                        <div className="item_form">
                            <select
                                disabled={disableForm}
                                value={assign.assignment_type}
                                onChange={changeAssign(index, "assignment_type")}
                                name="options2"
                                className={
                                    "form-control select2me" +
                                    (errors["assignment_" + index + "_type"] ? errorInputClass : "")
                                }
                            >
                                {/* <option value="" disabled="disabled" hidden="hidden">氏名を入力してください。</option> */}
                                <option value="">{t("Contract.create.roleViewSign")}</option>
                                <option value={ContractAssignmentConstant.Type.View}>
                                    {t("Contract.create.roleView")}
                                </option>
                                <option value={ContractAssignmentConstant.Type.Sign}>
                                    {t("Contract.create.roleSign")}
                                </option>
                            </select>
                            <span className="error">{errors["assignment_" + index + "_type"]}</span>
                        </div>
                    </div>
                </div>
                {
                    (disabledByExist || assignments.length < 2) ? '' : 
                    <span className="btn remove_contract_sub" onClick={() => removeAssign(index)}>
                        <img src="/images/minus.png" alt="thumbnail" />
                    </span>
                }
                
            </div>
        );
    });
}
