export const SupportConstants = {
    Status : {
        Handle : 0,
        Confirm : 1,
        Success : 2,
    },
    Type : {
        Password : 1,
        Plan : 2,
        Contract : 3,
        Other : 4,
    },
}

export default SupportConstants;