import React, {useState, useEffect, useRef} from "react";
import ApiConstant from "../../constants/ApiConstant";
import _ from "lodash";
import "../../public/assets/css/contract/list.css";
import { Link } from "react-router-dom";
import axiosAuth from "../../services/RequestService";
import Pagination from "rc-pagination";
import "../../public/assets/css/common.css";
import ContractConstant from "../../constants/ContractConstant";
import { useTranslation } from "react-i18next";
import RouteConstant from "../../constants/RouteConstant";
import moment from "moment-timezone";

export default function ListContract() {
    /*
    const [data, setData] = useState({
        api_result: {},
        datas: {
            data: [],
        }
    });
    */
    const [dataContract, setDataContract] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [formData, setFormData] = useState({});
    const [dataTemplates, setDataTemplates] = useState({
        api_result: {},
        datas: {
            templates: [],
            file_before_sign_file: {
                thumbnail_data: []
            }
        },
    });
    const [modeView, setModeView] = useState(false);
    const { t } = useTranslation();
    const [allSelected, setAllSelected] = useState(false);
    const [dataPaginate, setDataPaginate] = useState({});
    const thumbnailRef = useRef(null);

    const getListContract = (formData) => {
        axiosAuth
            .post(ApiConstant.Contract.List, formData)
            .then((response) => {
                // need check res status error
                let datas = response.data.datas.data;
                setDataPaginate(response.data.datas);
                setDataContract(datas);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getListTemplate = (formData) => {
        axiosAuth
            .get(ApiConstant.Template.List)
            .then((response) => {
                setDataTemplates(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        document.title = t("Title.contractList");
    }, [t]);

    useEffect(() => {
        getListContract(formData);
    }, [formData]);

    useEffect(() => {
        getListTemplate();
    }, []);

    const handlePaginate = (page) => {
        setFormData((prevState) => ({
            ...prevState,
            page: page,
        }));
        setAllSelected(false);
    };

    const handleSearchByKeyword = (e) => {
        e.preventDefault();
        setFormData((prevState) => ({
            ...prevState,
            keyword: keyword,
            page: 1,
        }));
        setAllSelected(false);
    };

    const handleSearchByStatus = (status) => {
        setFormData((prevState) => ({
            ...prevState,
            status: status,
            page: 1,
        }));
        setAllSelected(false);
    };

    const formatDate = (date) => {
        return moment(date).tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm:ss");
    };

    const checkboxAll = (e) => {
        setAllSelected(!allSelected);
        let newDataContract = dataContract
        newDataContract.forEach((contract, index) => {
            newDataContract[index].checked = e.target.checked;
        });
        setDataContract([...newDataContract]);
    };

    const handleCheckbox = (e, index) => {
        let newDataContract = dataContract
        newDataContract[index].checked = !dataContract[index].checked;
        setDataContract([...newDataContract]);
    };

    const handleDownloadFile = (url, fileName) => {
        // axios({
        //     url: url,
        //     method: 'GET',
        //     responseType: 'blob',
        // }).then((response) => {
        //     const url = window.URL.createObjectURL(new Blob([response.data]));
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute('download', fileName + '.pdf'); //or any other extension
        //     document.body.appendChild(link);
        //     link.click();
        // });
        window.open(url, '_blank');
    }

    const openBlankPageUrl = (url) => {
        window.open(url, '_blank');
    }

    return (
        <div>
            <div className="clearfix" />
            <div className="page-container">
                <div className="page-content-wrapper">
                    <div className="page-content list_contract">
                        <div className="container">
                            <div className="main-body-content member-page">
                                
                                    {!_.isEmpty(dataTemplates.datas) &&
                                        dataTemplates.datas.templates
                                            .slice(0, 2)
                                            .map((template, index) => {
                                                return (
                                                    <div key={index} className="list_contract_top">
                                                        <div className="clearfix">
                                                            <div className="contract-note">
                                                                <p>{template.template_name}</p>
                                                            </div>
                                                            <div className="contract-setting">
                                                                <ul>
                                                                    <li>
                                                                        <div style={{cursor: "pointer"}} onClick={event => openBlankPageUrl(template.template_url)}>
                                                                            <img
                                                                                alt=""
                                                                                src="/images/pdf.png"
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div style={{cursor: "pointer"}} onClick={event => openBlankPageUrl(template.document_url)}>
                                                                            <img
                                                                                alt=""
                                                                                src="/images/presentation.png"
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div style={{cursor: "pointer"}} onClick={event => openBlankPageUrl(template.image_url)}>
                                                                            <img
                                                                                alt=""
                                                                                src="/images/book.png"
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="">
                                                                            <img
                                                                                alt=""
                                                                                src="/images/qr.png"
                                                                            />
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="">
                                                                            <img
                                                                                alt=""
                                                                                src="/images/smartphone.png"
                                                                            />
                                                                        </Link>
                                                                    </li>
                                                                    {/*<li>*/}
                                                                    {/*    <Link to="">*/}
                                                                    {/*        <img*/}
                                                                    {/*            alt=""*/}
                                                                    {/*            src="/images/password_ct.png"*/}
                                                                    {/*        />*/}
                                                                    {/*    </Link>*/}
                                                                    {/*</li>*/}
                                                                    <li>
                                                                        <Link to="">
                                                                            <img
                                                                                alt=""
                                                                                src="/images/settings_ct.png"
                                                                            />
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                
                                <div className="contract_search full mrb-20">
                                    <div className="row">
                                        <div className="col-md-4 icon">
                                            <div className="setting_contract_button">
                                                <ul>
                                                    <li>
                                                        <Link
                                                            to={
                                                                RouteConstant.Contract.InitContract.path
                                                            }
                                                        >
                                                            <img alt="" src="/images/notebook.png" />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="">
                                                            <img alt="" src="/images/antenna.png" />
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-4 md">
                                            <select
                                                name="selecttext"
                                                title="全ての契約"
                                                className="form-control selectpickermember text-left"
                                                onChange={(event) =>
                                                    handleSearchByStatus(event.target.value)
                                                }
                                            >
                                                <option value="">
                                                    {t("Contract.status_default")}
                                                </option>
                                                <option
                                                    value={ContractConstant.RequestStatus.NeedToHandle}
                                                >
                                                    {t("Contract.status", {
                                                        context: ContractConstant.RequestStatus.NeedToHandle.toString(),
                                                    })}
                                                </option>
                                                <option
                                                    value={ContractConstant.RequestStatus.RequestSign}
                                                >
                                                    {t("Contract.status", {
                                                        context: ContractConstant.RequestStatus.RequestSign.toString(),
                                                    })}
                                                </option>
                                                <option
                                                    value={ContractConstant.RequestStatus.CompleteSign}
                                                >
                                                    {t("Contract.status", {
                                                        context: ContractConstant.RequestStatus.CompleteSign.toString(),
                                                    })}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="col-md-4 md pull-right">
                                            <div className="input-search">
                                                <form
                                                    action=""
                                                    method="POST"
                                                    onSubmit={handleSearchByKeyword}
                                                >
                                                    <input
                                                        onChange={(event) =>
                                                            setKeyword(event.target.value)
                                                        }
                                                        type="text"
                                                        placeholder="テキスト／ユーザーなどの検索"
                                                        className="form-control"
                                                    />
                                                    <img onClick={handleSearchByKeyword} className="icon_search" alt="" src="/images/search_input.png" />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="contract_content">
                                    <div className={
                                            modeView
                                                ? "list_contract_grid_tool contract_content_tool"
                                                : "contract_content_tool"
                                        }
                                    >
                                        <div className="grid-checkall">
                                            <input
                                                id="check-all-list"
                                                type="checkbox"
                                                value={allSelected}
                                                name="all"
                                                onChange={checkboxAll}
                                                checked={!!allSelected}
                                            />
                                            {/*<span>すべてチェック</span>*/}
                                        </div>
                                        <div className="setting_contract_button float-right">
                                            <ul>
                                                <li>
                                                    <Link to="">
                                                        <img alt="" src="/images/folder.png" />
                                                    </Link>
                                                </li>
                                                <li className={
                                                        modeView
                                                            ? "click-list click-grid"
                                                            : "click-grid"
                                                    }
                                                >
                                                    <button
                                                        className="btn_mode_view"
                                                        onClick={(event) => setModeView(!modeView)}
                                                    >
                                                        <img className="list-icon" alt="" src="/images/menu.png" />
                                                        <img className="grid-icon" alt="" src="/images/icon-grig.png" />
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            modeView
                                                ? "list_contract_grid contract_content_main"
                                                : "contract_content_main"
                                        }
                                    >
                                        <ul className="checkbox-list checkall list-content">
                                            <li className="item item-title">
                                                <ul className="item-content">
                                                    <li className="sub-item">
                                                        <input
                                                            id="all"
                                                            type="checkbox"
                                                            value={allSelected}
                                                            name="all"
                                                            onChange={checkboxAll}
                                                            checked={!!allSelected}
                                                        />
                                                    </li>
                                                    <li className="sub-item">
                                                        <span>{t("Contract.contract_id")}</span>
                                                    </li>
                                                    <li className="sub-item">
                                                        <span>
                                                            {t("Contract.contract_title_detail")}
                                                        </span>
                                                    </li>
                                                    <li className="sub-item">
                                                        <span>{t("Contract.time_sign")}</span>
                                                    </li>
                                                    <li className="sub-item">
                                                        <span>{t("Contract.user_sign")}</span>
                                                    </li>
                                                    <li className="sub-item">
                                                        <span>明細確認</span>
                                                    </li>
                                                    <li className="sub-item">
                                                        <span>{t("Contract.download")}</span>
                                                    </li>
                                                </ul>
                                            </li>
                                            {dataContract.length > 0 ? (
                                                dataContract.map((contract, index) => {
                                                    return (
                                                        <li className="item" key={index}>
                                                            <ul style={ modeView && contract.thumbnail_url ? {backgroundImage: `url(${contract.thumbnail_url})`} : {}} className="item-content" ref={thumbnailRef}>
                                                                <li className="sub-item">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            name={index}
                                                                            value={contract.checked}
                                                                            onChange={(e) =>
                                                                                handleCheckbox(
                                                                                    e,
                                                                                    index,
                                                                                )
                                                                            }
                                                                            checked={
                                                                                !!contract.checked
                                                                            }
                                                                        />
                                                                    </label>
                                                                </li>
                                                                <li className="sub-item">
                                                                    <Link
                                                                            to={
                                                                                !contract.is_owner
                                                                                    ? RouteConstant.Contract.Confirm.path.replace(
                                                                                          ":shareToken",
                                                                                          contract.share_token,
                                                                                      )
                                                                                    : RouteConstant.Contract.DetailContract.path.replace(
                                                                                          ":id",
                                                                                          contract.contract_id,
                                                                                      )
                                                                            }
                                                                        >
                                                                            {contract.contract_id}
                                                                        </Link>
                                                                </li>
                                                                <li className="sub-item">
                                                                    <span>
                                                                        {contract.contract_title}
                                                                    </span>
                                                                </li>
                                                                <li className="sub-item">
                                                                    <span>
                                                                        {contract.last_sign_contract_date ? formatDate(contract.last_sign_contract_date) : formatDate(contract.updated_at)}
                                                                    </span>
                                                                </li>
                                                                <li className="sub-item">
                                                                    {!_.isEmpty(contract.users)
                                                                        ? contract.users
                                                                              .slice(0, 3)
                                                                              .map(
                                                                                  (user, index) => {
                                                                                      return (
                                                                                          <img
                                                                                              key={
                                                                                                  index
                                                                                              }
                                                                                              className="number"
                                                                                              src={
                                                                                                  user.profile_avatar ? user.profile_avatar : "/images/avatar_men.png"
                                                                                              }
                                                                                              alt=""
                                                                                          />
                                                                                      );
                                                                                  },
                                                                              )
                                                                        : ""}
                                                                    {!_.isEmpty(contract.users) &&
                                                                    contract.users.length > 3 ? (
                                                                        <span className="number">
                                                                            +
                                                                            {contract.users.length -
                                                                                3}
                                                                        </span>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </li>
                                                                <li className="sub-item">
                                                                    <Link className="btn btn-primary" to={RouteConstant.Contract.Evidence.path.replace(":contractId", contract.contract_id)}>明細</Link>
                                                                </li>
                                                                <li className="sub-item">
                                                                    <button
                                                                        className="btn-download-pdf"
                                                                        onClick={() => handleDownloadFile(contract.download_url, contract.contract_id)}
                                                                    >
                                                                        <img
                                                                            alt=""
                                                                            src="/images/download.png"
                                                                        />
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    );
                                                })
                                            ) : (
                                                <li className="sub-item text-center">
                                                    <span>{t("nodata")}</span>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>

                                {!_.isEmpty(dataPaginate) ? (
                                    <Pagination
                                        className="align-items-center"
                                        onChange={handlePaginate}
                                        current={dataPaginate.current_page}
                                        total={dataPaginate.total}
                                        pageSize={dataPaginate.per_page}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
