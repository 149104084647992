export const login = (user) => {
    localStorage.setItem("user_verify_token", user.user_verify_token);
    localStorage.setItem("user_verify_token_expired", user.user_verify_token_expired);
    localStorage.setItem("user_email", user.email);
    localStorage.setItem("user_is_verified", user.is_verified);
    localStorage.setItem("name_user", user.last_name + user.first_name);
    localStorage.setItem("profile_avatar", user.avatar);
    localStorage.setItem("user_type", user.user_type);
};

export const logout = () => {
    localStorage.removeItem("user_verify_token");
    localStorage.removeItem("user_verify_token_expired");
    localStorage.removeItem("user_is_verified");
    localStorage.removeItem("user_email");
    localStorage.removeItem("name_user");
    localStorage.removeItem("profile_avatar");
    localStorage.removeItem("user_type");
};
