// baseURL example http://localhost:8000
export const ApiConstant = {
    Home: "/",
    Auth: {
        Login: {
            Email: "user/list",
            Password: "user/login",
        },
        ForgotPassword: {
            Forgot: "user/forgot_password",
            CheckSession: "user/check_token_reset_password",
            ResetPassword: "user/reset_password",
        },
        RegisterPersonal: "user/register/personal",
        RegisterCompany: "user/register/company",
        ResendEmail: "user/resend/email",
        ActiveUser: "user/active",
        Logout: "user/logout",
        VerifyToken: "user/verify-token",
    },
    Contract: {
        Confirm: "contract/receiver",
        List: "contract/list",
        ListTemplate: "contract/template/list",
        Create: "contract/create",
        Update: "contract/update",
        Evidence: "contract/evidence",
        EvidenceDownloadPdf: "contract/evidence/:contract_id/download",
        Detail: "contract/evidence/:contractId",
        Public: "contract/public",
        PublicExist: "contract/public/exist",
        DenySigning: "contract/receiver/deny",
    },
    Template: {
        List: "contract/template/list",
    },
    Profile: {
        Detail: "user/detail",
        Update: "user/update",
        Avatar: "user/upload/avatar",
        AddSignature: "user/add-signature",
        DetailUserCompany: "company/user/detail",
        UpdateUserCompany: "company/user/update",
        SendVerifyMobileNumber: "user/send-verify-mobile-number",
        VerifyMobileNumber: "user/verify-mobile-number",
    },
    Group: {
        Create: "group/create",
        List: "group/list",
        Detail: "group/detail/:groupId",
        SearchGroup: "group/search",
        User: {
            Create: "group/user/create",
            Search: "group/user/search",
            UpdateRoleInGroup: "group/user/update/role_in_group",
            DeleteRoleInGroup: "group/user/delete/user_in_group",
            Confirm: "group/user/confirm/join_group",
            Cancel: "group/user/cancel/join_group",
            Request: "group/user/send/request",
        },
    },
    Company: {
        CreateUserInCompany: "company/user/add",
        List: "company/user/in",
        DeleteUserInCompany: "company/user/delete",
        ListContract: "company/contract/list",
        SearchUser: "company/search/user"
    },
    Setting: {
        GetSetting: "user/setting",
        UpdateSetting: "user/setting/update",
    },
    Support: {
        SendMail: "support/send",
    },
    Result: {
        Success: 0,
    },
};

export default ApiConstant;
