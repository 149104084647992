import RouteConstant from "../constants/RouteConstant";
import { logout } from "../services/AuthService";

const apiUrl =
    process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_API_PRO
        : process.env.REACT_APP_API_DEV;
const uri = {
    global: apiUrl,
};

const tokenHeader = () => {
    const token = localStorage.getItem("user_verify_token") || "";
    return { duid: token };
};

const json = (response) => {
    if (response.ok) {
        return response.json();
    }

    validate(response);

    return response.json().then(Promise.reject.bind(Promise));
};

const validate = (response) => {
    if (response.status === 401) {
        logout();
        window.location.reload(true);
    } else if (response.status === 403) {
        window.location.href = RouteConstant.Page.Forbidden.path;
    } else if (response.status === 404) {
        window.location.href = RouteConstant.Page.NotFound.path;
    }
};

class API {
    constructor(uri) {
        this.uri = uri;
    }

    get(path, body, headers = {}) {
        const uri = new URL(`${this.uri}/${path}`);
        uri.search = new URLSearchParams(body).toString();

        return fetch(uri, {
            headers: {
                ...tokenHeader(),
                ...headers,
            },
        }).then(json);
    }

    post(path, body, headers = {}) {
        return fetch(`${this.uri}/${path}`, {
            method: "POST",
            headers: {
                ...tokenHeader(),
                "Content-Type": "application/json",
                ...headers,
            },
            body: JSON.stringify(body),
        }).then(json);
    }

    put(path, body, headers = {}) {
        return fetch(`${this.uri}/${path}`, {
            method: "PUT",
            headers: {
                ...tokenHeader(),
                "Content-Type": "application/json",
                ...headers,
            },
            body: JSON.stringify(body),
        }).then(json);
    }

    patch(path, body, headers = {}) {
        return fetch(`${this.uri}/${path}`, {
            method: "PATCH",
            headers: {
                ...tokenHeader(),
                "Content-Type": "application/json",
                ...headers,
            },
            body: JSON.stringify(body),
        }).then(json);
    }
}

const api = new API(uri.global);
export { api };
