import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { isLoggedIn } from "../helpers/AuthHelper";
import RouteConstant from "../constants/RouteConstant";
import { api } from "../services/api";
import ApiConstant from "../constants/ApiConstant";
import { login } from "../services/AuthService";
import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";

function AuthRoute({ children, ...rest }) {
    const params = new URLSearchParams(rest.location.search);
    const token = params.get("_token");
    const [verified, setVerify] = useState(false);
    const [loading, setLoading] = useState(!!token);

    useEffect(() => {
        if (token) {
            const fetchData = async () => {
                const result = await api.post(ApiConstant.Auth.VerifyToken, { duid: token });

                if (result.api_result.result === ApiConstant.Result.Success) {
                    setVerify(true);
                    login(result.datas);
                } else {
                    setVerify(false);
                }

                setLoading(false);
            };

            fetchData();
        }
    }, [token]);

    if (loading) return <></>;
    return (
        <>
            {!verified ? <Header /> : null}
            <Route
                {...rest}
                render={({ location }) =>
                    isLoggedIn() || verified ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: RouteConstant.Login.path,
                                state: { from: location },
                            }}
                        />
                    )
                }
            />
            <Footer />
        </>
    );
}

export default AuthRoute;
