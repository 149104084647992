import React from "react";
import { Link } from "react-router-dom";
import RouteConstant from "../../../constants/RouteConstant";
import { useTranslation } from "react-i18next";

function LayoutResetSuccess() {
    const { t } = useTranslation();

    return (
        <div className="reset_password login">
            <div className="page-container">
                <div className="login_wrapper register register_active">
                    <div className="form login_form login">
                        <section style={{ paddingTop: "50px" }} className="login_content">
                            <form method="POST" target="hidden_iframe">
                                <h1>{t("forgot_password.txt_title_reset")}</h1>
                                <div className="login-note">
                                    <p className="text-center">
                                        {t("forgot_password.txt_msg_success")}
                                    </p>
                                </div>
                                <div className="submit-form">
                                    <Link to={RouteConstant.Login.path}>
                                        <button className="btn green button-submit">
                                            {t("forgot_password.txt_goto_login")}
                                        </button>
                                    </Link>
                                </div>
                                <div className="clearfix" />
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LayoutResetSuccess;
