import React, { useRef, useState } from "react";
import ConfirmModal from "../../shared/form/ConfirmModal";
import SignatureCanvas from "react-signature-canvas";
import { useTranslation } from "react-i18next";
import ApiConstant from "../../constants/ApiConstant";
import { api } from "../../services/api";
import Input from "../../shared/form/Input";

export default function ProfileSignature(props) {
    const { t } = useTranslation();
    const signatureRef = useRef({});
    const [signatureId, setSignatureId] = useState("");
    const [signatureMessage, setSignatureMessage] = useState("");
    const [modalSignatureStatus, setModalSignatureStatus] = useState(false);

    let dataURL = props.dataURL;

    const handleClearSignature = () => {
        signatureRef.current.clear();
    };

    const handleRenderDrawingSignature = () => {
        return (
            <div className="contract-draw-signature-wrapper">
                <div className="contract-draw-signature-message text-danger">
                    {signatureMessage}
                </div>
                <div className="contract-draw-signature-action">
                    <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={handleClearSignature}
                    >
                        {t("button.btn_clear")}
                    </button>
                </div>
                <SignatureCanvas
                    penColor="black"
                    ref={signatureRef}
                    canvasProps={{ className: "contract-draw-signature-canvas" }}
                />
            </div>
        );
    };

    const handleShowDrawingSignature = () => {
        setModalSignatureStatus(true);
    };

    const handleCloseDrawingSignature = () => setModalSignatureStatus(false);

    const handleResizeSignatureBeforeUpload = () => {
        const signatureCanvas = signatureRef.current.getTrimmedCanvas();

        const resizedCanvas = document.createElement("canvas");
        const resizedContext = resizedCanvas.getContext("2d");

        resizedCanvas.height = "80";
        resizedCanvas.width = "124";

        resizedContext.drawImage(signatureCanvas, 0, 0, 124, 80);
        return resizedCanvas.toDataURL();
    };

    const handleSubmitSignature = async () => {
        if (signatureRef.current.isEmpty()) {
            handleCloseDrawingSignature();

            return false;
        }

        dataURL = handleResizeSignatureBeforeUpload();

        if (!dataURL) {
            return false;
        }

        props.setLoading(true);
        const result = await api.post(ApiConstant.Profile.AddSignature, {
            signature: dataURL,
        });

        if (result.api_result.result === ApiConstant.Result.Success) {
            setSignatureId(result.datas.id);
            props.setDataURL(dataURL);

            handleCloseDrawingSignature();
        } else {
            setSignatureMessage(result.api_result.message);
        }
        props.setLoading(false);
    };

    return (
        <>
            <div className="contract-signature-wrapper">
                <div className="contract-signature-image contract-signature-image-grey">
                    {dataURL ? <img src={dataURL} /> : null}
                </div>
                <button
                    className="btn btn-sm btn-primary"
                    type="button"
                    onClick={handleShowDrawingSignature}
                >
                    {t("button.btn_draw_signing")}
                </button>
            </div>

            <Input type="hidden" value={signatureId} className="form-control" />
            <ConfirmModal
                title={t("Contract.draw_signature")}
                showModal={modalSignatureStatus}
                onClose={handleCloseDrawingSignature}
                onConfirm={handleSubmitSignature}
                cancel={t("button.btn_cancel")}
                confirm={t("button.btn_sign")}
                body={handleRenderDrawingSignature()}
                className="contract_assignment modal-draw-signature"
            />
            <div className="clearfix" />
        </>
    );
}
